/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dropdown,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { cloneDeep, omit } from 'lodash';
import { JsonEditor as JSONEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { convertHTMLDatetimeString, handleErrorResponse } from 'helpers';
import MessageList from 'components/message-list';
import {
  GET_SPECIAL_OFFER_DATA,
  GET_SPECIAL_OFFER,
  UPDATE_SPECIAL_OFFER,
  ADD_SPECIAL_OFFER_TO_PARK,
  REMOVE_SPECIAL_OFFER_FROM_PARK,
} from './graphql';

const propTypes = {
  client: PropTypes.shape({}).isRequired,
  handleForward: PropTypes.func.isRequired,
  isCreatingNewSpecialOffer: PropTypes.bool.isRequired,
  parentState: PropTypes.shape({}).isRequired,
  setParentState: PropTypes.func.isRequired,
  specialOfferId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const UpdateSpecialOffer = ({
  client,
  handleForward,
  isCreatingNewSpecialOffer,
  parentState,
  setParentState,
  specialOfferId,
}) => {
  const [state, setState] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [hasMutated, setHasMutated] = useState(false);
  const [mediaFlag, setMediaFlag] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let specialOffer = {};

      if (!isCreatingNewSpecialOffer) {
        const getSpecialOffer = await client.query({
          query: GET_SPECIAL_OFFER,
          variables: {
            id: specialOfferId === 'create' ? parentState.id : specialOfferId,
            showDeleted: true,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        specialOffer = getSpecialOffer.data.specialOffer;
      }

      const { data: { allTenants, allParks, allPages } } = await client.query({
        query: GET_SPECIAL_OFFER_DATA,
      });

      const orderedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      const specialOfferForState = !isCreatingNewSpecialOffer
        ? specialOffer
        : parentState;
      setState({
        ...state,
        allTenants,
        allParks: orderedParks,
        allPages,
        initialSpecialOffer: cloneDeep(specialOfferForState),
      });

      setParentState(prevState => ({
        ...prevState,
        specialOffer: specialOfferForState,
      }));

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewSpecialOffer]);

  useEffect(() => {
    if (parentState && parentState.media && parentState.media.path && mediaFlag) {
      const specialOffer = Object.assign({}, parentState);
      specialOffer.mediaId = specialOffer.media.path;
      setParentState(prevState => ({ ...prevState, specialOffer }));
      setMediaFlag(false);
    }
  }, [parentState, setParentState, mediaFlag]);

  const handleInputChange = ({ target: { name, value } }) => {
    const specialOffer = Object.assign({}, parentState);
    specialOffer[name] = value;
    setParentState(prevState => ({ ...prevState, specialOffer }));
  };

  const handleSelectAllParksChange = () => {
    const selectedParksLength = parentState.parks.length;
    const allParksLength = state.allParks.length;

    const parks = selectedParksLength === allParksLength
      ? [] : state.allParks.map(({ id }) => ({ id }));

    setParentState(prevState => ({
      ...prevState,
      specialOffer: {
        ...prevState.specialOffer,
        parks,
      },
    }));
  };

  const handleSelectedParksChange = (e, parkId) => {
    const { target: { checked } } = e;
    const { parks } = Object.assign({}, parentState);

    checked
      ? parks.push({ id: parkId })
      : parks.splice(parks.findIndex(({ id }) => parseInt(id, 10) === parseInt(parkId, 10)), 1);

    setParentState(prevState => ({
      ...prevState,
      specialOffer: {
        ...prevState.specialOffer,
        parks,
      },
    }));
  };

  const handleJSONEditorChange = ({ target: { name, value } }) => {
    const specialOffer = Object.assign({}, parentState);
    const JSONObject = parentState[name];

    // eslint-disable-next-line array-callback-return
    Object.keys(JSONObject).map((key) => {
      JSONObject[key] !== value[key] && (JSONObject[key] = value[key]);
    });

    setParentState(prevState => ({ ...prevState, specialOffer }));
  };

  const validateData = () => {
    const errorsFound = [];

    const {
      title,
      shortDescription,
      tenantId,
      mediaId,
      startDate,
      endDate,
      publishAt,
      expiresAt,
    } = parentState;
    if (!title) {
      errorsFound.push({
        title: 'Invalid Title',
        message: 'Please ensure you have entered a Title',
      });
    }

    if (!shortDescription) {
      errorsFound.push({
        title: 'Invalid Description',
        message: 'Please ensure you have entered a Description',
      });
    }

    if (!tenantId) {
      errorsFound.push({
        title: 'Invalid Tenant',
        message: 'Please ensure you have selected a Tenant',
      });
    }

    if (!mediaId) {
      errorsFound.push({
        title: 'Invalid Media url',
        message: 'Please ensure you have entered a media url',
      });
    }

    if (!startDate) {
      errorsFound.push({
        title: 'Invalid Start Date',
        message: 'Please ensure you have selected a Start Date',
      });
    }

    if (!endDate) {
      errorsFound.push({
        title: 'Invalid End Date',
        message: 'Please ensure you have selected an End Date',
      });
    }

    if (!publishAt) {
      errorsFound.push({
        title: 'Invalid Publish At Date',
        message: 'Please ensure you have selected a Publish At Date',
      });
    }

    if (!expiresAt) {
      errorsFound.push({
        title: 'Invalid Expires At Date',
        message: 'Please ensure you have selected an Expires At Date',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const updateSpecialOffer = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setHasMutated(false);

    const {
      __typename,
      id,
      parks,
      startDate,
      endDate,
      publishAt,
      expiresAt,
      ...input
    } = parentState;
    const formatDateString = string => string.substring(0, 16).concat(':00');

    const response = await client.mutate({
      mutation: UPDATE_SPECIAL_OFFER,
      variables: {
        id: parseInt(id, 10),
        input: {
          startDate: formatDateString(startDate),
          endDate: formatDateString(endDate),
          publishAt: formatDateString(publishAt),
          expiresAt: formatDateString(expiresAt),
          ...omit(input, 'media'),
        },
      },
    });

    if (!response || response.errors) {
      setErrors(...handleErrorResponse({ response, message: 'No special offer response' }));
    }

    // mutated successfully
    if (response && response.data) {
      // make special offer -> park associations/disassociations

      const { data: { updateCSSpecialOffer: { parks: responseParks } } } = response;
      const { parks: selectedParks } = parentState;

      const selectedParkIds = selectedParks.map(({ id: parkId }) => parkId);
      const responseParkIds = responseParks.map(({ id: parkId }) => parkId);

      const parksToAdd = [];
      const parksToRemove = [];

      selectedParkIds.forEach(parkId => (
        !responseParkIds.includes(parkId) && parksToAdd.push((
          { parkId: parseInt(parkId, 10), specialOfferId: parseInt(specialOfferId, 10) }
        ))));

      responseParkIds.forEach(parkId => (
        !selectedParkIds.includes(parkId) && parksToRemove.push((
          { parkId: parseInt(parkId, 10), specialOfferId: parseInt(specialOfferId, 10) }
        ))));

      if (parksToAdd.length > 0) {
        (async () => {
          const addParksResponse = await client.mutate({
            mutation: ADD_SPECIAL_OFFER_TO_PARK,
            variables: {
              input: [...parksToAdd],
            },
          });

          if (!addParksResponse || addParksResponse.errors) {
            setErrors(...handleErrorResponse({ response: addParksResponse, message: 'Error adding park associations' }));
          }
        })();
      }

      if (parksToRemove.length > 0) {
        (async () => {
          const removeParksResponse = await client.mutate({
            mutation: REMOVE_SPECIAL_OFFER_FROM_PARK,
            variables: {
              input: [...parksToRemove],
            },
          });
          if (!removeParksResponse || removeParksResponse.errors) {
            setErrors(...errors, ...handleErrorResponse({ response: removeParksResponse, message: 'Error removing park associations' }));
          }
        })();
      }

      errors.length === 0 && setHasMutated(true);
    }
  };

  // if new special offer, move to page edit
  // else update current special offer
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateData()) {
      if (!parentState.pageId) {
        handleForward();
        return;
      }
      updateSpecialOffer();
    }
  };

  const handleContinue = () => {
    if (validateData()) {
      handleForward();
    }
  };

  const handleSaveAndContinue = () => {
    if (validateData()) {
      updateSpecialOffer();
      !errors.length && handleForward();
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveAndContinue();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {hasMutated && <MessageList messages={[{ message: 'Special Offer has been updated' }]} messageType="success" />}

            <TextField
              label="Special Offer ID"
              name="id"
              value={parentState.id}
              prefix="#"
              type="number"
              readOnly
            />

            <TextField
              label={`Title: (${
                parentState.title.length
              }/50 characters)`}
              name="title"
              placeholder="Enter a title.."
              value={parentState.title}
              onChange={e => handleInputChange(e)}
              ariaLabel="Title"
              maxLength={50}
              required
            />

            <TextField
              label={`Description: (${
                parentState.shortDescription.length
              }/255 characters)`}
              name="shortDescription"
              placeholder="Enter a description.."
              value={parentState.shortDescription}
              onChange={e => handleInputChange(e)}
              multiline
              maxLength={255}
              required
            />

            <Dropdown
              label="Tenant"
              name="tenantId"
              placeholder="Select a Tenant"
              selectedKey={
                parentState.tenantId
                  ? parentState.tenantId
                  : undefined
              }
              options={state.allTenants.map(tenant => ({
                key: tenant.id,
                text: tenant.name,
              }))}
              onChange={(e, item) => handleInputChange({
                target: { name: 'tenantId', value: item.key },
              })}
              required
            />

            <Stack>
              <Label>Meta</Label>
              <JSONEditor
                name="meta"
                value={parentState.meta}
                onChange={e => handleJSONEditorChange({ target: { name: 'meta', value: e } })}
              />
            </Stack>

            <TextField
              name="mediaId"
              placeholder="Enter media url"
              value={parentState.mediaId}
              onChange={e => handleInputChange(e)}
              label="Media Url"
              required
            />

            <Stack tokens={{ childrenGap: 8 }}>
              <Label variant="medium" style={{ fontWeight: 600 }}>
                Parks
              </Label>

              <Checkbox
                label="Select All"
                checked={state.allParks.length === parentState.parks.length}
                onChange={() => handleSelectAllParksChange()}
              />

              <Stack tokens={{ childrenGap: 4 }}>
                {state.allParks.map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    label={name}
                    checked={
                      parentState.parks.findIndex(
                        o => parseInt(o.id, 10) === parseInt(id, 10),
                      ) >= 0
                    }
                    onChange={e => handleSelectedParksChange(e, id)}
                  />
                ))}
              </Stack>
            </Stack>

            <TextField
              label="Start Date"
              name="startDate"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: parentState.startDate, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="End Date"
              name="endDate"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: parentState.endDate, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="Publish At Date"
              name="publishAt"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: parentState.publishAt, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="Expiry Date"
              name="expiresAt"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: parentState.expiresAt, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <Stack horizontal horizontalAlign="space-between">
              {parentState.pageId !== null && <PrimaryButton type="button" onClick={e => handleSubmit(e)}>Save</PrimaryButton>}
              {parentState.pageId !== null && (
                <PrimaryButton type="submit" onClick={e => handleSaveAndContinue(e)}>Save and Continue</PrimaryButton>
              )}
              <PrimaryButton onClick={e => handleContinue(e)}>Continue</PrimaryButton>
            </Stack>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateSpecialOffer.propTypes = propTypes;

export default withApollo(UpdateSpecialOffer);
