/* eslint-disable react/prop-types */
import React from 'react';
import { Toggle } from 'office-ui-fabric-react';

/*
  This HOC is for fixing issue where clicking on the wrong part of a Toggle component
  can result in sending the name as undefined.

  The only change from Office-UI <Toggle /> to <NewToggle /> is that the onChange function
  needs to be added as a prop because the parameters are already defined in <BaseToggle />
  e.g. onChange={handleInputChange}
*/

const withToggle = BaseToggle => ({ onChange, ...props }) => (
  <BaseToggle
    {...props}
    onChange={(e, checked) => onChange({
      target: { name: props.name, value: checked },
    })}
  />
);

const NewToggle = withToggle(Toggle);

export default NewToggle;
