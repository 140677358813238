/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  Dropdown,
  Label,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import { convertHTMLDatetimeString, handleErrorResponse } from 'helpers';
import MessageList from 'components/message-list';
import { ParentModal, TemplateModal } from './components';
import {
  GET_PAGE,
  GET_PAGE_DATA,
  CREATE_CS_SPECIAL_OFFER,
  CREATE_PAGE,
  UPDATE_PAGE,
  ADD_SPECIAL_OFFER_TO_PARK,
} from './graphql';

const defaultProps = {
  pageId: null,
};

const propTypes = {
  client: PropTypes.shape({}).isRequired,
  handleBack: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
  pageId: PropTypes.number,
  parentState: PropTypes.shape({}).isRequired,
  setIsCreatingNewSpecialOffer: PropTypes.func.isRequired,
  setParentState: PropTypes.func.isRequired,
};

const UpdatePage = ({
  client,
  handleBack,
  handleForward,
  pageId,
  parentState,
  setIsCreatingNewSpecialOffer,
  setParentState,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [canonicalLocked, setIsCanonicalLocked] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let page = null;

      if (pageId) {
        const getPage = await client.query({
          query: GET_PAGE,
          variables: {
            id: pageId,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        page = getPage.data.page;
      }

      const {
        data: {
          allSectors,
          allTenants,
          allPages,
          allPageTemplates,
        },
      } = await client.query({
        query: GET_PAGE_DATA,
      });

      setState({
        allSectors,
        allTenants,
        allPages,
        allPageTemplates,
      });
      // eslint-disable-next-line no-unused-expressions
      page !== null && setParentState(prevState => ({ ...prevState, page }));

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  const handleInputChange = ({ target: { name, value } }) => {
    const page = Object.assign({}, parentState.page);
    page[name] = value;
    setParentState(prevState => ({ ...prevState, page }));
  };

  const generateUrl = () => {
    const { page: { navigationLabel } } = parentState;

    if (navigationLabel) {
      const slug = navigationLabel.toLowerCase().split(' ').join('-');
      const parentName = state.allPages.find(({ id }) => (
        parseInt(id, 10) === parseInt(parentState.page.parentId, 10))).url;

      const slugForUrl = `${parentName}${parentName.endsWith('/') ? '' : '/'}${slug}`;
      const canonical = `${parentName}${parentName.endsWith('/') ? '' : '/'}${slug}`;

      const page = Object.assign({}, parentState.page);
      page.url = slugForUrl;
      page.canonical = canonical;
      setParentState(prevState => ({ ...prevState, page }));

      return;
    }

    setErrors([{ title: 'Invalid Navigation Label', message: 'Please enter a navigation label to generate a URL' }]);
  };

  const validateData = () => {
    const errorsFound = [];

    const {
      page: {
        name,
        sectorId,
        parentId,
        navigationLabel,
        url,
        title,
        description,
        canonical,
        publishedAt,
      },
    } = parentState;

    if (!name) {
      errorsFound.push({
        title: 'Invalid Name',
        message: 'Please ensure you have entered a page Name',
      });
    }

    if (!sectorId) {
      errorsFound.push({
        title: 'Invalid Sector',
        message: 'Please ensure you have selected a Sector',
      });
    }

    if (!parentId) {
      errorsFound.push({
        title: 'Invalid Parent',
        message: 'Please ensure you have selected a Parent',
      });
    }

    if (!navigationLabel) {
      errorsFound.push({
        title: 'Invalid Navigation Label',
        message: 'Please ensure you have entered a Navigation Label',
      });
    }

    if (!url) {
      errorsFound.push({
        title: 'Invalid URL',
        message: 'Please ensure you have entered a URL',
      });
    }

    if (!title) {
      errorsFound.push({
        title: 'Invalid Title',
        message: 'Please ensure you have entered a Title',
      });
    }

    if (!description) {
      errorsFound.push({
        title: 'Invalid Description',
        message: 'Please ensure you have entered a Description',
      });
    }

    if (!canonical) {
      errorsFound.push({
        title: 'Invalid Canonical',
        message: 'Please ensure you have entered a Canonical value',
      });
    }

    if (!publishedAt) {
      errorsFound.push({
        title: 'Invalid Published At Date',
        message: 'Please ensure you have entered a Publish date',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleMutation = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSuccessMessages([]);

    const {
      page: {
        id,
        tenantId,
        publishedAt,
        __typename,
        ...input
      },
      specialOffer: {
        tenantId: specialOfferTenantId,
      },
    } = parentState;

    const formatDateString = string => string.substring(0, 16).concat(':00');

    const response = await client.mutate({
      mutation: pageId ? UPDATE_PAGE : CREATE_PAGE,
      variables: {
        ...(pageId && { id: parseInt(id, 10) }),
        input: {
          ...input,
          order: parseInt(input.order, 10),
          priority: parseFloat(input.priority),
          // defaults to specialOfferTenantId if page tenantId has not been changed
          tenantId: tenantId || specialOfferTenantId,
          publishedAt: formatDateString(publishedAt),
        },
      },
    });

    if (!response || response.errors) {
      setErrors(...handleErrorResponse({ response, message: 'Error saving page' }));
    }

    if (response && response.data) {
      // set page id
      const responseMapped = pageId
        ? response.data.updatePage
        : response.data.createPage;

      setParentState(prevState => ({
        ...prevState,
        specialOffer: {
          ...prevState.specialOffer,
          pageId: !pageId ? parseInt(responseMapped.id, 10) : pageId,
        },
        page: {
          ...prevState.page,
          tenantId: parseInt(responseMapped.tenantId, 10),
          id: parseInt(responseMapped.id, 10),
        },
      }));

      setSuccessMessages([{
        title: 'Page',
        message: `Page has been ${pageId ? 'updated' : 'created'}`,
      }]);

      if (!pageId) {
        // create new special offer
        const {
          specialOffer: {
            id: nullId,
            pageId: specialOfferPageId,
            startDate,
            endDate,
            publishAt,
            expiresAt,
            holidayHomes,
            parks,
            media,
            ...specialOfferInput
          },
        } = parentState;

        const specialOfferResponse = await client.mutate({
          mutation: CREATE_CS_SPECIAL_OFFER,
          variables: {
            input: {
              pageId: parseInt(responseMapped.id, 10),
              startDate: formatDateString(startDate),
              endDate: formatDateString(endDate),
              publishAt: formatDateString(publishAt),
              expiresAt: formatDateString(expiresAt),
              ...specialOfferInput,
            },
          },
        });

        if (!specialOfferResponse || specialOfferResponse.errors) {
          setErrors(...errors, ...handleErrorResponse({ response: specialOfferResponse, message: 'Error saving Special Offer' }));
        }
        if (specialOfferResponse && specialOfferResponse.data) {
          setParentState(prevState => ({
            ...prevState,
            specialOffer: {
              ...prevState.specialOffer,
              id: parseInt(specialOfferResponse.data.createCSSpecialOffer.id, 10),
            },
          }));
          setIsCreatingNewSpecialOffer(false);
          setSuccessMessages([...successMessages, { title: 'Special Offer', message: 'Created Special Offer' }]);

          if (parentState.specialOffer.parks.length > 0) {
            // make special offer => park associations/disassociations

            const { data: { createCSSpecialOffer: { id: specialOfferId } } } = specialOfferResponse;
            const { specialOffer: { parks: selectedParks } } = parentState;

            const parksToAdd = selectedParks.map(({ id: parkId }) => ({
              parkId: parseInt(parkId, 10), specialOfferId: parseInt(specialOfferId, 10),
            }));

            (async () => {
              const associationsResponse = await client.mutate({
                mutation: ADD_SPECIAL_OFFER_TO_PARK,
                variables: {
                  input: [...parksToAdd],
                },
              });

              if (!associationsResponse || associationsResponse.errors) {
                setErrors(...errors, ...handleErrorResponse({ response: associationsResponse, message: 'Error saving Park Associations' }));
              }

              if (associationsResponse && associationsResponse.data) {
                setSuccessMessages([...successMessages, { title: 'Park Associations', message: 'Associated parks to special offer' }]);
              }
            })();
          }
        }
      }
    }
  };

  const handleSave = () => {
    if (validateData()) {
      handleMutation();
    }
  };

  const handleSaveAndContinue = () => {
    if (validateData()) {
      handleMutation();
      if (errors.length === 0) {
        handleForward();
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <Stack tokens={{ childrenGap: 18 }}>

        {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
        {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

        <TextField
          label="Page ID"
          name="id"
          value={parentState.page.id}
          prefix="#"
          type="number"
          readOnly
        />

        <TextField
          label="Name"
          value={parentState.page.name}
          name="name"
          placeholder="Enter a page name.."
          onChange={e => handleInputChange(e)}
          ariaLabel="Name"
          required
        />

        <TextField
          label="Title"
          value={parentState.page.title}
          name="title"
          placeholder="Enter a title.."
          onChange={e => handleInputChange(e)}
          ariaLabel="Title"
          required
        />

        <TextField
          label="Description"
          value={parentState.page.description}
          name="description"
          placeholder="Enter a description.."
          onChange={e => handleInputChange(e)}
          ariaLabel="Description"
          multiline
          required
        />

        {!pageId
          ? (
            <TemplateModal
              tenantId={parentState.specialOffer.tenantId}
              pageTemplateId={parentState.page.pageTemplateId || undefined}
              handleInputChange={handleInputChange}
            />
          )
          : (
            <TextField
              label="Template"
              readOnly
              value={parentState.page.pageTemplateId
                ? state.allPageTemplates.find(({ id }) => (
                  parseInt(id, 10) === parseInt(parentState.page.pageTemplateId, 10)
                )).name
                : undefined
              }
            />
          )}

        <ParentModal
          parentId={parentState.page.parentId || undefined}
          setState={setParentState}
        />

        <TextField
          label="Navigation Label"
          name="navigationLabel"
          placeholder="Enter a navigation label.."
          value={parentState.page.navigationLabel}
          onChange={e => handleInputChange(e)}
          ariaLabel="Navigation Label"
          required
        />

        <Stack>
          <Label>URL</Label>
          <Stack horizontal tokens={{ childrenGap: 24 }}>
            <TextField
              value={parentState.page.url}
              name="url"
              placeholder="Enter a URL.."
              onChange={e => handleInputChange(e)}
              ariaLabel="URL"
              required
              styles={{ root: { width: 360 } }}
            />
            {!pageId && (
              <DefaultButton onClick={() => generateUrl()}>Generate URL</DefaultButton>
            )}
          </Stack>
        </Stack>

        <Stack>
          <Label>Canonical</Label>
          <Stack horizontal tokens={{ childrenGap: 24 }}>
            <TextField
              name="canonical"
              readOnly={canonicalLocked}
              value={parentState.page.canonical}
              onChange={e => handleInputChange(e)}
              styles={{ root: { width: 360 } }}
              required
            />
            <DefaultButton
              onClick={() => setIsCanonicalLocked(false)}
            >
              Unlock Editing
            </DefaultButton>
          </Stack>
        </Stack>

        {/*
          allows editing if new page but not if existing page.
          prevents potential issues arising from changing the tenant of a live page
        */}
        {!pageId ? (
          <Dropdown
            label="Tenant"
            selectedKey={parentState.specialOffer.tenantId}
            options={state.allTenants.map(({ id, name }) => ({
              key: id,
              text: name,
            }))}
            onChange={(e, item) => handleInputChange({
              target: { name: 'tenantId', value: item.key },
            })}
            required
          />
        )
          : (
            <TextField
              label="Tenant"
              readOnly
              value={state.allTenants.find(({ id }) => (
                parseInt(id, 10) === parseInt(parentState.page.tenantId, 10)
              )).name}
            />
          )
        }

        <Dropdown
          label="Sector"
          name="sectorId"
          placeholder="Select a Sector"
          selectedKey={
            parentState.page.sectorId
              ? parentState.page.sectorId
              : undefined
          }
          options={state.allSectors.map(({ id, name }) => ({
            key: id,
            text: name,
          }))}
          onChange={(e, item) => handleInputChange({
            target: { name: 'sectorId', value: item.key },
          })}
          required
        />

        <TextField
          label="Priority"
          type="number"
          name="priority"
          min={0}
          max={1}
          step={0.01}
          value={parentState.page.priority}
          onChange={e => handleInputChange(e)}
          required
        />

        <TextField
          label="Order"
          type="number"
          name="order"
          value={parentState.page.order}
          onChange={e => handleInputChange(e)}
          required
        />

        <Toggle
          label="Pagebuilder Disabled"
          name="pagebuilderDisabled"
          onText="Disabled"
          offText="Not Disabled"
          checked={parentState.page.pagebuilderDisabled}
          onChange={(e, checked) => handleInputChange({
            target: { name: e.target.name, value: checked },
          })}
        />

        <Toggle
          label="System Page"
          name="systemPage"
          onText="Page can be modified with Page Builder"
          offText="Page cannot be modified with Page Builder"
          checked={parentState.page.systemPage}
          onChange={(e, checked) => handleInputChange({
            target: { name: e.target.name, value: checked },
          })}
        />

        <Toggle
          label="Indexing"
          onText="Page is indexed"
          offText="Page is not indexed"
          name="noIndex"
          checked={parentState.page.noIndex}
          onChange={(e, checked) => handleInputChange({
            target: { name: e.target.name, value: checked },
          })}
        />

        <Toggle
          label="URL Locked from Editing"
          onText="Locked"
          offText="Unlocked"
          name="urlLocked"
          checked={parentState.page.urlLocked}
          onChange={(e, checked) => handleInputChange({
            target: { name: e.target.name, value: checked },
          })}
        />

        <Toggle
          label="Show on Web Sidebar"
          onText="Show"
          offText="Hide"
          name="showOnWebSidebar"
          checked={parentState.page.showOnWebSidebar}
          onChange={(e, checked) => handleInputChange({
            target: { name: e.target.name, value: checked },
          })}
        />

        <TextField
          label="Published At"
          type="datetime-local"
          min="2010-01-01T00:00"
          max="9999-12-31T00:00"
          name="publishedAt"
          value={convertHTMLDatetimeString({ string: parentState.page.publishedAt, toHTML: true })}
          onChange={e => handleInputChange({
            target: {
              name: e.target.name,
              value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
            },
          })}
          required
        />

        <Stack horizontal horizontalAlign="space-between">
          <DefaultButton onClick={() => handleBack()}>Back</DefaultButton>
          <PrimaryButton type="submit">Save</PrimaryButton>
          <PrimaryButton onClick={() => handleSaveAndContinue()}>Save & Enter IDE</PrimaryButton>
          {pageId
            && <PrimaryButton onClick={() => handleForward()}>Continue to IDE</PrimaryButton>}
        </Stack>
      </Stack>
    </form>
  );
};

UpdatePage.defaultProps = defaultProps;
UpdatePage.propTypes = propTypes;

export default withApollo(UpdatePage);
