// returns any error messages from a graphql response

export default ({ response, customMessage = 'No response' }) => {
  const errors = [];

  if (!response) {
    errors.push({ message: customMessage });
  }

  if (response && response.errors) {
    errors.push(response.errors);
  }

  return errors;
};
