import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'office-ui-fabric-react';

const defaultProps = {
  disabled: true,
  id: null,
  onClick: () => false,
};

const propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number,
  onClick: PropTypes.func,
};

const SelectButton = ({
  disabled,
  id,
  onClick,
  ...rest
}) => (
  <ActionButton
    disabled={disabled}
    id={id}
    onClick={onClick}
  >
    {rest.children}
  </ActionButton>
);

SelectButton.propTypes = propTypes;
SelectButton.defaultProps = defaultProps;

export default SelectButton;
