/* eslint-env browser */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  PrimaryButton,
  DefaultButton,
  Spinner,
} from 'office-ui-fabric-react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { PUBLIC_PATHS } from '../../config';
import { GET_MEDIA } from './graphql';

/*
 Important Note;
  this component should be ingested within a Modal from FabricUI,
  called from a parent Component.
*/
const MediaPicker = ({ client, onDismiss, onSelectItem }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    items: [],
    page: 1,
  });

  /* (int) how many items to return from GraphQL */
  const LIMIT = 20;

  const columns = [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 40,
      isResizable: true,
    },
    {
      key: 'media',
      iconName: 'Picture',
      isIconOnly: true,
      onRender: item => (
        <div style={{ width: '300px', height: '169px' }}>
          <Image
            src={item.disk === 'CS' ? `${item.path}?format=pjpg&auto=webp&quality=75&width=300&height=169&fit=crop` : `${PUBLIC_PATHS.assets}/300x169/assets/${item.path}`}
            alt={item.alternateText}
            imageFit={ImageFit.cover}
            maximizeFrame
          />
        </div>
      ),
      minWidth: 350,
      maxWidth: 350,
    },
    {
      key: 'alternateText',
      name: 'Alternate Text',
      fieldName: 'alternateText',
      minWidth: 200,
      isResizable: true,
    },
    {
      key: 'createdAt',
      name: 'Created',
      fieldName: 'createdAt',
      minWidth: 120,
      isResizable: true,
    },
    {
      key: 'actions',
      name: '',
    },
  ];

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <PrimaryButton
            text="Select"
            onClick={() => {
              onSelectItem(item);
              onDismiss();
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const onLoadNextPage = useCallback(async () => {
    const res = await client.query({
      query: GET_MEDIA,
      variables: {
        pagination: {
          offset: (state.page * LIMIT) - LIMIT,
          limit: LIMIT,
        },
      },
    });

    const { allMedia } = res.data;

    setState({
      items: state.items.concat(allMedia),
      page: state.page + 1,
    });

    setIsLoading(false);
  }, [client, state.items, state.page]);

  const onDelayedLoadNextPage = () => {
    if (!isLoading) {
      setIsLoading(true);
      onLoadNextPage();
    }
  };

  useEffect(() => {
    // only run on first page
    if (state.page === 1) {
      const fetchData = async () => {
        setIsLoading(true);
        await onLoadNextPage();
      };

      fetchData();
    }
  }, [onLoadNextPage, state.page]);

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: 'Media Picker',
        subText: 'Please choose your media to associate by clicking on the Select button.',
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={(window.innerWidth * 0.95)}
    >
      <div style={{ position: 'relative', height: '70vh' }}>
        <ScrollablePane>
          <DetailsList
            setKey="items"
            items={[...state.items, null]}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            onRenderMissingItem={() => onDelayedLoadNextPage()}
          />
          {isLoading && (
            <Spinner label="Loading..." />
          )}
        </ScrollablePane>
      </div>
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="Close" />
      </DialogFooter>
    </Dialog>
  );
};

MediaPicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

export default withApollo(MediaPicker);
