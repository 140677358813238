/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  Dropdown,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import { FooterWrapper } from 'components/footer-wrapper';
import { GET_LIVE_CHAT_GREETING, UPDATE_LIVE_CHAT_GREETING } from './graphql';
import { apolloClient as client } from '../../../client';

const QuickEdit = ({
  componentId,
  handleOnDismiss,
  quickEditMutated,
  setHasQuickEditMutated,
}) => {
  const [state, setState] = useState({
    delayOptions: [...Array.from(Array(13).keys(), n => ({ key: n * 5, text: n * 5 }))],
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await client.query({
        query: GET_LIVE_CHAT_GREETING,
        variables: {
          id: componentId,
        },
      });

      if (res.data && res.data.getLiveChatGreetings) {
        const { data: { getLiveChatGreetings: liveChatGreeting } } = res;
        setState({ ...state, liveChatGreeting });
      } else {
        setErrors([{ message: 'Data fetching unsuccessful' }]);
      }

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.liveChatGreeting[name] = value;
    setState(newState);
  };

  const handleSave = async () => {
    const {
      liveChatGreeting: {
        __typename,
        ...input
      },
    } = state;

    const response = await client.mutate({
      mutation: UPDATE_LIVE_CHAT_GREETING,
      variables: { input },
    });

    if (!response) {
      setErrors([{ message: 'Save unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    if (response && response.data) {
      handleOnDismiss();
      setHasQuickEditMutated(!quickEditMutated);
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {/* display errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>

                {errors.map(({ message }) => (
                  <Text variant="medium" key={message}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}

          <TextField
            label="Live Chat Greeting ID"
            value={state.liveChatGreeting.id}
            readOnly
          />

          <MessageBar messageBarType={MessageBarType.default}>
            <TextField
              label="URL"
              name="url"
              value={state.liveChatGreeting.url}
              onChange={e => handleInputChange(e)}
              ariaLabel="URL"
              required
            />
            {'Preface the URL with "@" to use a regular expression e.g. @.{0,}parkholidays.(com|local)/holidays'}
          </MessageBar>

          <TextField
            label="Message"
            name="message"
            value={state.liveChatGreeting.message}
            onChange={e => handleInputChange(e)}
            ariaLabel="Message"
            multiline
            required
          />

          <Dropdown
            label="Delay (seconds)"
            name="delay"
            options={state.delayOptions}
            selectedKey={
              state.liveChatGreeting.delay ? state.liveChatGreeting.delay : undefined
            }
            onChange={(e, item) => handleInputChange({
              target: { name: 'delay', value: item.key },
            })}
            ariaLabel="Delay"
            required
          />

          <Toggle
            label="Active"
            name="active"
            onText="Active"
            offText="Inactive"
            checked={state.liveChatGreeting.active}
            onChange={(e, checked) => handleInputChange({
              target: { name: e.target.name, value: checked },
            })}
            ariaLabel="Active"
            required
            type="number"
          />

        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = {
  componentId: PropTypes.string.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  quickEditMutated: PropTypes.bool.isRequired,
  setHasQuickEditMutated: PropTypes.func.isRequired,
};

export default QuickEdit;
