import gql from 'graphql-tag';

export const GET_PARK_CONTACT = gql`
query parkContact($id: ID!) {
  parkContact(id: $id) {
    id
    salutation
    firstname
    surname
    email
    phoneNo
    mobilePhoneNo
    jobRoleId
    mediaId
    media {
      id
      path
    }
    parks {
      id
      name
    }
  }
}
`;

export const UPDATE_PARK_CONTACT = gql`
  mutation updateParkContact($id: Int!, $input: UpdateParkContactInput!) {
    updateParkContact(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_CS_PARK_CONTACT = gql`
  mutation UpdateCSParkContact($id: Int!, $input: UpdateCSParkContactInput!) {
    updateCSParkContact(id: $id, input:$input) {
      id
    }
  }`;

export const CREATE_PARK_CONTACT = gql`
  mutation createParkContact($input: CreateParkContactInput!) {
    createParkContact(input: $input) {
      id
    }
  }
`;

export const CREATE_CS_PARK_CONTACT = gql`
  mutation CreateCSParkContact($input: CreateCSParkContactInput!) {
    createCSParkContact(input:$input) {
      id
    }
  }
`;

export const ADD_CONTACT_TO_PARK = gql`
  mutation addContactToPark($input: [ContactParkAssociationInput]!) {
    addContactToPark(input: $input) {
      contactId
    }
  }
`;

export const REMOVE_CONTACT_FROM_PARK = gql`
  mutation removeContactFromPark($input: [ContactParkAssociationInput]!) {
    removeContactFromPark(input: $input) {
      contactId
    }
  }
`;

export const GET_PARK_CONTACT_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
    allJobRoles {
      id
      jobTitle
    }
  }
`;
