/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { FooterWrapper, MessageList } from 'components';
import { GET_FILE, UPDATE_FILE } from './graphql';

const propTypes = {
  client: PropTypes.object.isRequired,
  ownersAreaResourceId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  setQuickEditMutated: PropTypes.func.isRequired,
};

const QuickEdit = ({
  client,
  ownersAreaResourceId,
  handleOnDismiss,
  setQuickEditMutated,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: { ownersAreaResource } } = await client.query({
        query: GET_FILE,
        variables: {
          id: ownersAreaResourceId,
        },
      });

      setState({ ownersAreaResource });
      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const asset = Object.assign({}, state.ownersAreaResource.asset);
    asset[name] = value;
    setState(prevState => ({
      ...prevState,
      ownersAreaResource: {
        ...prevState.ownersAreaResource,
        asset,
      },
    }));
  };

  const handleSave = async () => {
    const {
      ownersAreaResource: {
        asset: {
          __typename,
          id,
          path,
          ...input
        },
      },
    } = state;

    const response = await client.mutate({
      mutation: UPDATE_FILE,
      variables: {
        id,
        input,
      },
    });

    if (!response) {
      setErrors([{ message: 'Save unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    if (response && response.data) {
      handleOnDismiss();
      setQuickEditMutated();
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

          <TextField
            label="File ID"
            value={state.ownersAreaResource.asset.id}
            prefix="#"
            type="number"
            readOnly
          />

          <TextField
            label="Path"
            name="path"
            value={state.ownersAreaResource.asset.path}
            onChange={e => handleInputChange(e)}
            ariaLabel="Path"
            readOnly
          />

          <TextField
            label={`Title: (${
              state.ownersAreaResource.asset.title.length
            }/50 characters)`}
            name="title"
            value={state.ownersAreaResource.asset.title}
            onChange={e => handleInputChange(e)}
            ariaLabel="Title"
            maxLength={50}
            required
          />

          <TextField
            label={`Description: (${
              state.ownersAreaResource.asset.description.length
            }/255 characters)`}
            name="description"
            value={state.ownersAreaResource.asset.description}
            onChange={e => handleInputChange(e)}
            ariaLabel="Description"
            maxLength={255}
            rows={4}
            required
          />
        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = propTypes;

export default withApollo(QuickEdit);
