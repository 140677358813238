import gql from 'graphql-tag';

export const GET_RESOURCE = gql`
  query ownersAreaResource($id: Int!) {
    ownersAreaResource(id: $id) {
      id
      asset {
        id
        title
        description
        path
        mediaId
        media {
          path
        }
        size
      }
      categoryId
      parks {
        id
      }
    }
  }
`;

export const UPDATE_OWNERS_AREA_RESOURCE = gql`
  mutation updateOwnersAreaResource($id: Int!, $input: UpdateOwnersAreaResourceInput! ) {
    updateOwnersAreaResource(id: $id, input: $input) {
      id
      categoryId
    }
  }
`;

export const GET_RESOURCE_DATA = gql`
  {
    allAssetCategories {
      id
      name
    }
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
  }
`;

export const GENERATE_PRESIGNED_URL = gql`
  mutation generatePresignedUrl($input: GeneratePresignedUrlInput!) {
    generatePresignedUrl(input: $input) {
      url
    }
  }
`;

export const CREATE_ASSET = gql`
  mutation createCSAsset($input: CreateCSAssetInput!) {
    createCSAsset(input: $input) {
      id
      path
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateCSAsset($id: Int!, $input: UpdateCSAssetInput!) {
    updateCSAsset(id: $id, input: $input) {
      id
      path
    }
  }
`;

export const ADD_ASSET_TO_RESOURCE = gql`
  mutation addAssetsToOwnersAreaResources($input: [AddAssetsToOwnersAreaResourcesInput]!) {
    addAssetsToOwnersAreaResources(input: $input) {
      id
    }
  }
`;

export const ADD_RESOURCES_TO_PARKS = gql`
  mutation addOwnersAreaResourcesToParks($input: [AddOwnersAreaResourcesToParksInput]!) {
    addOwnersAreaResourcesToParks(input: $input) {
      ownersAreaResourceId
    }
  }
`;

export const REMOVE_RESOURCES_FROM_PARKS = gql`
  mutation removeOwnersAreaResourcesFromParks($input: [RemoveOwnersAreaResourcesFromParksInput]!) {
    removeOwnersAreaResourcesFromParks(input: $input) {
      ownersAreaResourceId
    }
  }
`;
