import gql from 'graphql-tag';

export const GET_FILE = gql`
  query ownersAreaResource($id: Int!) {
    ownersAreaResource(id: $id) {
      id
      asset {
        id
        title
        description
      }
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation updateAsset($id: Int!, $input: UpdateAssetInput!) {
    updateAsset(id: $id, input: $input) {
      id
    }
  }
`;
