import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { styled } from 'styletron-react';
import login from './views/login';
import UserContext from './context/userContext';
import Appbar from './components/appbar';
import SideMenu from './components/side-menu';
import { authenticatedRoutes, systemRoutes } from './routes';

const AppWrapper = styled('div', {
  boxSizing: 'border-box',
});

const AppContentWrapper = styled('div', {
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '333px auto',
  height: 'calc(100vh - 50px)',
});

const Content = styled('div', {
  boxSizing: 'border-box',
  paddingTop: '8px',
  marginRight: '24px',
  paddingBottom: '8px',
  marginLeft: '24px',
});


const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { authenticated } = useContext(UserContext);

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

const App = () => {
  const { authenticated, user } = useContext(UserContext);

  return (
    <Router>
      <Switch>

        <Route exact path="/login" component={login} />

        <React.Fragment>
          <AppWrapper>
            <Appbar userProfile={authenticated ? user : {}} />
            <AppContentWrapper>
              <SideMenu />
              <Content>
                <div style={{ height: '100%' }}>
                  {authenticatedRoutes.map(
                    ({ component, path, displaySideMenu }) => (
                      <AuthenticatedRoute
                        exact
                        path={path}
                        key={path}
                        component={component}
                        displaySideMenu={displaySideMenu}
                      />
                    ),
                  )}
                </div>
              </Content>
            </AppContentWrapper>
          </AppWrapper>
        </React.Fragment>

        <Route component={systemRoutes[404]} />

      </Switch>
    </Router>
  );
};

export default App;
