import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  ActionButton,
  CommandBar,
  ContextualMenuItemType,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  Toggle,
} from 'office-ui-fabric-react';
import {
  copyAndSort,
} from '../../../../helpers';
import {
  MockContextualMenuLink,
  MockContextualMenuLinkItemText,
} from '../../../../components/mock-contextual-menu-link';
import QuickEdit from './components/quick-edit';
import {
  GET_ALL_SPECIAL_OFFERS,
  GET_SPECIAL_OFFER_DATA,
  UPDATE_SPECIAL_OFFERS_ORDER,
  DELETE_SPECIAL_OFFER,
  UNDELETE_SPECIAL_OFFER,
} from './graphql';

const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 40,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'title',
      name: 'Title',
      fieldName: 'title',
      minWidth: 70,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'tenant',
      name: 'Tenant',
      fieldName: 'tenant',
      minWidth: 70,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'sector',
      name: 'Sector',
      fieldName: 'sector',
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'startDate',
      name: 'Start Date',
      fieldName: 'startDate',
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'endDate',
      name: 'End Date',
      fieldName: 'endDate',
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'publishAt',
      name: 'Publish At',
      fieldName: 'publishAt',
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'expiresAt',
      name: 'Expires At',
      fieldName: 'expiresAt',
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'deletedAt',
      name: 'Published',
      fieldName: 'deletedAt',
      onRender: d => (!d.deletedAt ? 'Published' : 'Unpublished'),
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  tenants: [],
  filters: {
    tenant: 1,
    sector: 1,
    displayExpired: false,
    displayUnpublished: false,
  },
};

const SpecialOffers = ({ client }) => {
  const [state, setState] = useState(initialState);
  const [displayQuickEdit, setDisplayQuickEdit] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [errors, setErrors] = useState([]);
  const [quickEditMutated, setHasQuickEditMutated] = useState(false);
  let draggedItem;

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => (
        <Link to="/special-offers/create" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ActionButton
            className={link.className}
            role={link.role}
            iconProps={link.iconProps}
            allowDisabledFocus={link.allowDisabledFocus}
          >
            {link.text}
          </ActionButton>
        </Link>
      ),
    },
    {
      key: 'toggleExpired',
      name: state.filters.displayExpired ? 'Hide Expired' : 'Show Expired',
      iconProps: {
        iconName: state.filters.displayExpired ? 'ClearFilter' : 'Filter',
      },
      onClick: () => {
        setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayExpired: !state.filters.displayExpired,
          },
        }));
      },
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const specialOffersResponse = await client.query({
        query: GET_ALL_SPECIAL_OFFERS,
        variables: {
          showDeleted: true,
        },
      });

      const specialOfferDataResponse = await client.query({
        query: GET_SPECIAL_OFFER_DATA,
      });

      const { data: { allSpecialOffers } } = specialOffersResponse;
      const { data: { allSectors: sectors, allTenants: tenants } } = specialOfferDataResponse;

      const items = [];

      allSpecialOffers.forEach((s) => {
        const sector = sectors.find(t => parseInt(t.id, 10) === parseInt(s.page.sectorId, 10));
        const tenant = tenants.find(t => parseInt(t.id, 10) === parseInt(s.tenantId, 10));

        items.push({
          ...s,
          tenant: tenant ? tenant.name : null,
          sector: sector ? sector.name : null,
        });
      });

      setState({
        ...state,
        items: copyAndSort(items, 'order', 'number', false),
        sectors,
        tenants,
      });

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickEditMutated]);

  const handleQuickEdit = (id) => {
    setSelectedComponentId(id);
    setDisplayQuickEdit(true);
  };

  const handlePublishUnpublish = async (item) => {
    setErrors([]);

    const isPublishingSpecialOffer = item.deletedAt;

    const response = await client.mutate({
      mutation: isPublishingSpecialOffer ? UNDELETE_SPECIAL_OFFER : DELETE_SPECIAL_OFFER,
      variables: {
        id: parseInt(item.id, 10),
      },
    });

    if (!response) {
      setErrors([{ message: `${isPublishingSpecialOffer ? 'Publishing' : 'Unpublishing'} unsuccessful` }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    if (response && response.data) {
      // remove component from items
      const index = state.items.findIndex(offer => offer.id === item.id);
      const newItems = Array.from(state.items);
      // Overwrite deletedAt value from one of the two paths depending on delete/undelete
      const deletedAt = isPublishingSpecialOffer
        ? response.data.undeleteSpecialOffer.deletedAt
        : response.data.deleteSpecialOffer.deletedAt;
      newItems[index].deletedAt = deletedAt;

      setState(prevState => ({
        ...prevState,
        items: newItems,
      }));
    }
  };

  const onColumnClick = (e, column) => {
    const { columns, items } = state;
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        newCol.isSorted = false;
        // eslint-disable-next-line no-param-reassign
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items, currColumn.fieldName, currColumn.data, currColumn.isSortedDescending,
    );
    setState(prevState => ({
      ...prevState,
      columns: newColumns,
      items: newItems,
    }));
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'quickEditItem',
                  name: 'Quick Edit',
                  onClick: (e) => {
                    e.preventDefault();
                    handleQuickEdit(item.id);
                  },
                },
                {
                  key: 'advancedEditItem',
                  onRender: () => (
                    <MockContextualMenuLink>
                      <Link to={`/special-offers/${item.id}`} className="ms-ContextualMenu-link" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="ms-ContextualMenu-linkContent">
                          <MockContextualMenuLinkItemText className="ms-ContextualMenu-itemText">Advanced Edit</MockContextualMenuLinkItemText>
                        </div>
                      </Link>
                    </MockContextualMenuLink>
                  ),
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'publishUnpublishItem',
                  name: item.deletedAt ? 'Publish' : 'Unpublish',
                  onClick: () => {
                    handlePublishUnpublish(item);
                  },
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const filteredItems = () => {
    let allItems = [...state.items];
    const {
      filters: {
        displayExpired, displayUnpublished, sector, tenant,
      },
    } = state;

    if (!displayExpired) {
      const newDate = new Date().getTime();

      allItems = allItems.filter(({ expiresAt }) => new Date(expiresAt).getTime() >= newDate);
    }

    if (!displayUnpublished) {
      allItems = allItems.filter(({ deletedAt }) => deletedAt === null);
    }

    allItems = allItems.filter(({ tenantId, page: { sectorId } }) => (
      parseInt(sectorId, 10) === sector && parseInt(tenantId, 10) === tenant
    ));

    return allItems;
  };

  const insertBeforeItem = async (item) => {
    const { items } = state;
    const draggedItems = [draggedItem];

    const newItems = items.filter(itm => draggedItems.indexOf(itm) === -1);
    let insertIndex = items.indexOf(item);

    // if dragging/dropping on itself, index will be 0.
    if (insertIndex === -1) {
      insertIndex = 0;
    }

    newItems.splice(insertIndex, 0, ...draggedItems);

    const reorderedItems = newItems.map((offer, index) => ({
      ...offer,
      id: parseInt(offer.id, 10),
      order: index,
    }));

    const response = await client.mutate({
      mutation: UPDATE_SPECIAL_OFFERS_ORDER,
      variables: {
        input: reorderedItems.map(({ id: specialOfferId, order }) => ({ specialOfferId, order })),
      },
    });

    if (!response) {
      setErrors([{ message: 'Attempt to reorder items unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    setState({ ...state, items: reorderedItems });
  };

  const dragDropEvents = () => ({
    canDrop: () => true,
    canDrag: () => true,
    onDragLeave: () => {},
    onDrop: (item) => {
      if (draggedItem) {
        insertBeforeItem(item);
      }
    },
    onDragStart: (item) => {
      draggedItem = item;
    },
    onDragEnd: () => {
      draggedItem = undefined;
    },
  });

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {displayQuickEdit && (
        <QuickEdit
          specialOfferId={parseInt(selectedComponentId, 10)}
          setHasQuickEditMutated={setHasQuickEditMutated}
          quickEditMutated={quickEditMutated}
          handleOnDismiss={() => {
            setDisplayQuickEdit(false);
            setSelectedComponentId(null);
          }}
        />
      )}
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <React.Fragment>
          {showFilter && (
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <Dropdown
                label="Tenant"
                placeholder="Select a Tenant"
                name="tenantId"
                selectedKey={
                  state.filters.tenant
                    ? state.filters.tenant
                    : undefined
                }
                options={state.tenants.map(({ id, name }) => ({
                  key: id,
                  text: name,
                }))}
                onChange={(e, item) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    tenant: item.key,
                  },
                }))}
                styles={{ dropdown: { width: 168 } }}
              />
              <Dropdown
                label="Sector"
                placeholder="Select a Sector"
                name="sectorId"
                selectedKey={
                  state.filters.sector
                    ? state.filters.sector
                    : undefined
                }
                options={state.sectors.map(({ id, name }) => ({
                  key: id,
                  text: name,
                }))}
                onChange={(e, item) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    sector: item.key,
                  },
                }))}
                styles={{ dropdown: { width: 168 } }}
              />
              <Toggle
                defaultChecked={false}
                label="Display Expired"
                onText="Yes"
                offText="No"
                name="displayExpired"
                onChange={(e, checked) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    displayExpired: checked,
                  },
                }))}
              />
              <Toggle
                defaultChecked={false}
                label="Display Unpublished"
                onText="Yes"
                offText="No"
                name="displayUnpublished"
                onChange={(e, checked) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    displayUnpublished: checked,
                  },
                }))}
              />
            </Stack>
          )}

          {/* display errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
              isMultiline
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>
                {errors.map(({ message }) => (
                  <Text variant="medium" key={message}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}

          <Text variant="small">{`Displaying ${filteredItems().length} of ${state.items.length}`}</Text>
          <DetailsList
            items={filteredItems()}
            columns={state.columns.map(c => ({ ...c, onColumnClick }))}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            dragDropEvents={dragDropEvents()}
          />
        </React.Fragment>
      )}
    </div>
  );
};

SpecialOffers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(SpecialOffers);
