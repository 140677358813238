import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Stack,
  Text,
} from 'office-ui-fabric-react';

const defaultProps = {
  array: [10, 25, 50, 100],
};

const propTypes = {
  array: PropTypes.arrayOf(PropTypes.number),
  numberOfRecordsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  updateFunction: PropTypes.func.isRequired,
};

const PaginationResultsButtonRow = ({
  array,
  numberOfRecordsPerPage,
  totalCount,
  updateFunction,
}) => (
  <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 10 }}>
    <Text>Results Per Page:</Text>
    {
      [...array, totalCount].map(num => num <= totalCount && (
        <ActionButton
          id={num}
          key={num}
          disabled={num === parseInt(numberOfRecordsPerPage, 10)}
          onClick={() => updateFunction(num)}
        >
          {num}
        </ActionButton>
      ))
    }
  </Stack>
);

PaginationResultsButtonRow.defaultProps = defaultProps;
PaginationResultsButtonRow.propTypes = propTypes;

export default PaginationResultsButtonRow;
