import { styled } from 'styletron-react';
import { Link as ReactRouterLink } from 'react-router-dom';

export const SideMenuWrapper = styled('div', {
  boxSizing: 'border-box',
  paddingTop: '8px',
  paddingRight: '24px',
  paddingBottom: '8px',
  paddingLeft: '8px',
  borderTopWidth: '0px',
  borderRightWidth: '1px',
  borderBottomWidth: '0px',
  borderLeftWidth: '0px',
  borderStyle: 'solid',
  borderColor: '#cccccc',
});

export const Link = styled(ReactRouterLink, {
  color: 'inherit',
  textAlign: 'left',
  textDecoration: 'none',
  width: '100%',
  ':focus': {
    outline: 'none',
  },
});
