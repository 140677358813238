/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  GroupedList,
  Stack,
  Modal,
  Label,
  TextField,
  DefaultButton,
} from 'office-ui-fabric-react';
import GroupHeader from './GroupHeader';
import { GET_PAGES_DATA } from './graphql';

const defaultProps = {
  parentId: undefined,
};

const propTypes = {
  setState: PropTypes.func.isRequired,
  parentId: PropTypes.number,
};

const ParentModal = ({
  client, parentId, setState: setParentState,
}) => {
  const [loading, setIsLoading] = useState(true);
  const [state, setState] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const getData = await client.query({
        query: GET_PAGES_DATA,
      });

      const { data: { allPages } } = getData;

      const mutatedPages = allPages.map((p, index) => ({
        ...p,
        key: p.name,
        name: p.name,
        count: 0,
        startIndex: index,
        isCollapsed: true,
        level: 0,
        children: [],
      }));

      const groups = mutatedPages.filter(p => !p.parentId);

      // maps out items to groups
      mutatedPages.map((p) => {
        const recurse = (array) => {
          array.map((item) => {
            if (parseInt(item.id, 10) === p.parentId) {
              item.children.push(p);
            } else {
              // rerun function with children array
              recurse(item.children);
            }
          });
        };

        recurse(groups);
      });

      const updatePageProperties = (array, level) => {
        array.map((item) => {
          item.level = level;
          if (item.children.length) {
            item.count = item.children.length;
            updatePageProperties(item.children, level + 1);
          } else {
            item.count = 1;
          }
        });
      };
      updatePageProperties(groups, 0);

      setState({ allPages, mutatedPages, groups });
      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRenderHeader = (props) => {
    const {
      group: {
        id,
        name,
        url,
        children,
        level,
        isCollapsed,
        isSelected,
      },
    } = props;

    /*
      groups are nested hence the need for recursive iteration
      to update the correct isCollapsed property
    */
    const toggleCollapse = () => {
      const { groups: newGroupsArray } = state;

      const recurse = (array) => {
        array.map((group, index) => {
          if (parseInt(group.id, 10) === parseInt(id, 10)) {
            const newGroup = group;
            newGroup.isCollapsed = !group.isCollapsed;
            array.splice(index, 1, newGroup);
            return;
          }
          recurse(group.children);
        });
      };

      recurse(newGroupsArray);

      setState({ ...state, groups: [...newGroupsArray] });
    };

    return (
      <GroupHeader
        id={id}
        name={name}
        url={url}
        level={level}
        isSelected={isSelected}
        setParentState={setParentState}
        toggleCollapse={toggleCollapse}
        toggleModalOpen={setIsOpen}
        isCollapsed={isCollapsed}
      >
        {children}
      </GroupHeader>
    );
  };

  return (
    <>
      <Stack>
        <Label>Parent</Label>
        <Stack horizontal tokens={{ childrenGap: 24 }}>
          <Stack tokens={{ childrenGap: 8 }}>
            {!loading && (
            <>
              <TextField
                prefix="Name"
                value={
                  parentId ? state.allPages.find(({ id }) => (
                    parseInt(id, 10) === parseInt(parentId, 10))).name
                    : undefined
                }
                readOnly
                placeholder="Select a parent.."
                ariaLabel="Parent Name"
                styles={{ root: { width: 360 } }}
              />
              <TextField
                prefix="URL"
                value={
                  parentId ? state.allPages.find(({ id }) => (
                    parseInt(id, 10) === parseInt(parentId, 10))).url
                    : undefined
                }
                readOnly
                placeholder="Select a parent.."
                ariaLabel="Parent URL"
                styles={{ root: { width: 360 } }}
              />
            </>
            )}
          </Stack>
          <DefaultButton onClick={() => setIsOpen(true)}>Select</DefaultButton>
        </Stack>
      </Stack>

      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        styles={{ main: { height: '100%', minWidth: 800, width: '80%' } }}
      >
        <GroupedList
          groupProps={{ onRenderHeader }}
          items={state.mutatedPages}
          groups={state.groups}
        />
      </Modal>
    </>
  );
};

ParentModal.defaultProps = defaultProps;
ParentModal.propTypes = propTypes;

export default withApollo(ParentModal);
