/* eslint-disable no-return-assign */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import {
  CommandBar,
  ContextualMenuItemType,
  DefaultButton,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import { copyAndSort, handleErrorResponse } from 'helpers';
import {
  CommandBarButton,
  MenuLinkButton,
  MessageList,
  OverviewList,
} from 'components';
import {
  GET_PARK_MESSAGES,
  DELETE_PARK_MESSAGE,
} from './graphql';

const propTypes = {
  client: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 60,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'number',
    },
    {
      key: 'parkName',
      name: 'Park',
      fieldName: 'parkName',
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'message',
      name: 'Message',
      fieldName: 'message',
      minWidth: 120,
      maxWidth: 260,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'author',
      name: 'Author',
      fieldName: 'author',
      minWidth: 90,
      maxWidth: 260,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'jobTitle',
      name: 'Job Title',
      fieldName: 'jobTitle',
      minWidth: 90,
      maxWidth: 260,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'publishAt',
      name: 'Publish At',
      fieldName: 'publishAt',
      minWidth: 120,
      maxWidth: 260,
      isResizable: true,
      data: 'timestamp',
      isPadded: true,
    },
    {
      key: 'expiresAt',
      name: 'Expires At',
      fieldName: 'expiresAt',
      minWidth: 120,
      maxWidth: 260,
      isResizable: true,
      data: 'timestamp',
      isPadded: true,
    },
    {
      key: 'createdAt',
      name: 'Created At',
      fieldName: 'createdAt',
      minWidth: 120,
      maxWidth: 260,
      isResizable: true,
      data: 'timestamp',
      isPadded: true,
    },
    {
      key: 'updatedAt',
      name: 'Last Modified',
      fieldName: 'updatedAt',
      minWidth: 120,
      maxWidth: 260,
      isResizable: true,
      data: 'timestamp',
      isPadded: true,
    },
    {
      key: 'actions',
      name: '',
      maxWidth: 120,
    },
  ],
  items: [],
  filters: {
    text: '',
    displayExpired: false,
  },
};

const ParkMessages = ({ client, ...otherProps }) => {
  const { messageTypeId, location: { pathname } } = otherProps;

  const [state, setState] = useState(initialState);
  const [loading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => <CommandBarButton link={link} to={`${pathname}/messages/create`} />,
    },
    {
      key: 'toggleExpired',
      name: state.filters.displayExpired ? 'Hide Expired' : 'Show Expired',
      iconProps: {
        iconName: state.filters.displayExpired ? 'ClearFilter' : 'Filter',
      },
      onClick: () => {
        setState(({
          ...state,
          filters: {
            ...state.filters,
            displayExpired: !state.filters.displayExpired,
          },
        }));
      },
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    (async () => {
      const { data: { allParkMessages } } = await client.query({
        query: GET_PARK_MESSAGES,
        variables: {
          id: parseInt(messageTypeId, 10),
        },
      });

      const messages = allParkMessages.filter(({ parkMessageTypeId }) => (
        parseInt(parkMessageTypeId, 10) === parseInt(messageTypeId, 10)));

      const items = messages.map(({
        contact,
        park,
        ...m
      }) => {
        const parkName = park && park.name ? park.name : '';
        if (!park) {
          console.log('park', park, contact);
        }
        /*
          25/02/2020

          The below variables and conditional prevent build from breaking when using the test data.
          Park contact will be required for new messages so this can probably be deleted later.
          Salutation is not a required field when creating a contact hence the extra conditional
          for the author property.
         */

        let firstname;
        let surname;
        let salutation;
        let jobRole;

        if (contact) {
          firstname = contact.firstname;
          surname = contact.surname;
          salutation = contact.salutation;
          jobRole = contact.jobRole;
        }

        return ({
          ...m,
          author: firstname && surname ? `${surname}, ${salutation ? `${salutation} ` : ''}${firstname}` : '',
          jobTitle: jobRole ? jobRole.jobTitle : '',
          message: m.message.length > 0 ? `${m.message.slice(0, 50)}..` : '',
          parkName,
        });
      });

      setState({ ...state, items: copyAndSort(items, 'id', 'number', true) });
      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = () => {
    let allItems = [...state.items];

    const { filters: { displayExpired, text } } = state;

    if (!displayExpired) {
      const newDate = new Date().getTime();

      allItems = allItems.filter(({ expiresAt }) => new Date(expiresAt).getTime() >= newDate);
    }

    if (text !== '') {
      allItems = allItems.filter((item) => {
        // filter unneeded key values
        const {
          __typename, data, park, ...rest
        } = item;

        const filterableData = Object.values(rest);

        return (
          JSON.stringify(filterableData)
            .toLowerCase()
            .indexOf(state.filters.text.toLowerCase()) > -1
        );
      });
    }

    return allItems;
  };

  const handleDelete = async (item) => {
    setErrors([]);
    setSuccessMessages([]);

    const response = await client.mutate({
      mutation: DELETE_PARK_MESSAGE,
      variables: {
        id: parseInt(item.id, 10),
      },
    });

    if (!response || response.errors) {
      setErrors(...handleErrorResponse({ response, message: 'No response' }));
      return;
    }

    if (response.data) {
      const items = state.items.filter(({ id }) => id !== response.data.deleteParkMessage.id);

      setState({ ...state, items });
      setSuccessMessages([{ message: `Message ${response.data.deleteParkMessage.id} deleted` }]);
    }
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'advancedEditItem',
                  onRender: () => <MenuLinkButton to={`${pathname}/messages/${item.id}`}>Advanced Edit</MenuLinkButton>,
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'deleteItem',
                  name: 'Delete',
                  onClick: () => {
                    handleDelete(item);
                  },
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <React.Fragment>
          {showFilter && (
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <TextField
                label="Filter by"
                placeholder="Search"
                name="text"
                onChange={e => setState(({
                  ...state,
                  filters: {
                    ...state.filters,
                    text: e.target.value,
                  },
                }))
              }
              />
              <Toggle
                defaultChecked={false}
                label="Display Expired"
                onText="Yes"
                offText="No"
                name="displayExpired"
                onChange={(e, checked) => setState(({
                  ...state,
                  filters: {
                    ...state.filters,
                    displayExpired: checked,
                  },
                }))}
              />
            </Stack>
          )}

          {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
          {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

          <Text variant="small">
            {`Displaying ${filteredItems().length} of ${state.items.length}`}
          </Text>

          <OverviewList
            columns={state.columns}
            items={state.items}
            setState={setState}
            filteredItems={filteredItems()}
            renderItemColumn={renderItemColumn}
          />
        </React.Fragment>
      )}
    </div>
  );
};

ParkMessages.propTypes = propTypes;

export default withApollo(withRouter(ParkMessages));
