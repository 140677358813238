import React from 'react';
import PropTypes from 'prop-types';
import {
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from 'office-ui-fabric-react';

const defaultProps = {
  messageFunction: () => false,
};

const propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  messageFunction: PropTypes.func,
  messageType: PropTypes.string.isRequired,
};

const MessageList = ({ messages, messageFunction, messageType }) => (
  <MessageBar
    messageBarType={MessageBarType[messageType]}
    isMultiline
    onDismiss={() => messageFunction([])}
    dismissButtonAriaLabel="Close"
  >
    <Stack tokens={{ childrenGap: 8 }}>
      {messageType === 'error' && (
        <Text variant="medium">
          {`
            ${messages.flatMap(obj => Object.keys(obj)).includes('title') ? 'Input ' : 'Server '}
            ${messages.length === 1 ? 'error' : 'errors'}:
          `}
        </Text>
      )}

      {messageType === 'success' && (
        <Text variant="medium">Success</Text>
      )}

      {messages.map(({ message }) => (
        <Text variant="medium" key={message}>
          {`- ${message}`}
        </Text>
      ))}
    </Stack>
  </MessageBar>
);

MessageList.defaultProps = defaultProps;
MessageList.propTypes = propTypes;

export default MessageList;
