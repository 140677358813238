import gql from 'graphql-tag';

export const GENERATE_PRESIGNED_URL = gql`
  mutation GeneratePresignedUrl($input: GeneratePresignedUrlInput!) {
    generatePresignedUrl(input: $input) {
      url
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation updateMedia($id: Int!, $input: UpdateMediaInput!) {
    updateMedia(id: $id, input: $input) {
      id
    }
  }
`;

export const GET_MEDIA = gql`
  query media($id: ID!) {
    media(id: $id) {
      path
    }
  }
`;
