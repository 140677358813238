/* eslint-disable import/no-unresolved */
import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  CommandBar,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import {
  CommandBarButton,
  MenuLinkButton,
  MessageList,
  PaginationButtonRow,
  PaginationResultsButtonRow,
  ShimmeredOverviewList,
} from 'components';
import {
  copyAndSort,
  cursorPaginationUpdateFunctions,
  handleErrorResponse,
  useDebounce,
} from 'helpers';
import { GET_ALL_FILES, REMOVE_ASSETS_FROM_OWNERS_AREA_RESOURCES } from './graphql';
import { initialState, reducer } from './initialState';
import QuickEdit from './components/quick-edit';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

const Files = ({ client, match: { path } }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    columns,
    cursorPaginationObject,
    displayQuickEdit,
    errors,
    filters,
    items,
    loading,
    numberOfRecordsPerPage,
    pageCount,
    quickEditMutated,
    selectedOwnersAreaResourceId,
    showFilter,
  } = state;

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => <CommandBarButton link={link} to={`${path}/create`}>{link.text}</CommandBarButton>,
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => dispatch({ type: 'TOGGLE_FILTER_DISPLAY' }),
    },
  ];

  const debouncedSearchTerm = useDebounce(filters.text, 600);

  const fetchData = async () => {
    dispatch({ type: 'FETCHING' });

    const { data: { allOwnersAreaResources: { edges, pageInfo, totalCount } } } = (
      await client.query({
        query: GET_ALL_FILES,
        variables: {
          cursorPagination: {
            ...cursorPaginationObject,
            first: numberOfRecordsPerPage,
          },
          criteria: {
            fuzzySearch: debouncedSearchTerm,
          },
        },
      })
    );

    const nodes = edges.map(({ node }) => node);
    const files = nodes.map(item => ({
      // using the id from the parent resource and the remaining data from the nested asset
      ...item,
      image: item.asset.media && item.asset.media.path ? item.asset.media.path : '',
      title: item.asset.title,
      createdAt: item.asset.createdAt,
      updatedAt: item.asset.updatedAt,
    }));

    dispatch({ type: 'UPDATE_PAGINATED_ITEMS', payload: { totalCount, pageInfo, items: copyAndSort(files, 'createdAt', 'datetime', true) } });
  };

  useMemo(() => fetchData(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      cursorPaginationObject,
      debouncedSearchTerm,
      numberOfRecordsPerPage,
      pageCount,
      quickEditMutated,
    ]);

  const handleAssetRemoval = async (assetId) => {
    const response = await client.mutate({
      mutation: REMOVE_ASSETS_FROM_OWNERS_AREA_RESOURCES,
      variables: {
        input: [{ assetId }],
      },
    });

    if (!response || response.errors) {
      const [payload] = handleErrorResponse({ response, message: 'Deletion unsuccessful' });
      dispatch({ type: 'UPDATE_ERRORS', payload });
      return;
    }

    if (response.data) {
      fetchData();
    }
  };

  const returnMenuItems = item => [
    {
      key: 'quickEditItem',
      name: 'Quick Edit',
      onClick: (e) => {
        e.preventDefault();
        dispatch({ type: 'SELECT_QUICK_EDIT', payload: item.id });
      },
    },
    {
      key: 'advancedEditItem',
      onRender: () => <MenuLinkButton to={`${path}/${item.id}`}>Advanced Edit</MenuLinkButton>,
    },
    {
      key: 'deleteItem',
      name: 'Delete',
      onClick: () => handleAssetRemoval(item.asset.id),
    },
  ];

  const updatePaginationCriteria = cursorPaginationUpdateFunctions(
    {
      dispatch,
      startCursor: state.pageInfo.startCursor,
      endCursor: state.pageInfo.endCursor,
      numberOfRecordsPerPage,
      type: 'UPDATE_PAGINATION_CRITERIA',
    },
  );

  return (
    <Stack
      styles={{ root: { height: '100%' } }}
      verticalAlign="space-between"
    >

      {errors.length > 0 && (
        <MessageList
          messages={errors}
          messageFunction={() => dispatch({ type: 'UPDATE_ERRORS', payload: [] })}
          messageType="error"
        />
      )}

      <Stack>
        <CommandBar
          items={commandBarItems}
          farItems={commandBarFarItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
        />

        {displayQuickEdit && (
          <QuickEdit
            ownersAreaResourceId={parseInt(selectedOwnersAreaResourceId, 10)}
            handleOnDismiss={() => dispatch({ type: 'DISMISS_QUICK_EDIT' })}
            setQuickEditMutated={() => dispatch({ type: 'SET_QUICK_EDIT_MUTATED' })}
          />
        )}

        {showFilter && (
          <TextField
            label="Filter by"
            placeholder="Search"
            name="text"
            onChange={e => dispatch({ type: 'UPDATE_FILTER_VALUE', field: 'text', payload: e.target.value })}
            styles={{ root: { width: 180 } }}
          />
        )}

        <Text variant="small">{`Displaying ${items.length} of ${state.totalCount}`}</Text>

        <ShimmeredOverviewList
          items={items}
          loading={loading}
          columns={columns}
          returnMenuItems={returnMenuItems}
          setState={(newColumns, newItems) => dispatch(({ type: 'UPDATE_COLUMNS', payload: { columns: newColumns, items: newItems } }))}
        />
      </Stack>

      <Stack>
        <PaginationButtonRow
          incrementFunction={updatePaginationCriteria.increment}
          decrementFunction={updatePaginationCriteria.decrement}
          loading={loading}
          pageCount={pageCount}
          selectPageFunction={updatePaginationCriteria.select}
          numOfPages={Math.ceil(state.totalCount / numberOfRecordsPerPage)}
        />

        <PaginationResultsButtonRow
          numberOfRecordsPerPage={numberOfRecordsPerPage}
          totalCount={state.totalCount}
          updateFunction={updatePaginationCriteria.updateNumberOfRecords}
        />
      </Stack>
    </Stack>
  );
};

Files.propTypes = propTypes;

export default withRouter(withApollo(Files));
