import { createContext } from 'react';

const UserContext = createContext({
  user: {
    sub: {
      displayName: null,
    },
  },
  authenticated: false,
  setUser: () => {},
});

export default UserContext;
