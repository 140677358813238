/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { convertHTMLDatetimeString } from 'helpers';
import { MessageList } from 'components';
import {
  GET_PARK_NOTICE,
  GET_PARK_NOTICE_DATA,
  CREATE_PARK_NOTICE,
  UPDATE_PARK_NOTICE,
  ADD_PARK_NOTICES_TO_PARKS,
  REMOVE_PARK_NOTICES_FROM_PARKS,
} from './graphql';

const UpdateParkNotice = ({ client, ...otherProps }) => {
  const {
    match: {
      params: { id: parkNoticeId },
    },
  } = otherProps;

  const [isCreatingNewParkNotice, setIsCreatingNewParkNotice] = (
    useState(Number.isNaN(parseInt(parkNoticeId, 10))));

  const [state, setState] = useState({
    parkNotice: {
      id: null,
      title: '',
      description: '',
      publishAt: '',
      expireAt: '',
      parks: [],
    },
    initialSelectedParks: [],
    allParks: [],
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  useEffect(() => {
    (async () => {
      let parkNotice = {};

      if (!isCreatingNewParkNotice) {
        const getParkNotice = await client.query({
          query: GET_PARK_NOTICE,
          variables: {
            id: parkNoticeId === 'create' ? state.parkNotice.id : parseInt(parkNoticeId, 10),
            showDeleted: true,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        parkNotice = getParkNotice.data.parkNotice;
      }

      const { data: { allParks } } = await client.query({
        query: GET_PARK_NOTICE_DATA,
      });

      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      const objectForState = !isCreatingNewParkNotice
        ? parkNotice
        : state.parkNotice;

      setState({
        ...state,
        parkNotice: objectForState,
        initialSelectedParks: objectForState.parks,
        allParks: sortedParks,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewParkNotice]);

  const handleInputChange = ({ target: { name, value } }) => {
    const newParkNotice = Object.assign({}, state.parkNotice);
    newParkNotice[name] = value;
    setState({ ...state, parkNotice: newParkNotice });
  };

  const toggleSelectedPark = (e, parkId) => {
    const { target: { checked } } = e;
    const { parkNotice: { parks } } = state;

    let newParksArray = [];

    if (checked) {
      newParksArray = [...parks, { id: parkId }];
    } else {
      newParksArray = parks.filter(({ id }) => parseInt(id, 10) !== parseInt(parkId, 10));
    }

    setState(prevState => ({
      ...prevState,
      parkNotice: {
        ...prevState.parkNotice,
        parks: newParksArray,
      },
    }));
  };

  const toggleSelectAllParks = () => {
    const selectedParksLength = state.parkNotice.parks.length;
    const allParksLength = state.allParks.length;

    const parks = selectedParksLength === allParksLength
      ? [] : state.allParks.map(({ id }) => ({ id }));

    setState({
      ...state,
      parkNotice: {
        ...state.parkNotice,
        parks,
      },
    });
  };

  const validateData = () => {
    const errorsFound = [];

    const { parkNotice: { parks } } = state;

    if (parks.length === 0) {
      errorsFound.push({
        title: 'Invalid Parks',
        message: 'Please ensure you have selected at least one park',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleParkNoticeMutation = async () => {
    const {
      parkNotice: {
        __typename,
        id,
        parks,
        ...input
      },
    } = state;

    const formatDateString = string => string.substring(0, 16).concat(':00');

    const response = await client.mutate({
      mutation: isCreatingNewParkNotice ? CREATE_PARK_NOTICE : UPDATE_PARK_NOTICE,
      variables: {
        ...(!isCreatingNewParkNotice && { id: state.parkNotice.id }),
        input: {
          ...input,
          publishAt: formatDateString(input.publishAt),
          expireAt: formatDateString(input.expireAt),
        },
      },
    });

    if (!response) {
      throw new Error(`No response. Failed at ${isCreatingNewParkNotice ? 'creating' : 'updating'} Park Notice`);
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    if (response && response.data) {
      const responseMapped = isCreatingNewParkNotice
        ? response.data.createParkNotice
        : response.data.updateParkNotice;

      return ({
        parkNoticeId: responseMapped.id,
      });
    }

    return false;
  };

  const handleParkAssociationMutations = async (type, arrayOfParkIds, noticeId) => {
    const meta = {
      add: {
        mutation: ADD_PARK_NOTICES_TO_PARKS,
        response: 'addParkNoticeToParks',
      },
      remove: {
        mutation: REMOVE_PARK_NOTICES_FROM_PARKS,
        response: 'removeParkNoticeFromParks',
      },
    };

    const input = arrayOfParkIds.map(parkId => ({
      parkNoticeId: noticeId,
      parkId,
    }));

    const response = await client.mutate({
      mutation: meta[type].mutation,
      variables: {
        input,
      },
    });

    if (!response) {
      throw new Error(`No response. Failed at mutation ${meta[type].response}`);
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    if (response.data) {
      setState({
        ...state,
        initialSelectedParks: state.parkNotice.parks,
      });
    }
  };

  const handleSave = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSuccessMessages([]);

    const { parkNotice: { parks }, initialSelectedParks } = state;
    const initialParkIds = initialSelectedParks.map(p => p.id);
    const parkIds = parks.map(p => p.id);

    const additions = parkIds.filter(id => !initialParkIds.includes(id));
    const removals = initialParkIds.filter(id => !parkIds.includes(id));

    if (validateData()) {
      try {
        const { parkNoticeId: id } = await handleParkNoticeMutation();

        if (additions.length > 0) {
          await handleParkAssociationMutations('add', additions, id);
        }

        if (removals.length > 0) {
          await handleParkAssociationMutations('remove', removals, id);
        }

        setState({
          ...state,
          parkNotice: { ...state.parkNotice, id },
        });
        setSuccessMessages([{ message: `Park Notice ${isCreatingNewParkNotice ? 'created' : 'updated'}` }]);
        setIsCreatingNewParkNotice(false);
      } catch (e) {
        setErrors([{ message: e }]);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="Park Notice ID"
              name="id"
              value={state.parkNotice.id}
              prefix="#"
              type="number"
              readOnly
            />

            <TextField
              label={`Title: (${
                state.parkNotice.title.length
              }/50 characters)`}
              name="title"
              placeholder="Enter a title.."
              value={state.parkNotice.title}
              onChange={e => handleInputChange(e)}
              ariaLabel="Title"
              maxLength={50}
              required
            />

            <Stack tokens={{ childrenGap: 8 }}>
              <Label variant="medium" style={{ fontWeight: 600 }}>
                Associated Parks
              </Label>

              <Checkbox
                label="Select All"
                checked={state.allParks.length === state.parkNotice.parks.length}
                onChange={() => toggleSelectAllParks()}
              />

              <Stack tokens={{ childrenGap: 4 }}>
                {state.allParks.map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    label={name}
                    checked={
                      state.parkNotice.parks
                        .findIndex(
                          o => parseInt(o.id, 10) === parseInt(id, 10),
                        ) >= 0
                    }
                    onChange={e => toggleSelectedPark(e, id)}
                  />
                ))}
              </Stack>
            </Stack>

            <TextField
              label={`Description: (${
                state.parkNotice.description.length
              }/4000 characters)`}
              name="description"
              placeholder="Enter a description.."
              value={state.parkNotice.description}
              onChange={e => handleInputChange(e)}
              multiline
              rows={8}
              maxLength={4000}
              required
            />

            {/*
              Using HTML5 inputs for date-times until a date-time picker is added to the
              office fabric spec. As of 26/11/19 only date picker is available.
            */}
            <TextField
              label="Publish Date"
              name="publishAt"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: state.parkNotice.publishAt, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="Expiry Date"
              name="expireAt"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: state.parkNotice.expireAt, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateParkNotice.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(UpdateParkNotice);
