import React, { useState } from 'react';
import UpdateSpecialOffer from './edit-special-offer';
import UpdatePage from './edit-page';

/*
  TODO: Paramapedia™
    - add link to meta field to display available key/values
    - link to MD file within CMS './src/docs' ./paramepedia/markdown
*/

/*
  Note:
    This page acts as a controller for the UpdateSpecialOffer and UpdatePage components.
    The overall state is held in this parent component, and the child components then update
    the parent state from within their own scope. This should enable easier transitioning between
    components.

    1) Fill in special offer details
    2) Fill in page details
    3) Save page
    4) Return pageId from graphql
    5) Push pageId into special offer and save special offer

  General rules for this section:
    New special offer:
      - allow changing of any input
      - action to generate a suggested URL for the user
      - single action to request to create special offer AND page

    Updating existing special offer:
      - disallow changing of page template
      - display URL (allow it to be editable)
      - allow user to edit special offer/page/pagebuilder individually
*/

const UpdateSpecialOfferController = ({ match }) => {
  const { params: { id: specialOfferId } } = match;
  const [isCreatingNewSpecialOffer, setIsCreatingNewSpecialOffer] = (
    useState(Number.isNaN(parseInt(specialOfferId, 10))));

  const [currentView, setCurrentView] = useState(1);

  const [state, setState] = useState({
    specialOffer: {
      id: null,
      tenantId: 1, // null
      title: '',
      shortDescription: '',
      meta: {},
      pageId: null,
      mediaId: null,
      startDate: '',
      endDate: '',
      publishAt: '',
      expiresAt: '',
      parks: [],
      holidayHomes: [],
      media: [],
    },
    page: {
      id: null,
      tenantId: null, // specified within state of special offer
      sectorId: null,
      pageTypeId: 2, // hard-coded special-offer page type
      parentId: null, // parent page ID (select page from dropdown or similar)
      url: '', // === parentPageId URL + '/' + slugified('navigation-label'); (add a button to override everything after the fwd slash)
      navigationLabel: '', // shortened name for navigation
      title: '', // SEO meta
      description: '', // SEO meta
      canonical: '', // should be locked by default (to replicate URL) but accessible to change by clicking button
      data: '', // JSON prop-types data for template
      priority: 0.75, // 0 - 1; step of 0.01
      order: 1,
      urlLocked: false, // prevents user from changing URL
      systemPage: false, // page that cannot be modified using page-builder
      noIndex: false, // SEO meta
      showOnWebSidebar: false,
      publishedAt: '', // default to today's date
      pageTemplateId: null,
      pagebuilderDisabled: false,
    },
  });

  const handleBack = () => setCurrentView(currentView - 1);
  const handleForward = () => setCurrentView(currentView + 1);

  const STATES = {
    1: 'specialOffer',
    2: 'page',
    // 3: 'pagebuilder'
  };

  const COMPONENTS = {
    specialOffer: <UpdateSpecialOffer />,
    page: <UpdatePage />,
  };

  const PROPS = {
    specialOffer: {
      handleForward,
      isCreatingNewSpecialOffer,
      parentState: state.specialOffer,
      setParentState: setState,
      specialOfferId,
    },
    page: {
      handleBack,
      handleForward,
      pageId: state.specialOffer.pageId,
      parentState: state,
      setIsCreatingNewSpecialOffer,
      setParentState: setState,
    },
  };

  return (
    React.cloneElement(
      COMPONENTS[STATES[currentView]],
      { ...PROPS[STATES[currentView]] },
    )
  );
};

export default UpdateSpecialOfferController;
