import gql from 'graphql-tag';

export const GET_ALL_EVENTS = gql`
  query allEvents ($criteria: EventCriteria, $cursorPagination: CursorPaginationCriteria, $showDeleted: Boolean, $filters: [Filter]) {
    allEvents(criteria: $criteria, cursorPagination: $cursorPagination, showDeleted: $showDeleted, filters: $filters) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          description
          startDate
          endDate
          pinned
          slug
          cancelled
          park {
            name
          }
          type {
            name
          }
          eventTypeId
          parkId
          createdAt
          deletedAt
        }
      }
    }
  }
`;

export const GET_EVENT_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
    allEventTypes {
      id
      name
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id) {
      id
      deletedAt
    }
  }
`;

export const UNDELETE_EVENT = gql`
  mutation undeleteEvent($id: Int!) {
    undeleteEvent(id: $id) {
      id
      deletedAt
    }
  }
`;
