import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import {
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from 'office-ui-fabric-react';
import { withApollo } from 'react-apollo';
import { GENERATE_PRESIGNED_URL, GET_MEDIA, UPDATE_MEDIA } from './graphql';
import { Doka } from './react-doka';

const ImageEditor = ({ client, ...props }) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [state, setState] = useState({
    loading: true,
    path: '',
  });
  const [userResponse, setUserResponse] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await client
        .query({
          query: GET_MEDIA,
          variables: {
            id,
          },
        });

      const { data: { media: { path } } } = response;

      setState({
        path,
        loading: false,
      });
    };

    fetchData();
  }, [client, id]);

  const handleSaveChanges = async (output) => {
    const { file } = output;
    setUserResponse({});

    const newFilename = md5(
      `${new Date().getTime().toString()}-${(Math.random() * 10000)
        .toString()
        .replace('.', '')}`,
    );

    const getPresignedURL = async () => {
      const res = await client
        .mutate({
          mutation: GENERATE_PRESIGNED_URL,
          variables: {
            input: {
              filename: newFilename,
              path: 'assets/',
              contentType: 'image/jpeg',
            },
          },
        });

      return res.data.generatePresignedUrl.url;
    };

    const fileSendRequest = async (presignedURL) => {
      // eslint-disable-next-line no-undef
      const res = await fetch(presignedURL, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'image/jpeg',
        },
        mode: 'cors',
      });

      return res;
    };

    const updateMediaRecord = async () => {
      const res = await client.mutate({
        mutation: UPDATE_MEDIA,
        variables: {
          id,
          input: {
            path: newFilename,
          },
        },
      });

      return res;
    };

    try {
      const presignedUrl = await getPresignedURL();
      const req = await fileSendRequest(presignedUrl);
      const updateMedia = await updateMediaRecord();
    } catch (err) {
      setUserResponse({
        title: 'Please try submitting the image again or report this to the IT help desk.',
        type: 'Error',
        description: err,
      });
    }

    if (Object.entries(userResponse).length === 0) {
      setUserResponse({
        title: 'Image has been saved',
        type: 'Success',
      });
    }
  };

  const { loading, path } = state;

  if (loading) {
    return <Spinner label="Loading..." size={SpinnerSize.medium} />;
  }

  return (
    <Stack>
      {
        Object.entries(userResponse).length !== 0 && (
          <MessageBar
            messageBarType={MessageBarType[userResponse.type.toLowerCase()]}
            isMultiline
            onDismiss={() => setUserResponse({})}
            dismissButtonAriaLabel="Close"
          >
            <Stack tokens={{ childrenGap: 8 }}>
              <Text variant="medium">{userResponse.type}</Text>
              <Text variant="medium">{userResponse.title}</Text>
              {
                userResponse.description && (
                  <Text variant="medium">{userResponse.description}</Text>
                )
              }
            </Stack>
          </MessageBar>
        )}

      <Doka
        style={{ height: 'calc(100vh - 66px)', width: '100%' }}
        utils={['crop', 'color', 'resize']}
        src={`https://assets.parkholidays.com/assets/${path}`}
        allowAutoDestroy
        allowDropFiles={false}
        cropAllowImageTurnRight
        labelButtonConfirm="Save"
        labelButtonCancel="Back"
        oncancel={() => props.history.goBack()}
        onconfirm={output => handleSaveChanges(output)}
      />
    </Stack>
  );
};

ImageEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]).isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default withApollo(ImageEditor);
