import gql from 'graphql-tag';

export const GET_PAGE = gql`
  query page($id: ID!) {
    page(id: $id) {
      id
      name
      pageTemplateId
      tenantId
      sectorId
      pageTypeId
      parentId
      url
      navigationLabel
      title
      description
      canonical
      data
      priority
      order
      urlLocked
      systemPage
      noIndex
      showOnWebSidebar
      publishedAt
    }
  }
`;

export const GET_PAGE_DATA = gql`
  {
    allSectors {
      id
      name
    }
    allTenants {
      id
      name
    }
    allPages {
      id
      name
      url
    }
    allPageTemplates {
      id
      name
    }
  }
`;

export const CREATE_SPECIAL_OFFER = gql`
  mutation createSpecialOffer($input: CreateSpecialOfferInput!) {
    createSpecialOffer(input: $input) {
      id
    }
  }
`;

export const CREATE_CS_SPECIAL_OFFER = gql`
  mutation CreateCSSpecialOffer($input: CreateCSSpecialOfferInput!) {
    createCSSpecialOffer(input: $input) {
      id
    }
  }
`;

export const CREATE_PAGE = gql`
  mutation createPage($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      tenantId
    }
  }
`;

export const UPDATE_PAGE = gql`
  mutation updatePage($id: Int!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      id
      tenantId
    }
  }
`;

export const ADD_SPECIAL_OFFER_TO_PARK = gql`
  mutation addSpecialOfferToPark($input: [SpecialOfferParkAssociationInput]!) {
    addSpecialOfferToPark(input: $input) {
      specialOfferId
    }
  }
`;
