import gql from 'graphql-tag';

export const GET_ALL_SPECIAL_OFFERS = gql`
  query allSpecialOffers($showDeleted: Boolean!) {
    allSpecialOffers(showDeleted: $showDeleted) {
      id
      tenantId
      page {
        sectorId
      }
      title
      startDate
      endDate
      publishAt
      expiresAt
      deletedAt
      order
    }
  }
`;

export const UPDATE_SPECIAL_OFFERS_ORDER = gql`
  mutation updateSpecialOffersOrder($input: [UpdateSpecialOfferOrderInput]!) {
    updateSpecialOffersOrder(input: $input) {
      id
    }
  }
`;

export const DELETE_SPECIAL_OFFER = gql`
  mutation deleteSpecialOffer($id: Int!) {
    deleteSpecialOffer(id: $id) {
      deletedAt
    }
  }
`;

export const UNDELETE_SPECIAL_OFFER = gql`
  mutation undeleteSpecialOffer($id: Int!) {
    undeleteSpecialOffer(id: $id) {
      deletedAt
    }
  }
`;

export const GET_SPECIAL_OFFER_DATA = gql`
  {
    allTenants {
      id
      name
    }
    allSectors {
      id
      name
    }
  }
`;
