import { styled } from 'styletron-react';
import { getTheme } from 'office-ui-fabric-react';

const theme = getTheme();

export const AppbarWrapper = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  height: '50px',
  backgroundColor: theme.palette.themeDark,
  color: '#ffffff',
});

export const AppbarContainer = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  paddingTop: '0px',
  paddingRight: '24px',
  paddingBottom: '0px',
  paddingLeft: '24px',
});

export const LogoContainer = styled('div', {
  boxSizing: 'border-box',
  width: '200px',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const Logo = styled('img', {
  width: '100%',
  height: 'auto',
});
