import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from 'apollo-boost';
import { GRAPHQL_API_URI } from './config';
import AuthService from './services/auth/AuthService';

// ApolloClient setup with Middleware
const httpLink = new HttpLink({ uri: GRAPHQL_API_URI });
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: AuthService.loggedIn()
        ? `Bearer ${AuthService.getToken()}`
        : '',
      consumer: 'WMTt2KYNHwbLHTpFGn3KUgnPVkSyXARaFG3NW39c96O7DJ0p4UMBuIQGVMw7',
    },
  });
  return forward(operation);
});

// eslint-disable-next-line import/prefer-default-export
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { // <Query />
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
