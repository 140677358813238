import gql from 'graphql-tag';

// note: queries are written with items property due to how AWS AppSync generates graphql schemas

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_LIVE_CHAT_ITEMS = gql`
  {
    listLiveChatGreetings {
      items {
        id
        url
        message
        delay
        active
      }
    }
  }
`;
