import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Stack, Text } from 'office-ui-fabric-react';

const propTypes = {
  option: PropTypes.shape({
    data: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }).isRequired,
};

const CustomDropdownOption = option => (
  <Stack
    horizontal
    horizontalAlign="space-between"
  >
    {option.data && option.data.icon && (
      <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
    )}
    <Text>{option.text}</Text>
  </Stack>
);

CustomDropdownOption.propTypes = propTypes;

export default CustomDropdownOption;
