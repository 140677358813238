import gql from 'graphql-tag';

export const GET_FAQ = gql`
  query faq($id: Int!, $showDeleted: Boolean) {
    faq(id: $id, showDeleted: $showDeleted) {
      id
      question
      answer
      faqCategoryId
      sectorId
      tenantId
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation updateFaq($id: Int!, $input: UpdateFaqInput!) {
    updateFaq(id: $id, input: $input) {
      id
    }
  }
`;
