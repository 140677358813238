import gql from 'graphql-tag';

export const GET_PARK_MESSAGE_TYPE = gql`
  query parkMessageType($id: Int!) {
    parkMessageType(id: $id) {
      id
      name
      messages {
        id
      }
    }
  }
`;

export const CREATE_PARK_MESSAGE_TYPE = gql`
  mutation createParkMessageType($input: CreateParkMessageTypeInput!) {
    createParkMessageType(input: $input) {
      id
    }
  }
`;

export const UPDATE_PARK_MESSAGE_TYPE = gql`
  mutation updateParkMessageType($id: Int!, $input: UpdateParkMessageTypeInput!) {
    updateParkMessageType(id: $id, input: $input) {
      id
    }
  }
`;
