import gql from 'graphql-tag';

export const GET_LIVE_CHAT_GREETING = gql`
  query getLiveChatGreetings($id: ID!) {
    getLiveChatGreetings(id: $id) {
      id
      url
      message
      delay
      active
    }
  }
`;

export const UPDATE_LIVE_CHAT_GREETING = gql`
  mutation updateLiveChatGreetings($input: UpdateLiveChatGreetingsInput!) {
    updateLiveChatGreetings(input: $input) {
      id
    }
  }
`;
