import gql from 'graphql-tag';

export const GET_PARK_NOTICE = gql`
  query parkNotice($id: Int!, $showDeleted: Boolean) {
    parkNotice(id: $id, showDeleted: $showDeleted) {
      id
      title
      description
      publishAt
      expireAt
      parks {
        id
      }
    }
  }
`;

export const GET_PARK_NOTICE_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
  }
`;

export const UPDATE_PARK_NOTICE = gql`
  mutation updateParkNotice($id: Int!, $input: UpdateParkNoticeInput!) {
    updateParkNotice(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_PARK_NOTICE = gql`
  mutation createParkNotice($input: CreateParkNoticeInput!) {
    createParkNotice(input: $input) {
      id
    }
  }
`;

export const ADD_PARK_NOTICES_TO_PARKS = gql`
  mutation addParkNoticesToParks($input: [ParkNoticeParkAssociationInput]!) {
    addParkNoticesToParks(input: $input) {
      parkNoticeId
      parkId
    }
  }
`;

export const REMOVE_PARK_NOTICES_FROM_PARKS = gql`
  mutation removeParkNoticesFromParks($input: [ParkNoticeParkAssociationInput]!) {
    removeParkNoticesFromParks(input: $input) {
      parkNoticeId
      parkId
    }
  }
`;
