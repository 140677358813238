import encodeBase64 from './encodeBase64';

// set of reducer functions for updating cursor pagination criteria
export default ({
  dispatch,
  startCursor = null,
  endCursor = null,
  numberOfRecordsPerPage,
  type,
}) => ({
  decrement: () => dispatch({
    type,
    payload: {
      cursorPaginationObject: { before: startCursor },
      pageCount: 'decrement',
    },
  }),
  increment: () => dispatch({
    type,
    payload: {
      cursorPaginationObject: { after: endCursor },
      pageCount: 'increment',
    },
  }),
  select: id => dispatch({
    type,
    payload: {
      cursorPaginationObject: {
        before: encodeBase64(Math.ceil((id * numberOfRecordsPerPage) + 1)),
      },
      pageCount: id,
    },
  }),
  updateNumberOfRecords: numberOfRecords => dispatch({
    type,
    payload: {
      cursorPaginationObject: { before: encodeBase64(numberOfRecords + 1) },
      numberOfRecordsPerPage: numberOfRecords,
      pageCount: 1,
    },
  }),
});
