import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  MockContextualMenuLink,
  MockContextualMenuLinkItemText,
} from '../mock-contextual-menu-link';

const propTypes = {
  to: PropTypes.string.isRequired,
};

const MenuLinkButton = ({ to, ...rest }) => (
  <MockContextualMenuLink>
    <Link
      to={to}
      className="ms-ContextualMenu-link"
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className="ms-ContextualMenu-linkContent">
        <MockContextualMenuLinkItemText className="ms-ContextualMenu-itemText">
          {rest.children}
        </MockContextualMenuLinkItemText>
      </div>
    </Link>
  </MockContextualMenuLink>
);

MenuLinkButton.propTypes = propTypes;

export default MenuLinkButton;
