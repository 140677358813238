/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  CommandBar,
  ContextualMenuItemType,
  DefaultButton,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import {
  copyAndSort,
  handleErrorResponse,
} from 'helpers';
import {
  CommandBarButton,
  MenuLinkButton,
  MessageList,
  OverviewList,
} from 'components';
import { PUBLIC_PATHS } from 'config';
import {
  GET_ALL_PARK_CONTACTS,
  DELETE_PARK_CONTACT,
  UNDELETE_PARK_CONTACT,
} from './graphql';
import QuickEdit from './components/quick-edit';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 40,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'number',
    },
    {
      key: 'thumbnail',
      name: 'Profile Picture',
      fieldName: 'thumbnail',
      onRender: t => (t.thumbnail === '' ? '' : <img src={t.thumbnail} alt="thumbnail" />),
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      clickable: false,
    },
    {
      key: 'firstname',
      name: 'First Name',
      fieldName: 'firstname',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'lastname',
      name: 'Last Name',
      fieldName: 'surname',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'jobTitle',
      name: 'Job Title',
      fieldName: 'jobTitle',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'email',
      name: 'Email',
      fieldName: 'email',
      minWidth: 70,
      maxWidth: 280,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'phoneNo',
      name: 'Phone Number',
      fieldName: 'phoneNo',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      clickable: false,
    },
    {
      key: 'parkIdLength',
      name: '# Of Parks Associated',
      fieldName: 'parkIdLength',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'deletedAt',
      name: 'Published',
      fieldName: 'deletedAt',
      onRender: d => (!d.deletedAt ? 'Published' : 'Unpublished'),
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  filters: {
    text: '',
    displayUnpublished: false,
  },
};

const Overview = ({ client }) => {
  const [state, setState] = useState(initialState);
  const [displayQuickEdit, setDisplayQuickEdit] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hasQuickEditMutated, setHasQuickEditMutated] = useState(false);

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => <CommandBarButton link={link} to="/contacts/overview/create">{link.text}</CommandBarButton>,
    },
    {
      key: 'toggleUnpublished',
      name: state.filters.displayUnpublished ? 'Hide Unpublished' : 'Show Unpublished',
      iconProps: {
        iconName: state.filters.displayUnpublished ? 'ClearFilter' : 'Filter',
      },
      onClick: () => {
        setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayUnpublished: !state.filters.displayUnpublished,
          },
        }));
      },
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { data: { allParkContacts: items } } = await client.query({
        query: GET_ALL_PARK_CONTACTS,
        variables: {
          showDeleted: true,
        },
      });

      const contacts = [];
      items.forEach((contact) => {
        const contentStackImage = contact.media && contact.media.path && contact.media.path.includes('contentstack') && contact.media.path;
        const concatThumbnailPath = contact.media && contact.media.path ? PUBLIC_PATHS.assets.concat('/60x60/assets/', contact.media.path) : '';
        const thumbnailPath = contentStackImage || concatThumbnailPath;

        // certain fields below are set to strings to avoid problems with reordering columns
        // when values are null
        contacts.push({
          ...contact,
          firstname: contact.firstname || '',
          surname: contact.surname || '',
          thumbnail: thumbnailPath,
          parkIdLength: contact.parks.length,
          jobTitle: contact.jobRole && contact.jobRole.jobTitle ? contact.jobRole.jobTitle : '',
        });
      });

      setState({
        ...state,
        items: copyAndSort(contacts, 'id', 'number', false),
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQuickEditMutated]);

  const handleQuickEdit = (id) => {
    setSelectedContactId(id);
    setDisplayQuickEdit(true);
  };

  const handlePublishUnpublish = async (item) => {
    setErrors([]);

    const isPublishingParkContact = item.deletedAt;

    const response = await client.mutate({
      mutation: isPublishingParkContact ? UNDELETE_PARK_CONTACT : DELETE_PARK_CONTACT,
      variables: {
        id: parseInt(item.id, 10),
      },
    });

    if (!response || response.errors) {
      setErrors(...handleErrorResponse({ response, message: `${isPublishingParkContact ? 'Publishing' : 'Unpublishing'} unsuccessful` }));
      return;
    }

    if (response && response.data) {
      // remove component from items
      const index = state.items.findIndex(component => component.id === item.id);
      const newItems = Array.from(state.items);
      // Overwrite deletedAt value from one of the two paths depending on delete/undelete
      const deletedAt = isPublishingParkContact
        ? response.data.undeleteParkContact.deletedAt
        : response.data.deleteParkContact.deletedAt;
      newItems[index].deletedAt = deletedAt;

      setState(prevState => ({
        ...prevState,
        items: newItems,
      }));
    }
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'quickEditItem',
                  name: 'Quick Edit',
                  onClick: (e) => {
                    e.preventDefault();
                    handleQuickEdit(item.id);
                  },
                },
                {
                  key: 'advancedEditItem',
                  onRender: () => <MenuLinkButton to={`/contacts/overview/${item.id}`}>Advanced Edit</MenuLinkButton>,
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'publishUnpublishItem',
                  name: item.deletedAt ? 'Publish' : 'Unpublish',
                  onClick: () => {
                    handlePublishUnpublish(item);
                  },
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const filteredItems = () => {
    let allItems = [...state.items];

    if (!state.filters.displayUnpublished) {
      allItems = allItems.filter(item => item.deletedAt === null);
    }

    if (state.filters.text !== '') {
      allItems = allItems.filter((item) => {
        const {
          // enter entries that need removing
          __typename,
          media,
          parks,
          salutation,
          thumbnail,
          jobRole,
          mobilePhoneNo,
          ...filterableData
        } = item;

        filterableData.deletedAt = (!filterableData.deletedAt ? 'published' : 'unpublished');

        // return true if a match is found in any value of filterableData
        return (
          Object
            .values(filterableData)
            .map(val => val.toString().toLowerCase())
            .filter(val => val.includes(state.filters.text.toLowerCase())).length
        );
      });
    }

    return allItems;
  };

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {displayQuickEdit && (
        <QuickEdit
          contactId={parseInt(selectedContactId, 10)}
          setHasQuickEditMutated={setHasQuickEditMutated}
          handleOnDismiss={() => {
            setDisplayQuickEdit(false);
            setSelectedContactId(null);
          }}
        />
      )}
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <React.Fragment>
          {showFilter && (
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <TextField
                label="Filter by"
                placeholder="Search"
                name="text"
                onChange={e => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    text: e.target.value,
                  },
                }))}
              />
              <Toggle
                defaultChecked={false}
                label="Display unpublished"
                onText="Yes"
                offText="No"
                name="displayUnpublished"
                onChange={(e, checked) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    displayUnpublished: checked,
                  },
                }))}
              />
            </Stack>
          )}

          {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

          <Text variant="small">{`Displaying ${filteredItems().length} of ${state.items.length}`}</Text>

          <OverviewList
            columns={state.columns}
            filteredItems={filteredItems()}
            items={state.items}
            renderItemColumn={renderItemColumn}
            setState={setState}
          />
        </React.Fragment>
      )}
    </div>
  );
};

Overview.propTypes = propTypes;

export default withApollo(Overview);
