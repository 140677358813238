import gql from 'graphql-tag';

export const GET_ALL_FAQS = gql`
  query allFaqs($showDeleted: Boolean) {
    allFaqs(showDeleted: $showDeleted) {
      id
      question
      answer
      deletedAt
      category {
        title
      }
      sector {
        name
      }
      tenant {
        name
      }
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($id: Int!) {
    deleteFaq(id: $id){
      id
      deletedAt
    }
  }
`;

export const UNDELETE_FAQ = gql`
  mutation undeleteFaq($id: Int!) {
    undeleteFaq(id: $id){
      id
    }
  }
`;
