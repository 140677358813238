import React, { useState } from 'react';
import UserContext from '../context/userContext';
import AuthService from '../services/auth/AuthService';

const UserProvider = ({ children }) => {
  const setUser = (user) => {
    // eslint-disable-next-line no-use-before-define
    updateUser(prevState => ({
      ...prevState,
      ...user,
    }));
  };

  const initialState = {
    user: AuthService.loggedIn() ? AuthService.getProfile() : { sub: { displayName: null } },
    authenticated: AuthService.loggedIn(),
    setUser,
  };

  const [user, updateUser] = useState(initialState);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
