/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
} from 'office-ui-fabric-react';
import SelectButton from './select-button';

/*
  UI component for rendering pagination controls. Receives a sequence of functions, and
  variables relating to the number of pages/current selected page (pageCount).
  Returns a row of button controls.
*/

const propTypes = {
  decrementFunction: PropTypes.func.isRequired,
  incrementFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  numOfPages: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  selectPageFunction: PropTypes.func.isRequired,
};

const PaginationButtonRow = ({
  decrementFunction,
  incrementFunction,
  loading,
  numOfPages,
  pageCount,
  selectPageFunction,
}) => {
  const returnPageSelectButton = (index) => {
    let item = false;
    const currIndex = parseInt(index + 1, 10);
    const lastIndex = numOfPages + 1;
    const props = {
      disabled: loading || currIndex === parseInt(pageCount, 10),
      id: currIndex,
      onClick: () => selectPageFunction(currIndex),
    };

    // displays first set of items
    if (pageCount <= 4 && currIndex <= 7) {
      item = React.cloneElement(<SelectButton />, { ...props, key: currIndex }, currIndex);
    }

    // displays middle items
    if (pageCount > 4 && (currIndex >= pageCount - 3 && currIndex <= pageCount + 3)) {
      item = React.cloneElement(<SelectButton />, { ...props, key: currIndex }, currIndex);
    }

    // displays end items
    if (pageCount >= lastIndex - 4 && currIndex >= lastIndex - 7) {
      item = React.cloneElement(<SelectButton />, { ...props, key: currIndex }, currIndex);
    }

    return item;
  };

  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 10 }}>
      <IconButton
        disabled={loading || pageCount <= 1}
        iconProps={{ iconName: 'ChevronLeft' }}
        onClick={() => decrementFunction()}
      />

      {
        pageCount > 5 && (
          <SelectButton
            disabled={loading}
            id={1}
            onClick={() => selectPageFunction(1)}
          >
            ...1
          </SelectButton>
        )
      }

      {Array.from(Array(numOfPages)).map((value, index) => (returnPageSelectButton(index)))}

      {
        (pageCount + 3) < numOfPages && numOfPages > 7 && (
          <SelectButton
            disabled={loading}
            id={numOfPages}
            onClick={() => selectPageFunction(numOfPages)}
          >
            {`...${numOfPages}`}
          </SelectButton>
        )
      }

      <IconButton
        disabled={loading || pageCount >= numOfPages}
        iconProps={{ iconName: 'ChevronRight' }}
        onClick={() => incrementFunction()}
      />
    </Stack>
  );
};

PaginationButtonRow.propTypes = propTypes;

export default PaginationButtonRow;
