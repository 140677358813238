import gql from 'graphql-tag';

export const GET_PARK_NOTICE = gql`
  query parkNotice($id: Int!, $showDeleted: Boolean) {
    parkNotice(id: $id, showDeleted: $showDeleted) {
      id
      title
      publishAt
      expireAt
    }
  }
`;

export const UPDATE_PARK_NOTICE = gql`
  mutation updateParkNotice($id: Int!, $input: UpdateParkNoticeInput!) {
    updateParkNotice(id: $id, input: $input) {
      id
    }
  }
`;
