import React from 'react';
import {
  Icon,
} from 'office-ui-fabric-react';

export default option => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'no-wrap',
    width: '100%',
  }}
  >
    {option.data && option.data.icon && (
      <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
    )}
    <span>{option.text}</span>
  </div>
);
