/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Label,
} from 'office-ui-fabric-react';
import { MessageList } from 'components';
// import _ from 'lodash';
import {
  ADD_CONTACT_TO_PARK,
  // CREATE_PARK_CONTACT,
  CREATE_CS_PARK_CONTACT,
  GET_PARK_CONTACT_DATA,
  GET_PARK_CONTACT,
  REMOVE_CONTACT_FROM_PARK,
  // UPDATE_PARK_CONTACT,
  UPDATE_CS_PARK_CONTACT,
} from './graphql';

const propTypes = {
  client: PropTypes.shape({}).isRequired,
};

const UpdateContact = ({ client, ...otherProps }) => {
  const {
    match: {
      params: {
        id: contactId,
      },
    },
  } = otherProps;

  const [state, setState] = useState({
    contact: {
      salutation: '',
      firstname: '',
      surname: '',
      email: '',
      phoneNo: '',
      mobilePhoneNo: '',
      jobRoleId: null,
      mediaId: null,
      parks: [],
    },
    initialSelectedParks: [],
  });
  const [mediaFlag, setMediaFlag] = useState(true);

  const [isCreatingNewContact, setIsCreatingNewContact] = (
    useState(Number.isNaN(parseInt(contactId, 10)))
  );
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  useEffect(() => {
    (async () => {
      let contact = {};

      if (!isCreatingNewContact) {
        const getContact = await client.query({
          query: GET_PARK_CONTACT,
          variables: {
            id: contactId === 'create' ? state.contact.id : contactId,
            showDeleted: true,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        contact = getContact.data.parkContact;
      }

      const { data: { allParks, allJobRoles } } = await client.query({
        query: GET_PARK_CONTACT_DATA,
      });

      const sortedJobRoles = allJobRoles.sort((a, b) => a.jobTitle.localeCompare(b.jobTitle));
      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      const contactForState = !isCreatingNewContact
        ? contact
        : state.contact;

      setState({
        ...state,
        contact: contactForState,
        parks: sortedParks,
        jobRoles: sortedJobRoles,
        initialSelectedParks: contactForState.parks,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewContact]);

  const handleInputChange = ({ target: { name, value } }) => {
    const contact = Object.assign({}, state.contact);
    contact[name] = value;
    setState(prevState => ({ ...prevState, contact }));
  };

  const handleParkAssociationChange = ({ target: { value } }) => {
    const contact = Object.assign({}, state.contact);
    let newParksArray = [...contact.parks];

    const selectedParkId = {
      id: value.key,
    };

    if (value.selected) {
      newParksArray.push(selectedParkId);
    } else {
      newParksArray = (
        newParksArray.filter(({ id }) => parseInt(id, 10) !== parseInt(selectedParkId.id, 10))
      );
    }

    setState(prevState => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        parks: newParksArray,
      },
    }));
  };

  const getAssociatedParks = () => {
    if (!state.contact.parks.length) {
      return [];
    }
    const contactParks = state.contact.parks.map(park => (
      park.id
    ));
    return contactParks;
  };

  const validateData = () => {
    const errorsFound = [];
    const { contact } = state;

    if (!contact.jobRoleId) {
      errorsFound.push({
        title: 'Invalid Job Role',
        message: 'Please ensure you have selected a job role',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleContactMutation = async () => {
    const {
      contact: {
        __typename,
        id,
        parks,
        media,
        ...input
      },
    } = state;
    // const omitJobRelatedID = _.omit(input, 'jobRoleId');
    const response = await client.mutate({
      mutation: isCreatingNewContact ? CREATE_CS_PARK_CONTACT : UPDATE_CS_PARK_CONTACT,
      variables: {
        ...(isCreatingNewContact ? {} : { id: parseInt(state.contact.id, 10) }),
        // ...(isCreatingNewContact ? {} : { jobRoleId: input.jobRoleId }),
        // input: isCreatingNewContact ? input : omitJobRelatedID,
        input,
      },
    });

    if (!response) {
      throw new Error(`No response. Failed at ${isCreatingNewContact ? 'creating' : 'updating'} contact.`);
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }

    if (response && response.data) {
      console.log('Response', response.data);
      const responseMapped = isCreatingNewContact
        ? response.data.createCSParkContact
        : response.data.updateCSParkContact;

      const responseId = parseInt(responseMapped.id, 10);

      return responseId;
    }

    return false;
  };

  const handleParkAssociations = async (id, mutationType, parksArray) => {
    const obj = {
      add: {
        mutation: ADD_CONTACT_TO_PARK,
        response: 'addContactToPark',
      },
      delete: {
        mutation: REMOVE_CONTACT_FROM_PARK,
        response: 'removeContactFromPark',
      },
    };

    const input = parksArray.map(({ id: parkId }) => ({
      contactId: id,
      parkId,
    }));

    const response = await client.mutate({
      mutation: obj[mutationType].mutation,
      variables: { input },
    });

    if (!response) {
      throw new Error(`No response. Failed at mutation ${obj[mutationType].response}`);
    }

    if (response.errors) {
      response.errors.forEach(({ message }) => {
        throw new Error(message);
      });
    }
  };

  const handleSave = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setErrors([]);
    setSuccessMessages([]);

    const { contact: { parks }, initialSelectedParks } = state;

    const initialParkIds = initialSelectedParks.map(p => p.id);
    const selectedParkIds = parks.map(p => p.id);

    const parkAdditions = parks.filter(({ id }) => !initialParkIds.includes(id));
    const parkDeletions = initialSelectedParks.filter(({ id }) => !selectedParkIds.includes(id));

    if (validateData()) {
      try {
        const responseId = await handleContactMutation();

        if (parkAdditions.length > 0) {
          await handleParkAssociations(responseId, 'add', parkAdditions);
        }

        if (parkDeletions.length > 0) {
          await handleParkAssociations(responseId, 'delete', parkDeletions);
        }

        setState(prevState => ({
          ...prevState,
          contact: {
            ...prevState.contact,
            id: responseId,
          },
          initialSelectedParks: state.contact.parks,
        }));

        setSuccessMessages([{
          message: `Contact has been ${isCreatingNewContact ? 'created' : 'updated'}`,
        }]);
        setIsCreatingNewContact(false);
      } catch (e) {
        setErrors([{ message: e }]);
      }
    }
  };

  useEffect(() => {
    if (state.contact && state.contact.media && state.contact.media.path && mediaFlag) {
      const contactDefinition = Object.assign({}, state);
      const { path } = state.contact.media;
      contactDefinition.contact.mediaId = path;
      setState({ ...contactDefinition });
      setMediaFlag(false);
    }
  }, [state, setState, mediaFlag]);

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>
            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="Contact ID"
              name="id"
              value={state.contact.id}
              prefix="#"
              type="number"
              disabled
              readOnly
            />

            <Stack>
              <Label>Profile Picture</Label>
              <Stack horizontal tokens={{ childrenGap: 24 }}>
                <TextField
                  name="mediaId"
                  placeholder="Enter media url"
                  value={state.contact.mediaId}
                  onChange={e => handleInputChange(e)}
                />
              </Stack>
            </Stack>


            <TextField
              label="Salutation"
              name="salutation"
              placeholder="e.g. Mr/Mrs"
              value={state.contact.salutation || ''}
              onChange={e => handleInputChange(e)}
            />

            <TextField
              label="First Name"
              name="firstname"
              placeholder="Enter a first name.."
              value={state.contact.firstname || ''}
              onChange={e => handleInputChange(e)}
              required
            />

            <TextField
              label="Last Name"
              name="surname"
              placeholder="Enter a surname.."
              value={state.contact.surname || ''}
              onChange={e => handleInputChange(e)}
              required
            />

            <TextField
              label="Email"
              name="email"
              placeholder="Enter an email.."
              value={state.contact.email || ''}
              onChange={e => handleInputChange(e)}
              required
            />

            <TextField
              label="Phone Number"
              name="phoneNo"
              placeholder="Enter a phone number.."
              value={state.contact.phoneNo || ''}
              onChange={e => handleInputChange(e)}
            />

            <TextField
              label="Mobile Number"
              name="mobilePhoneNo"
              placeholder="Enter a mobile number.."
              value={state.contact.mobilePhoneNo || ''}
              onChange={e => handleInputChange(e)}
            />

            <Dropdown
              label="Associated Parks"
              placeholder="Select"
              defaultSelectedKeys={getAssociatedParks()}
              options={state.parks.map(({ id: key, name: text }) => ({
                key,
                text,
              }))}
              name="parks"
              onChange={(e, item) => {
                handleParkAssociationChange({
                  target: { value: item },
                });
              }}
              multiSelect
            />

            <Dropdown
              label="Job Role"
              placeholder="Select"
              selectedKey={
                state.contact.jobRoleId
                  ? state.contact.jobRoleId.toString()
                  : undefined
              }
              options={state.jobRoles.map(({ id: key, jobTitle: text }) => ({
                key,
                text,
              }))}
              name="jobRoleId"
              onChange={(e, item) => handleInputChange({
                target: { name: 'jobRoleId', value: parseInt(item.key, 10) },
              })}
              required
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateContact.propTypes = propTypes;

export default withApollo(UpdateContact);
