import gql from 'graphql-tag';

const LDAP_LOGIN = gql`
  mutation ldapLogin($input: LoginInput! ) {
    ldapLogin(input: $input) {
      token
    }
  }
`;

export default LDAP_LOGIN;
