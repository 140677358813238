import gql from 'graphql-tag';

export const CREATE_LIVE_CHAT_GREETING = gql`
  mutation createLiveChatGreetings($input: CreateLiveChatGreetingsInput!) {
    createLiveChatGreetings(input: $input) {
      id
    }
  }
`;

export const UPDATE_LIVE_CHAT_GREETING = gql`
  mutation updateLiveChatGreetings($input: UpdateLiveChatGreetingsInput!) {
    updateLiveChatGreetings(input: $input) {
      id
    }
  }
`;
