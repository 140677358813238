import { styled } from 'styletron-react';

export const LoginWrapper = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: '1',
  height: '100%',
  backgroundImage: 'linear-gradient(-45deg, #0c5595, #962067, #2e9cc0, #87b026)',
  backgroundSize: '400% 400%',
  position: 'relative',
  animationDuration: '10s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  animationName: {
    '0%': {
      backgroundPosition: '0 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0 50%',
    },
  },
});

export const LoginContainer = styled('div', {
  boxSizing: 'border-box',
  flex: '0 1 300px',
  borderRadius: '5px',
  backgroundColor: 'rgba(255,255,255,0.8)',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
});
