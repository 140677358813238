import { DateTime } from 'luxon';

export default (items, columnKey, dataType, isSortedDescending) => {
  let sortedItems = items.slice(0);
  switch (dataType) {
    case 'number':
      // sorts numerical only
      sortedItems = sortedItems.sort(
        (a, b) => (
          isSortedDescending
            ? b[columnKey] - a[columnKey]
            : a[columnKey] - b[columnKey]),
      );
      break;
    case 'timestamp':
      // sorts timestamps (including null handler)
      sortedItems = sortedItems.sort(
        (a, b) => {
          if (a[columnKey] === b[columnKey]) {
            return 0;
          }
          if (a[columnKey] === null) {
            return isSortedDescending ? 1 : -1;
          }
          if (b[columnKey] === null) {
            return isSortedDescending ? -1 : 1;
          }
          const aDate = DateTime.fromFormat(a[columnKey], 'yyyy-MM-dd HH:mm:ss');
          const bDate = DateTime.fromFormat(b[columnKey], 'yyyy-MM-dd HH:mm:ss');
          return (isSortedDescending ? aDate < bDate : aDate > bDate) ? 1 : -1;
        },
      );
      break;
    case 'string':
    case 'boolean':
    default:
      // sorts by string only (default)
      sortedItems = sortedItems.sort(
        (a, b) => (
          (isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey])
            ? 1
            : -1
        ),
      );
      break;
  }

  return sortedItems;
};
