import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react';

/*
  NOTE:
    to use this as a component, initiate a reference (using createRef/useRef etc.) in the parent
    component and pass it as a prop (elemRef) to the SCEditor component. This is to help prevent
    errors caused by trying to invoke sceditor methods before the sceditor has been bound to the
    window.
*/

const defaultConfig = { format: 'bbcode' };

const propTypes = {
  config: PropTypes.shape({}),
  elemRef: PropTypes.shape({
    current: PropTypes.any.isRequired,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  objRef: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const defaultProps = {
  config: defaultConfig,
  required: true,
  value: '',
};

const SCEditor = ({
  config,
  elemRef,
  handleInputChange,
  label,
  objRef,
  required,
  value,
}) => {
  useEffect(() => {
    let { current } = elemRef;

    if (current) {
      // eslint-disable-next-line no-undef
      const { sceditor } = window;

      // create editor
      sceditor.create(current, config);

      // set initial constraints for editor
      sceditor.instance(current).val(value);
      sceditor.instance(current).expandToContent(true);

      // event listener to handle input changes
      sceditor.instance(current).bind('blur', () => {
        const editorValue = sceditor.instance(current).val();
        handleInputChange({ target: { name: objRef, value: editorValue } });
        sceditor.instance(current).expandToContent(true);
      });
    } else {
      current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Label required={required}>{label}</Label>
      <textarea
        // these height/width styles prevent element from breaking on resizing
        style={{ minHeight: 250, minWidth: '100%' }}
        ref={elemRef}
      />
    </div>
  );
};

SCEditor.propTypes = propTypes;
SCEditor.defaultProps = defaultProps;

export default SCEditor;
