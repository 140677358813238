/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { CustomDropdownOption, MessageList, SCEditor } from 'components';
import { handleErrorResponse } from 'helpers';
import {
  GET_FAQ,
  GET_FAQ_DATA,
  CREATE_FAQ,
  UPDATE_FAQ,
  CREATE_FAQ_CATEGORY,
} from './graphql';

// Trigger build

const propTypes = {
  client: PropTypes.objectOf(PropTypes.object).isRequired,
};

const UpdateFaq = ({ client, ...otherProps }) => {
  const {
    match: {
      params: { id: faqId },
    },
  } = otherProps;

  const [isCreatingNewFaq, setIsCreatingNewFaq] = useState(Number.isNaN(parseInt(faqId, 10)));

  const [state, setState] = useState({
    faq: {
      id: null,
      faqCategoryId: null,
      sectorId: null,
      tenantId: null,
      question: '',
      answer: '',
    },
    initialFaq: {},
    allSectors: [],
    allTenants: [],
    allCategories: [],
    newCategory: {
      title: '',
    },
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [isDialogHidden, setIsDialogHidden] = useState(true);

  // refs for SCEditor
  const answer = useRef();

  useEffect(() => {
    (async () => {
      let faq = {};

      if (!isCreatingNewFaq) {
        const getFaq = await client.query({
          query: GET_FAQ,
          variables: {
            id: faqId === 'create' ? state.faq.id : parseInt(faqId, 10),
            showDeleted: true,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        faq = getFaq.data.faq;
      }

      const { data: { allFaqCategories, allSectors, allTenants } } = await client.query({
        query: GET_FAQ_DATA,
      });

      const sortedCategories = [
        { id: 0, title: 'Add New', data: { icon: 'Add' } },
        ...allFaqCategories.sort((a, b) => a.title.localeCompare(b.title)),
      ];

      const sortedSectors = allSectors.sort((a, b) => a.name.localeCompare(b.name));
      const sortedTenants = allTenants.sort((a, b) => a.name.localeCompare(b.name));

      const faqForState = !isCreatingNewFaq
        ? faq
        : state.faq;

      setState({
        ...state,
        faq: faqForState,
        allCategories: sortedCategories,
        allSectors: sortedSectors,
        allTenants: sortedTenants,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewFaq]);

  const handleInputChange = ({ target: { name, value, object = '' } }) => {
    const newState = Object.assign({}, state);
    state[object === '' ? 'faq' : object][name] = value;
    setState(newState);
  };

  const handleCategoryCreation = async () => {
    setErrors([]);
    setSuccessMessages([]);

    if (state.newCategory.title.length) {
      setIsLoading(true);
      setIsDialogHidden(true);

      const { newCategory: input } = state;

      const response = await client.mutate({
        mutation: CREATE_FAQ_CATEGORY,
        variables: {
          input,
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Category save unsuccessful' }));
        return;
      }

      if (response.data && response.data.createFaqCategory) {
        // push new result into state.allCategories
        // set the selected category as the new category
        const { data: { createFaqCategory: { id, title } } } = response;
        const newArrayOfCategories = [...state.allCategories, { id, title }];

        const sortedArrayOfCategories = newArrayOfCategories
          .slice(0, 1)
          .concat(
            newArrayOfCategories
              .slice(1, newArrayOfCategories.length)
              .sort((a, b) => a.title.localeCompare(b.title)),
          );

        setState({
          ...state,
          allCategories: sortedArrayOfCategories,
          faq: {
            ...state.faq,
            faqCategoryId: id,
          },
          newCategory: {
            title: '',
          },
        });
      }
    }

    setIsLoading(false);
  };

  const validateData = () => {
    const errorsFound = [];

    const { faq: { sectorId, tenantId, faqCategoryId } } = state;

    if (!sectorId) {
      errorsFound.push({
        title: 'Invalid Sector',
        message: 'Please ensure you have selected a sector',
      });
    }

    if (!tenantId) {
      errorsFound.push({
        title: 'Invalid Tenant',
        message: 'Please ensure you have selected a tenant',
      });
    }

    if (!faqCategoryId) {
      errorsFound.push({
        title: 'Invalid Category',
        message: 'Please ensure you have selected a category',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleSave = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setErrors([]);
    setSuccessMessages([]);

    if (validateData()) {
      const {
        faq: {
          __typename, id, faqCategoryId, ...input
        },
      } = state;

      const response = await client.mutate({
        mutation: isCreatingNewFaq ? CREATE_FAQ : UPDATE_FAQ,
        variables: {
          ...(!isCreatingNewFaq && { id: parseInt(state.faq.id, 10) }),
          input: {
            ...input,
            categoryId: parseInt(faqCategoryId, 10),
          },
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Category save unsuccessful' }));
        return;
      }

      if (response && response.data) {
        const responseMapped = isCreatingNewFaq
          ? response.data.createFaq
          : response.data.updateFaq;

        setState({
          ...state,
          faq: {
            ...state.faq,
            id: parseInt(responseMapped.id, 10),
          },
        });

        setSuccessMessages([{ message: `FAQ ${isCreatingNewFaq ? 'created' : 'updated'}` }]);
        setIsCreatingNewFaq(false);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="FAQ ID"
              name="id"
              value={state.faq.id}
              prefix="#"
              type="number"
              readOnly
            />

            <Dropdown
              label="Tenant (Website)"
              placeholder="Select a Tenant (Website)"
              selectedKey={
                state.faq.tenantId
                  ? state.faq.tenantId
                  : undefined
              }
              options={state.allTenants.map(({ id: key, name: text }) => ({
                key,
                text,
              }))}
              name="tenantId"
              onChange={(e, item) => handleInputChange({
                target: { name: 'tenantId', value: item.key },
              })}
              required
            />

            <Dropdown
              label="Sector"
              placeholder="Select a Sector"
              selectedKey={
                state.faq.sectorId
                  ? state.faq.sectorId
                  : undefined
              }
              options={state.allSectors.map(({ id: key, name: text }) => ({
                key,
                text,
              }))}
              name="sectorId"
              onChange={(e, item) => handleInputChange({
                target: { name: 'sectorId', value: item.key },
              })}
              required
            />

            <Dropdown
              label="Category"
              placeholder="Select a Category"
              name="faqCategoryId"
              selectedKey={
                state.faq.faqCategoryId
                  ? state.faq.faqCategoryId.toString()
                  : undefined
              }
              options={state.allCategories.map(({ id: key, title: text, data }) => ({
                key,
                text,
                data,
              }))}
              onRenderOption={option => CustomDropdownOption(option)}
              onChange={(e, { key }) => (
                key === 0
                  ? setIsDialogHidden(false)
                  : handleInputChange({
                    target: { name: 'faqCategoryId', value: key },
                  }))}
              required
            />

            {/* Modal box to create new category */}
            <Dialog
              hidden={isDialogHidden}
              onDismiss={() => setIsDialogHidden(true)}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                  label="Category Title"
                  name="title"
                  value={state.newCategory.title}
                  onChange={e => handleInputChange({ target: { name: e.target.name, value: e.target.value, object: 'newCategory' } })}
                  required
                />
                <DialogFooter>
                  <PrimaryButton onClick={() => handleCategoryCreation()} text="Save" />
                  <DefaultButton onClick={() => setIsDialogHidden(true)} text="Cancel" />
                </DialogFooter>
              </Stack>
            </Dialog>

            <TextField
              label={`Question: (${
                state.faq.question.length
              }/255 characters)`}
              name="question"
              value={state.faq.question}
              onChange={e => handleInputChange(e)}
              ariaLabel="Question"
              maxLength={255}
              multiline
              required
            />

            <SCEditor
              elemRef={answer}
              handleInputChange={handleInputChange}
              label="Answer"
              objRef="answer"
              required
              value={state.faq.answer}
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateFaq.propTypes = propTypes;

export default withApollo(UpdateFaq);
