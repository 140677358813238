import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import {
  ActionButton,
  CommandBar,
  ContextualMenuItemType,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import { copyAndSort, formatDatetimeString } from 'helpers';
import {
  GET_ALL_PARK_NOTICES,
  DELETE_PARK_NOTICE,
  UNDELETE_PARK_NOTICE,
} from './graphql';
import {
  MockContextualMenuLink,
  MockContextualMenuLinkItemText,
} from '../../../../components/mock-contextual-menu-link';
import QuickEdit from './components/quick-edit';

const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 40,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'number',
    },
    {
      key: 'title',
      name: 'Title',
      fieldName: 'title',
      minWidth: 70,
      maxWidth: 450,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'parks',
      name: '# of Parks Associated',
      fieldName: 'parks',
      onRender: d => d.parks.length,
      minWidth: 120,
      maxWidth: 250,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'publishAt',
      name: 'Publish Date',
      fieldName: 'publishAt',
      onRender: d => formatDatetimeString(d.publishAt),
      minWidth: 120,
      maxWidth: 250,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'expireAt',
      name: 'Expiry Date',
      fieldName: 'expireAt',
      onRender: d => formatDatetimeString(d.expireAt),
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'deletedAt',
      name: 'Published',
      fieldName: 'deletedAt',
      onRender: d => (!d.deletedAt ? 'Published' : 'Unpublished'),
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  filters: {
    text: '',
    displayUnpublished: false,
  },
};

const ParkNotices = ({ client }) => {
  const [state, setState] = useState(initialState);
  const [displayQuickEdit, setDisplayQuickEdit] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [selectedNoticeId, setSelectedNoticeId] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState([]);
  const [quickEditDismissed, setIsQuickEditDismissed] = useState(false);

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => (
        <Link to="/parks/notices/create" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ActionButton
            className={link.className}
            role={link.role}
            iconProps={link.iconProps}
            allowDisabledFocus={link.allowDisabledFocus}
          >
            {link.text}
          </ActionButton>
        </Link>
      ),
    },
    {
      key: 'toggleUnpublished',
      name: state.filters.displayUnpublished ? 'Hide Unpublished' : 'Show Unpublished',
      iconProps: {
        iconName: state.filters.displayUnpublished ? 'ClearFilter' : 'Filter',
      },
      onClick: () => {
        setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayUnpublished: !state.filters.displayUnpublished,
          },
        }));
      },
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await client.query({
        query: GET_ALL_PARK_NOTICES,
        variables: {
          showDeleted: true,
        },
      });

      const { data: { allParkNotices } } = response;

      const items = [];

        // allParkNotices.forEach((e) => {
        //   items.push({
        //     ...e,
        //     parkName: e.park.name,
        //   });
        // });

      setState({
        ...state,
        items: copyAndSort(allParkNotices, 'id', 'number', true),
      });

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickEditDismissed]);

  const handleQuickEdit = (id) => {
    setSelectedNoticeId(id);
    setDisplayQuickEdit(true);
  };

  const handlePublishUnpublish = async (item) => {
    setErrors([]);

    const isPublishingParkNotice = item.deletedAt;

    const response = await client.mutate({
      mutation: isPublishingParkNotice ? UNDELETE_PARK_NOTICE : DELETE_PARK_NOTICE,
      variables: {
        id: item.id,
      },
    });

    if (!response) {
      setErrors([{ index: 0, message: `${isPublishingParkNotice ? 'Publishing' : 'Unpublishing'} unsuccessful` }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors.map(({ message }, index) => ({
        index,
        message,
      })));
      return;
    }

    if (response && response.data) {
      // remove notice from items
      const index = state.items.findIndex(notice => notice.id === item.id);
      const newItems = Array.from(state.items);
      // Overwrite deletedAt value from one of the two paths depending on delete/undelete
      const deletedAt = isPublishingParkNotice
        ? response.data.undeleteParkNotice.deletedAt
        : response.data.deleteParkNotice.deletedAt;
      newItems[index].deletedAt = deletedAt;

      setState(prevState => ({
        ...prevState,
        items: newItems,
      }));
    }
  };

  const onColumnClick = (e, column) => {
    const { columns, items } = state;
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        newCol.isSorted = false;
        // eslint-disable-next-line no-param-reassign
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items, currColumn.fieldName, currColumn.data, currColumn.isSortedDescending,
    );
    setState(prevState => ({
      ...prevState,
      columns: newColumns,
      items: newItems,
    }));
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'quickEditItem',
                  name: 'Quick Edit',
                  onClick: (e) => {
                    e.preventDefault();
                    handleQuickEdit(item.id);
                  },
                },
                {
                  key: 'advancedEditItem',
                  onRender: () => (
                    <MockContextualMenuLink>
                      <Link to={`/parks/notices/${item.id}`} className="ms-ContextualMenu-link" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="ms-ContextualMenu-linkContent">
                          <MockContextualMenuLinkItemText className="ms-ContextualMenu-itemText">Advanced Edit</MockContextualMenuLinkItemText>
                        </div>
                      </Link>
                    </MockContextualMenuLink>
                  ),
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'publishUnpublishItem',
                  name: item.deletedAt ? 'Publish' : 'Unpublish',
                  onClick: () => {
                    handlePublishUnpublish(item);
                  },
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const filteredItems = () => {
    let allItems = [...state.items];

    if (!state.filters.displayUnpublished) {
      allItems = allItems.filter(item => item.deletedAt === null);
    }

    if (state.filters.text !== '') {
      allItems = allItems.filter((item) => {
        const {
          __typename,
          park,
          ...filterableData
        } = item;

        console.log(filterableData)

        filterableData.deletedAt = (!filterableData.deletedAt ? 'published' : 'unpublished');

        // return true if a match is found in any value of filterableData
        return Object.values(filterableData).map(val => val.toString().toLowerCase())
          .filter(val => val.includes(state.filters.text.toLowerCase())).length;
      });
    }

    return allItems;
  };

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {displayQuickEdit && (
        <QuickEdit
          noticeId={parseInt(selectedNoticeId, 10)}
          handleOnDismiss={() => {
            setDisplayQuickEdit(false);
            setSelectedNoticeId(null);
            setIsQuickEditDismissed(!quickEditDismissed);
          }}
        />
      )}
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <React.Fragment>
          {showFilter && (
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <TextField
                label="Filter by"
                placeholder="Search"
                name="text"
                onChange={e => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    text: e.target.value,
                  },
                }))}
              />
              <Toggle
                defaultChecked={false}
                label="Display unpublished"
                onText="Yes"
                offText="No"
                name="displayUnpublished"
                onChange={(e, checked) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    displayUnpublished: checked,
                  },
                }))}
              />
            </Stack>
          )}

          {/* display errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
              isMultiline
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>
                {errors.map(({ message, index }) => (
                  <Text variant="medium" key={index}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}
          <Text variant="small">{`Displaying ${filteredItems().length} of ${state.items.length}`}</Text>
          <DetailsList
            items={filteredItems()}
            columns={state.columns.map(c => ({ ...c, onColumnClick }))}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          />
        </React.Fragment>
      )}
    </div>
  );
};

ParkNotices.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(ParkNotices);
