import gql from 'graphql-tag';

export const GET_PARK_MESSAGES = gql`
  query allParkMessages($showDeleted: Boolean) {
    allParkMessages(showDeleted: $showDeleted) {
      id
      message
      parkMessageTypeId
      park {
        name
      }
      contact {
        firstname
        surname
        salutation
        jobRole {
          jobTitle
        }
      }
      publishAt
      expiresAt
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_PARK_MESSAGE = gql`
  mutation deleteParkMessage($id: Int!) {
    deleteParkMessage(id: $id) {
      id
    }
  }
`;
