import Contacts from './views/contacts/overview';
import Events from './views/events/overview';
import FAQs from './views/general/faqs/overview';
import ImageEditor from './components/image-editor';
// import Inventory from './views/stock/overview';
import LiveChat from './views/live-chat/overview';
// import Manufacturers from './views/stock/manufacturer/view';
import Media from './views/digital-assets/media';
import MessageTypes from './views/parks/messages/overview';
import Overview from './views/general/overview';
import OwnersAreaResources from './views/digital-assets/files/overview';
import PageNotFound from './views/system/404';
import ParkNotices from './views/parks/notices/overview';
import SpecialOffers from './views/general/special-offers/overview';
import StockOnlineReport from './views/reports/stock/online-report';
import UpdateEvent from './views/events/edit';
import UpdateFAQ from './views/general/faqs/edit';
// import UpdateInventory from './views/stock/edit';
// import UpdateManufacturer from './views/stock/manufacturer/edit';
// import UpdateModel from './views/stock/manufacturer/edit/components/models/edit';
// import UpdateModelYear from 
// './views/stock/manufacturer/edit/components/models/edit/components/model-years/edit';
import UpdateOwnersAreaResource from './views/digital-assets/files/edit';
import UpdateParkMessage from './views/parks/messages/edit/messages/edit';
import UpdateLiveChatGreeting from './views/live-chat/edit';
import UpdateParkMessageType from './views/parks/messages/edit';
import UpdateParkNotice from './views/parks/notices/edit';
import UpdateSpecialOfferController from './views/general/special-offers/edit';
import UpdateContact from './views/contacts/edit';

export const systemRoutes = {
  404: PageNotFound,
};

export const authenticatedRoutes = [
  // Overview
  {
    path: '/overview',
    component: Overview,
  },
  // FAQs
  {
    path: '/faqs',
    component: FAQs,
  },
  {
    path: '/faqs/:id',
    component: UpdateFAQ,
  },
  // Special Offers
  {
    path: '/special-offers',
    component: SpecialOffers,
  },
  {
    path: '/special-offers/:id',
    component: UpdateSpecialOfferController,
  },
  // Stock
  // {
  //   path: '/stock',
  //   component: Inventory,
  // },
  // {
  //   path: '/stock/:id',
  //   component: UpdateInventory,
  // },
  // {
  //   path: '/stock/:stockId/edit/:id',
  //   component: ImageEditor,
  // },
  // // Manufacturers
  // {
  //   path: '/manufacturers',
  //   component: Manufacturers,
  // },
  // {
  //   path: '/manufacturers/:id',
  //   component: UpdateManufacturer,
  // },
  // {
  //   path: '/manufacturers/:manufacturerId/edit/:id',
  //   component: ImageEditor,
  // },
  // // Manufacturers -> Models
  // {
  //   path: '/manufacturers/:manufacturerId/models/:modelId',
  //   component: UpdateModel,
  // },
  // // Manufacturers -> Models -> Model Years
  // {
  //   path: '/manufacturers/:manufacturerId/models/:modelId/years/:modelYearId',
  //   component: UpdateModelYear,
  // },
  // {
  //   path: '/manufacturers/:manufacturerId/models/:modelId/years/:modelYearId/edit/:id',
  //   component: ImageEditor,
  // },
  
  // Assets
  {
    path: '/assets/media',
    component: Media,
  },
  {
    path: '/assets/media/edit/:id',
    component: ImageEditor,
  },
  {
    path: '/assets/files/owners-area',
    component: OwnersAreaResources,
  },
  {
    path: '/assets/files/owners-area/:id',
    component: UpdateOwnersAreaResource,
  },
  // Reports
  {
    path: '/reports/stock/online-report',
    component: StockOnlineReport,
  },
  // Park Messages
  {
    path: '/parks/message-types',
    component: MessageTypes,
  },
  {
    path: '/parks/message-types/:id',
    component: UpdateParkMessageType,
  },
  {
    path: '/parks/message-types/:messageTypeId/messages/:id',
    component: UpdateParkMessage,
  },
  // Park Notices
  {
    path: '/parks/notices/',
    component: ParkNotices,
  },
  {
    path: '/parks/notices/:id',
    component: UpdateParkNotice,
  },
  // Events
  {
    path: '/events/overview',
    component: Events,
  },
  {
    path: '/events/overview/:id',
    component: UpdateEvent,
  },
  // Contacts
  {
    path: '/contacts/overview',
    component: Contacts,
  },
  {
    path: '/contacts/overview/:id',
    component: UpdateContact,
  },
  // Live Chat
  {
    path: '/live-chat/overview',
    component: LiveChat,
  },
  {
    path: '/live-chat/create',
    component: UpdateLiveChatGreeting,
  },
];
