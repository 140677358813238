import gql from 'graphql-tag';

export const GET_PARK_MESSAGE = gql`
  query parkMessage($id: Int!, $showDeleted: Boolean) {
    parkMessage(id: $id, showDeleted: $showDeleted) {
      id
      message
      parkId
      parkMessageTypeId
      contactId
      publishAt
      expiresAt
    }
  }
`;

export const GET_PARK_MESSAGE_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
    allParkMessageTypes {
      id
      name
    }
    allParkContacts {
      id
      salutation
      firstname
      surname
      jobRoleId
      jobRole {
        jobTitle
      }
      parks {
        id
      }
    }
  }
`;

export const CREATE_PARK_MESSAGE = gql`
  mutation createParkMessage($input: CreateParkMessageInput!) {
    createParkMessage(input: $input) {
      id
    }
  }
`;

export const UPDATE_PARK_MESSAGE = gql`
  mutation updateParkMessage($id: Int!, $input: UpdateParkMessageInput!) {
    updateParkMessage(id: $id, input: $input) {
      id
    }
  }
`;
