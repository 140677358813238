import gql from 'graphql-tag';

export const GET_EVENT = gql`
  query event($id: Int!, $showDeleted: Boolean) {
    event(id: $id, showDeleted: $showDeleted) {
      id
      title
      slug
      location
      startDate
      endDate
      pinned
      cancelled
    }
  }
`;

export const GET_EVENT_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($id: Int!, $input: UpdateEventInput!) {
    updateEvent(id: $id, input: $input) {
      id
    }
  }
`;
