import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Image,
  Label,
  MessageBar,
  MessageBarType,
  Modal,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import { GET_ALL_PAGE_TEMPLATES } from './graphql';

const defaultProps = {
  pageTemplateId: undefined,
};

const propTypes = {
  client: PropTypes.shape({}).isRequired,
  pageTemplateId: PropTypes.number,
  tenantId: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

const TemplateModal = ({
  client,
  pageTemplateId,
  tenantId: parentTenantId,
  handleInputChange,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const getPageData = await client.query({
        query: GET_ALL_PAGE_TEMPLATES,
      });

      const { data: { allPageTemplates } } = getPageData;

      // NOTE: this is temporary, to be changed/deleted once template images are available
      const sampleTemplate = (
        allPageTemplates
          .filter(({ tenantId }) => parseInt(tenantId, 10) === parentTenantId)
          .map(({ media: { path }, ...rest }) => ({
            ...rest,
            path: `https://assets.parkholidays.com/static_assets/page_templates/${path}`,
          }))[0]);


      const temporaryPageTemplates = Array.from(Array(15), () => sampleTemplate);

      setState({ allPageTemplates: temporaryPageTemplates });
      setIsLoading(false);
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <TextField label="Template" />;
  }

  return (
    <>
      <Stack>
        <Label>Template</Label>
        <Stack horizontal tokens={{ childrenGap: 24 }}>
          <TextField
            prefix="Name"
            value={pageTemplateId
              ? state.allPageTemplates.find(({ id }) => (
                parseInt(id, 10) === parseInt(pageTemplateId, 10)
              )).name
              : undefined
            }
            readOnly
            styles={{ root: { width: 360 } }}
          />
          <DefaultButton
            onClick={() => setIsOpen(true)}
          >
            Select
          </DefaultButton>
        </Stack>
      </Stack>

      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        styles={{ main: { height: '100%', minWidth: 800, width: '80%' } }}
      >
        <Stack tokens={{ childrenGap: 24 }}>
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            Please note that the template cannot
            be easily changed after being associated to a page.
            Future attempts to change the template may result in errors
            or data not being displayed.
          </MessageBar>

          <Stack
            horizontal
            horizontalAlign="space-evenly"
            wrap
            tokens={{ childrenGap: 24 }}
          >
            {state.allPageTemplates.map(({ id, name, path }) => (
              <Stack
                id={id}
                tokens={{ childrenGap: 8 }}
                styles={{
                  root: {
                    border: `2px solid ${parseInt(id, 10) === parseInt(pageTemplateId, 10) ? '#005a9e' : '#d9d9d9'}`,
                  },
                }}
              >
                <Image src={path} height={250} width={250} />
                <Text styles={{ root: { paddingLeft: 8 } }}>{name}</Text>
                <DefaultButton
                  onClick={() => [
                    handleInputChange({ target: { name: 'pageTemplateId', value: id } }),
                    setIsOpen(false),
                  ]}
                >
                Select
                </DefaultButton>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

TemplateModal.defaultProps = defaultProps;
TemplateModal.propTypes = propTypes;

export default withApollo(TemplateModal);
