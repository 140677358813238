/* eslint-disable import/no-unresolved */
/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  CommandBar,
  DefaultButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import { CommandBarButton, MenuLinkButton, OverviewList } from 'components';
import { copyAndSort } from 'helpers';
import {
  GET_ALL_PARK_MESSAGE_TYPES,
} from './graphql';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'number',
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'name',
      minWidth: 250,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'messages',
      name: 'Messages',
      fieldName: 'messagesLength',
      minWidth: 250,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  filters: {
    text: '',
  },
};

const MessageTypes = ({ client, ...otherProps }) => {
  const { match: { path } } = otherProps;

  const [state, setState] = useState(initialState);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => <CommandBarButton link={link} to={`${path}/create`} />,
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    (async () => {
      const { data: { allParkMessageTypes } } = await client.query({
        query: GET_ALL_PARK_MESSAGE_TYPES,
      });

      const items = [];

      allParkMessageTypes.forEach((t) => {
        items.push({
          ...t,
          messagesLength: t.messages.length,
        });
      });

      setState(prevState => ({
        ...prevState,
        items: copyAndSort(items, 'id', 'number', true),
      }));

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'advancedEditItem',
                  onRender: () => <MenuLinkButton to={`${path}/${item.id}`}>Advanced Edit</MenuLinkButton>,
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const filteredItems = () => {
    let allItems = [...state.items];

    if (state.filters.text !== '') {
      allItems = allItems.filter((item) => {
        // remove messages from search to avoid confusion
        const { messages, __typename, ...filterableData } = item;

        // map out all object values in filterableData
        // return true if a match is found in any of the values of filterableData
        return Object.values(filterableData).map(val => val.toString().toLowerCase())
          .filter(val => val.includes(state.filters.text.toLowerCase())).length;
      });
    }

    return allItems;
  };

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {
        loading ? (
          <Spinner label="Loading, please wait..." />
        ) : (
          <React.Fragment>
            {showFilter && (
              <Stack horizontal tokens={{ childrenGap: 24 }}>
                <TextField
                  label="Filter by"
                  placeholder="Search"
                  name="text"
                  onChange={e => setState(prevState => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      text: e.target.value,
                    },
                  }))}
                />
              </Stack>
            )}

            <Text variant="small">{`Displaying ${filteredItems().length} of ${state.items.length}`}</Text>
            <OverviewList
              columns={state.columns}
              items={state.items}
              setState={setState}
              filteredItems={filteredItems()}
              renderItemColumn={renderItemColumn}
            />
          </React.Fragment>
        )
      }
    </div>
  );
};

MessageTypes.propTypes = propTypes;

export default withApollo(MessageTypes);
