// eslint-disable-next-line import/no-unresolved
import { formatDatetimeString } from 'helpers';

const returnPageCount = (prevState, payload) => {
  if (payload === 'increment') {
    return prevState + 1;
  }

  if (payload === 'decrement') {
    return prevState - 1;
  }

  return payload;
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'UPDATE_EVENT_DATA':
      return {
        ...state,
        allEventTypes: action.payload.allEventTypes,
        allParks: action.payload.allParks,
        loading: false,
      };

    case 'UPDATE_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };

    case 'TOGGLE_FILTER_DISPLAY':
      return {
        ...state,
        showFilter: !state.showFilter,
      };

    case 'UPDATE_FILTER_VALUE':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageCount: 1,
          [action.field]: action.payload,
        },
      };

    case 'SELECT_QUICK_EDIT':
      return {
        ...state,
        selectedEventId: action.payload,
        displayQuickEdit: true,
      };

    case 'DISMISS_QUICK_EDIT':
      return {
        ...state,
        displayQuickEdit: false,
        selectedEventId: null,
      };

    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      };

    case 'UPDATE_PAGINATED_ITEMS':
      return {
        ...state,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        items: action.payload.items,
        loading: false,
      };

    case 'UPDATE_COLUMNS':
      return {
        ...state,
        columns: action.payload.columns,
        items: action.payload.items,
      };

    case 'UPDATE_PAGINATION_CRITERIA':
      return {
        ...state,
        cursorPaginationObject: action.payload.cursorPaginationObject,
        pageCount: (
          action.payload.pageCount
            ? returnPageCount(state.pageCount, action.payload.pageCount) : state.pageCount
        ),
        numberOfRecordsPerPage: (
          action.payload.numberOfRecordsPerPage || state.numberOfRecordsPerPage
        ),
      };

    case 'SET_QUICK_EDIT_MUTATED':
      return {
        ...state,
        quickEditMutated: !state.quickEditMutated,
      };

    default:
      break;
  }

  return state;
};

export const initialState = {
  columns: [
    {
      key: 'id',
      name: '#',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 40,
      isResizable: true,
      data: 'number',
    },
    {
      key: 'title',
      name: 'Title',
      fieldName: 'title',
      minWidth: 70,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'parkName',
      name: 'Park',
      fieldName: 'parkName',
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'typeName',
      name: 'Event Type',
      fieldName: 'typeName',
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'pinned',
      name: 'Pinned',
      fieldName: 'pinned',
      onRender: d => (d.pinned ? 'Pinned' : 'Unpinned'),
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'boolean',
    },
    {
      key: 'cancelled',
      name: 'Cancelled',
      fieldName: 'cancelled',
      onRender: d => (d.cancelled ? 'Cancelled' : 'Not Cancelled'),
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'boolean',
    },
    {
      key: 'startDate',
      name: 'Start Date',
      fieldName: 'startDate',
      onRender: d => formatDatetimeString(d.startDate),
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'endDate',
      name: 'End Date',
      fieldName: 'endDate',
      onRender: d => formatDatetimeString(d.endDate),
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'createdAt',
      name: 'Created At',
      fieldName: 'createdAt',
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      data: 'timestamp',
    },
    {
      key: 'deletedAt',
      name: 'Published',
      fieldName: 'deletedAt',
      onRender: d => (!d.deletedAt ? 'Published' : 'Unpublished'),
      minWidth: 90,
      maxWidth: 140,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  allParks: [],
  allEventTypes: [],
  filters: {
    text: '',
    displayUnpublished: true,
    parkId: 0,
    eventTypeId: 0,
  },
  cursorPaginationObject: {
    first: 10,
  },
  displayQuickEdit: false,
  errors: [],
  loading: true,
  numberOfRecordsPerPage: 10,
  pageCount: 1,
  pageInfo: {},
  quickEditMutated: false,
  selectedEventId: null,
  showFilter: false,
  totalCount: 10,
};
