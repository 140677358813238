/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Nav } from 'office-ui-fabric-react';
import { SideMenuWrapper, Link } from './style';
import AuthService from '../../services/auth/AuthService';
import UserContext from '../../context/userContext';

// temporary authorisation list
const validNames = [
  // dev
  'dev test',
  'Chris Paton',
  'Matt Herbert',
  'Robert Stubbs',
  'Simon Fry',
  'Andy Dunning',
  'Mark Light',
  'Krishna Alluri',
  'Jenny Hampton',
  // IT
  'Jack Podmore',
  // marketing
  'Daniella Perry',
  'James Bevis',
  'Olivia Forster',
  'Stacey Pretty',
  'Paul Edwards',
  'Sammi Orr',
  'Rachel Conaghan',
  'Kelly Farley',
  'Sophie Bridger',
  'Anneliese Moon',
  'Kelly Johnstone',
];

const SideMenu = () => {
  const { user } = useContext(UserContext);
  const { sub: { displayName } } = user;

  const links = [
    {
      key: 'overview',
      name: 'Overview',
      to: '/overview',
    },
    {
      key: 'faqs',
      name: 'FAQs',
      to: '/faqs',
    },
    {
      key: 'specialOffers',
      name: 'Special Offers',
      to: '/special-offers',
    },
    {
      key: 'parksOverview',
      name: 'Overview',
      to: '/parks/overview',
    },
    {
      key: 'notices',
      name: 'Notices',
      to: '/parks/notices',
    },
    {
      key: 'messages',
      name: 'Messages',
      to: '/parks/message-types',
    },
    {
      key: 'eventsOverview',
      name: 'Overview',
      to: '/events/overview',
    },
    // {
    //   key: 'stock',
    //   name: 'Inventory',
    //   to: '/stock',
    // },
    // {
    //   key: 'manufacturers',
    //   name: 'Manufacturers',
    //   to: '/manufacturers',
    // },
    {
      key: 'contactsOverview',
      name: 'Overview',
      to: '/contacts/overview',
    },
    {
      key: 'media',
      name: 'Media',
      to: '/assets/media',
    },
    {
      key: 'ownersAreaFiles',
      name: 'Owners Area Files',
      to: '/assets/files/owners-area',
    },
    {
      key: 'liveChatOverview',
      name: 'Overview',
      to: '/live-chat/overview',
    },
  ];

  const groups = [
    {
      name: 'General',
      // special offers left out of here until it's finished
      links: links.filter(({ key }) => (validNames.includes(displayName) ? ['overview', 'faqs'].includes(key) : key === 'overview')),
    },
    // {
    //   name: 'Stock Management',
    //   links: links.filter(({ key }) => (validNames.includes(displayName) ? ['stock', 'manufacturers'].includes(key) : key === 'stock')),
    //   collapseByDefault: true,
    // },
    {
      name: 'Parks',
      links: links.filter(({ key }) => ['parksOverview', 'notices', 'messages'].includes(key)),
      collapseByDefault: true,
    },
    {
      name: 'Events',
      links: links.filter(({ key }) => key === 'eventsOverview'),
      collapseByDefault: true,
    },
    {
      name: 'Contacts',
      links: links.filter(({ key }) => key === 'contactsOverview'),
      collapseByDefault: true,
    },
    {
      name: 'Digital Asset Management',
      links: links.filter(({ key }) => ['media', 'ownersAreaFiles'].includes(key)),
      collapseByDefault: true,
    },
    {
      name: 'Live Chat',
      links: links.filter(l => l.key === 'liveChatOverview'),
      collapseByDefault: true,
    },
  ];

  if (AuthService.loggedIn()) {
    return (
      <SideMenuWrapper>
        <Nav
          styles={{ root: { width: 300 } }}
          expandButtonAriaLabel="Expand or collapse"
          initialSelectedKey="overview"
          onRenderLink={({ to, name }) => (
            <Link to={to}>
              {name}
            </Link>
          )}
          groups={
            validNames.includes(displayName) ? groups : groups.filter(({ name }) => ['General', 'Stock Management'].includes(name))
          }
        />
      </SideMenuWrapper>
    );
  }

  return true;
};

export default SideMenu;
