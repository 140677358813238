import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_HOLIDAY_HOMES = gql`
  query allHolidayHomes($showDeleted: Boolean) {
    allHolidayHomes(showDeleted: $showDeleted) {
      id
      caravanSerialNumber
      price
      park {
        id
        code
      }
    }
  }
`;
