import { styled } from 'styletron-react';

export const MockContextualMenuLink = styled('button', {
  boxSizing: 'border-box',
  position: 'relative',
  fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  // [-webkit-font-smoothing]: 'antialiased',
  fontSize: '14px',
  fontWeight: '400',
  color: 'rgb(50, 49, 48)',
  backgroundColor: 'transparent',
  width: '100%',
  height: '36px',
  lineHeight: '36px',
  display: 'block',
  cursor: 'pointer',
  paddingTop: '0px',
  paddingRight: '8px',
  paddingBottom: '0px',
  paddingLeft: '4px',
  textAlign: 'left',
  outline: 'transparent',
  borderWidth: 'initial',
  borderStyle: 'none',
  borderColor: 'initial',
  borderImage: 'initial',
  overflow: 'hidden',
  ':hover': {
    backgroundColor: 'rgb(243, 242, 241)',
    color: 'rgb(32, 31, 30)',
  },
});

export const MockContextualMenuLinkItemText = styled('span', {
  marginTop: '0px',
  marginRight: '4px',
  marginBottom: '0px',
  marginLeft: '4px',
  verticalAlign: 'middle',
  display: 'inline-block',
  flexGrow: '1',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});
