import decode from 'jwt-decode';

export default class AuthService {
  constructor() {
    this.getProfile = AuthService.getProfile.bind(this);
    this.setToken = AuthService.setToken.bind(this);
    this.loggedIn = AuthService.loggedIn.bind(this);
    this.logout = AuthService.logout.bind(this);
  }

  static loggedIn() {
    const token = AuthService.getToken();
    return !!token && !AuthService.isTokenExpired(token);
  }

  static isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  static setToken(token) {
    sessionStorage.setItem('token', token);
  }

  static getToken() {
    return sessionStorage.getItem('token');
  }

  static logout() {
    sessionStorage.removeItem('token');
  }

  static getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(AuthService.getToken());
  }
}
