import gql from 'graphql-tag';

export const GET_PARK_CONTACT = gql`
  query parkContact($id: ID!) {
    parkContact(id: $id) {
      id
      salutation
      firstname
      surname
      email
      phoneNo
    }
  }
`;

export const UPDATE_PARK_CONTACT = gql`
  mutation updateParkContact($id: Int!, $input: UpdateParkContactInput!) {
    updateParkContact(id: $id, input: $input) {
      id
    }
  }
`;
