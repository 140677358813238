/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Label,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import { JsonEditor as JSONEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { GET_SPECIAL_OFFER, UPDATE_SPECIAL_OFFER } from './graphql';
import { FooterWrapper } from '../../../../../../components/footer-wrapper';

const QuickEdit = ({
  client,
  specialOfferId,
  handleOnDismiss,
  quickEditMutated,
  setHasQuickEditMutated,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const getSpecialOffer = await client.query({
        query: GET_SPECIAL_OFFER,
        variables: {
          id: specialOfferId,
          showDeleted: true,
        },
      });

      const { data: { specialOffer } } = getSpecialOffer;

      setState({
        specialOffer,
      });

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.specialOffer[name] = value;
    setState(newState);
  };

  const handleJSONEditorChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    const stateObj = state.specialOffer[name];

    // eslint-disable-next-line array-callback-return
    Object.keys(stateObj).map((key) => {
      stateObj[key] !== value[key] && (stateObj[key] = value[key]);
    });

    setState(newState);
  };

  const handleSave = async () => {
    const {
      specialOffer: {
        id,
        __typename,
        ...input
      },
    } = state;

    const response = await client.mutate({
      mutation: UPDATE_SPECIAL_OFFER,
      variables: {
        id: specialOfferId,
        input,
      },
    });

    if (!response) {
      setErrors([{ message: 'Save unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    if (response && response.data) {
      handleOnDismiss();
      setHasQuickEditMutated(!quickEditMutated);
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {/* display server errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>
                {errors.map(({ message }) => (
                  <Text variant="medium" key={message}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}

          <TextField
            label="Special Offer ID"
            value={state.specialOffer.id}
            prefix="#"
            type="number"
            readOnly
          />

          <TextField
            label={`Title: (${
              state.specialOffer.title.length
            }/50 characters)`}
            name="title"
            value={state.specialOffer.title}
            onChange={e => handleInputChange(e)}
            ariaLabel="Title"
            maxLength={50}
            required
          />

          <TextField
            label={`Short Description: (${
              state.specialOffer.shortDescription.length
            }/255 characters)`}
            name="shortDescription"
            value={state.specialOffer.shortDescription}
            onChange={e => handleInputChange(e)}
            ariaLabel="Short Description"
            maxLength={255}
            required
            multiline
          />

          <Stack>
            <Label>Meta</Label>
            <JSONEditor
              name="meta"
              value={state.specialOffer.meta}
              onChange={e => handleJSONEditorChange({ target: { name: 'meta', value: e } })}
            />
          </Stack>

        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = {
  client: PropTypes.object.isRequired,
  specialOfferId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  quickEditMutated: PropTypes.bool.isRequired,
  setHasQuickEditMutated: PropTypes.func.isRequired,
};

export default withApollo(QuickEdit);
