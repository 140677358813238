import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import ParkMessages from './messages/overview';
import {
  GET_PARK_MESSAGE_TYPE,
  CREATE_PARK_MESSAGE_TYPE,
  UPDATE_PARK_MESSAGE_TYPE,
} from './graphql';

const UpdateParkMessageType = ({ client, ...otherProps }) => {
  const {
    match: {
      params: {
        id: messageTypeId,
      },
    },
  } = otherProps;

  const [state, setState] = useState({
    id: null,
    name: '',
  });

  const [isCreatingNewMessageType, setIsCreatingNewMessageType] = useState(
    Number.isNaN(parseInt(messageTypeId, 10)),
  );
  const [loading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isCreatingNewMessageType) {
        const getMessageType = await client.query({
          query: GET_PARK_MESSAGE_TYPE,
          variables: {
            id: messageTypeId === 'create' ? state.id : parseInt(messageTypeId, 10),
          },
        });

        const { data: { parkMessageType } } = getMessageType;

        setState(parkMessageType);
      }

      setIsLoading(false);
    };

    fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewMessageType]);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    newState[name] = value;
    setState(newState);
  };

  const handleSave = async () => {
    setSuccess(false);

    const response = await client.mutate({
      mutation: isCreatingNewMessageType
        ? CREATE_PARK_MESSAGE_TYPE
        : UPDATE_PARK_MESSAGE_TYPE,
      variables: {
        ...(!isCreatingNewMessageType && { id: parseInt(state.id, 10) }),
        input: {
          name: state.name,
        },
      },
    });

    // handle errors
    if (response.errors) {
      const errorsFound = response.errors.map(({ message }, index) => ({
        index,
        message,
      }));

      setErrors(errorsFound);
    }

    // handle successful response
    if (response.data) {
      const responseMapped = !isCreatingNewMessageType
        ? response.data.updateParkMessageType
        : response.data.createParkMessageType;

      if (responseMapped !== null) {
        setState({
          ...state,
          id: parseInt(responseMapped.id, 10),
        });
        setSuccess(isCreatingNewMessageType ? 'create' : 'update');
        setIsCreatingNewMessageType(false);
      } else {
        setErrors([{ index: 'nullError', message: 'Response returned null' }]);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem style={{ height: '100%' }} headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {/* Display errors */}
            {errors.length > 0 && (
              <MessageBar
                messageBarType={MessageBarType.error}
                onDismiss={() => setErrors([])}
                dismissButtonAriaLabel="Close"
                isMultiline
              >
                <Stack tokens={{ childrenGap: 8 }}>

                  {/* server errors */}
                  <React.Fragment>
                    <Text variant="medium">
                      {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                    </Text>

                    {errors.map(({ message, index }) => (
                      <Text variant="medium" key={index}>
                        {`- ${message}`}
                      </Text>
                    ))}
                  </React.Fragment>

                </Stack>
              </MessageBar>
            )}

            {/* message confirming successful save, and instructing to add messages */}
            {errors.length === 0 && success && (
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline
                onDismiss={() => setSuccess(false)}
                dismissButtonAriaLabel="Close"
              >
                <Stack tokens={{ childrenGap: 8 }}>
                  <Text variant="medium">Success</Text>
                  <Text variant="medium">
                    {
                    success === 'create'
                      ? `Your park message type has been created, and you may now add messages
                      related to this type by clicking the Messages tab above`
                      : 'Park message type has been updated'
                  }
                  </Text>
                </Stack>
              </MessageBar>
            )}

            <TextField
              label="Name"
              name="name"
              placeholder="i.e. Sales Manager"
              onChange={e => handleInputChange(e)}
              value={state.name}
              required
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>

      {state.id !== null && (
        <PivotItem headerText={`Messages (${state.messages.length})`} itemIcon="List">
          <ParkMessages messageTypeId={state.id} />
        </PivotItem>
      )}
    </Pivot>
  );
};

UpdateParkMessageType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(UpdateParkMessageType);
