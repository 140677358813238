import React, { useState } from 'react';
import {
  Dropdown,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import {
  CREATE_LIVE_CHAT_GREETING,
  UPDATE_LIVE_CHAT_GREETING,
} from './graphql';
import { apolloClient as client } from '../client';

const UpdateLiveChatGreeting = () => {
  const [state, setState] = useState({
    liveChatGreeting: {
      id: null,
      url: '',
      message: '',
      delay: 0,
      active: true,
    },
    delayOptions: [...Array.from(Array(13).keys(), n => ({ key: n * 5, text: n * 5 }))],
  });
  const [loading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [mutationSuccessful, setIsMutationSuccessful] = useState(false);
  const [isCreatingNewGreeting, setIsCreatingNewGreeting] = useState(true);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.liveChatGreeting[name] = value;
    setState(newState);
  };

  const handleSave = async () => {
    setIsMutationSuccessful(false);
    setIsLoading(true);

    const { liveChatGreeting: { id, ...rest } } = state;

    const response = await client.mutate({
      mutation: isCreatingNewGreeting ? CREATE_LIVE_CHAT_GREETING : UPDATE_LIVE_CHAT_GREETING,
      variables: {
        input: isCreatingNewGreeting ? rest : ({ id, ...rest }),
      },
    });

    if (!response) {
      setErrors([{ message: 'Save unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors);
      return;
    }

    if (response && response.data) {
      const responseMapped = isCreatingNewGreeting
        ? response.data.createLiveChatGreetings
        : response.data.updateLiveChatGreetings;

      setState({
        ...state,
        liveChatGreeting: {
          ...state.liveChatGreeting,
          id: responseMapped.id,
        },
      });

      setIsMutationSuccessful(true);
      setIsCreatingNewGreeting(false);
      setIsLoading(false);
    }
  };

  if (loading) {
    return <Spinner label="Loading please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {/* display errors */}
            {errors.length > 0 && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline
                onDismiss={() => setErrors([])}
                dismissButtonAriaLabel="Close"
              >
                <Stack tokens={{ childrenGap: 8 }}>
                  <Text variant="medium">
                    {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                  </Text>

                  {errors.map(({ message }) => (
                    <Text variant="medium" key={message}>
                      {`- ${message}`}
                    </Text>
                  ))}
                </Stack>
              </MessageBar>
            )}

            {/* display success confirmation */}
            {mutationSuccessful && (
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline
                onDismiss={() => setIsMutationSuccessful(false)}
                dismissButtonAriaLabel="Close"
              >
                <Stack tokens={{ childrenGap: 8 }}>
                  <Text variant="medium">Success</Text>
                  <Text variant="medium">
                    {isCreatingNewGreeting
                      ? 'Your Live Chat Greeting has been created'
                      : 'Live Chat Greeting has been updated'
                    }
                  </Text>
                </Stack>
              </MessageBar>
            )}

            <TextField
              label="Live Chat Greeting ID"
              value={state.liveChatGreeting.id}
              readOnly
            />

            <MessageBar messageBarType={MessageBarType.default}>
              <TextField
                label="URL"
                name="url"
                value={state.liveChatGreeting.url}
                onChange={e => handleInputChange(e)}
                ariaLabel="URL"
                required
              />
              {'Preface the URL with "@" to use a regular expression e.g. @.{0,}parkholidays.(com|local)/holidays'}
            </MessageBar>

            <TextField
              label="Message"
              name="message"
              value={state.liveChatGreeting.message}
              onChange={e => handleInputChange(e)}
              ariaLabel="Message"
              required
              multiline
            />

            <Dropdown
              label="Delay (seconds)"
              name="delay"
              options={state.delayOptions}
              selectedKey={state.liveChatGreeting.delay}
              onChange={(e, item) => handleInputChange({
                target: { name: 'delay', value: item.key },
              })}
              ariaLabel="Delay"
              required
            />

            <Toggle
              label="Active"
              name="active"
              onText="Active"
              offText="Inactive"
              checked={state.liveChatGreeting.active}
              onChange={(e, checked) => handleInputChange({
                target: { name: e.target.name, value: checked },
              })}
              ariaLabel="Active"
              required
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

export default UpdateLiveChatGreeting;
