/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ActionButton } from 'office-ui-fabric-react';

const propTypes = {
  link: PropTypes.shape({}).isRequired,
  to: PropTypes.string.isRequired,
};

const CommandBarButton = ({ link, to }) => (
  <Link
    to={to}
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    <ActionButton
      className={link.className}
      role={link.role}
      iconProps={link.iconProps}
      allowDisabledFocus={link.allowDisabledFocus}
    >
      {link.text}
    </ActionButton>
  </Link>
);

CommandBarButton.propTypes = propTypes;

export default CommandBarButton;
