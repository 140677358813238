/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { initializeIcons } from '@uifabric/icons';
import './index.css';
import 'react-app-polyfill/ie11'; // For IE 11 support (CRA)
import 'core-js/stable'; // for IE 11 support (App)
import { ApolloProvider } from 'react-apollo';
import UserProvider from './provider/userProvider';
import { apolloClient } from './apolloClient';
import App from './App';

const debug = process.env.NODE_ENV === 'production' ? undefined : new DebugEngine();
/*test*/
const engine = new Styletron();

initializeIcons();

ReactDOM.render(
  <StyletronProvider value={engine} debug={debug} debugAfterHydration>
    <ApolloProvider client={apolloClient}>
      <UserProvider>
        <App />
      </UserProvider>
    </ApolloProvider>
  </StyletronProvider>,
  document.getElementById('root'),
);
