// configuration constants etc. (export at end)
export const GRAPHQL_API_URI = process.env.REACT_APP_APOLLO_API_ENDPOINT;

export const MEDIA_TYPES = {
  image: 1,
  youtube: 2,
  matterport: 3,
};

export const PUBLIC_PATHS = {
  assets: 'https://assets.parkholidays.com',
  camerasolution: 'https://camera-solution.parkholidays.com/assets',
  stock: 'https://www.parkholidays.com/caravan-holiday-homes-for-sale/details/',
};

export const HOLIDAY_HOME_IMAGE_ASPECTS = [
  'Kitchen',
  'Exterior',
  'Master bedroom',
  'Lounge',
  'Bathroom',
  'Other',
];

export const FINANCE_RULES = {
  // figures in years
  maxCaravanAgeForFinance: 17,
  minFinanceTerm: 2,
  maxFinanceTerm: 10,
};

export const PHONE_NUMBERS = {
  ownership: '0343 178 7065',
};
