/* eslint-disable import/no-unresolved */
import React, { useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  CommandBar,
  ContextualMenuItemType,
  Dropdown,
  SelectionMode,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import {
  CommandBarButton,
  MenuLinkButton,
  MessageList,
  PaginationButtonRow,
  PaginationResultsButtonRow,
  ShimmeredOverviewList,
} from 'components';
import {
  copyAndSort,
  cursorPaginationUpdateFunctions,
  handleErrorResponse,
  useDebounce,
} from 'helpers';
import QuickEdit from './components/quick-edit';
import { initialState, reducer } from './initialState';
import {
  GET_ALL_EVENTS,
  GET_EVENT_DATA,
  DELETE_EVENT,
  UNDELETE_EVENT,
} from './graphql';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const Events = ({ client, ...otherProps }) => {
  const { match: { path } } = otherProps;
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    columns,
    cursorPaginationObject,
    displayQuickEdit,
    errors,
    filters,
    items,
    loading,
    numberOfRecordsPerPage,
    pageCount,
    quickEditMutated,
    selectedEventId,
    showFilter,
  } = state;

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => <CommandBarButton to={`${path}/create`} link={link} />,
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => dispatch({ type: 'TOGGLE_FILTER_DISPLAY' }),
    },
  ];

  useEffect(() => {
    (async () => {
      dispatch({ type: 'FETCHING' });

      const { data: { allEventTypes, allParks } } = await client.query({
        query: GET_EVENT_DATA,
      });

      const prependedFilterOption = { id: 0, name: 'All' };

      const sortedEventTypes = [
        prependedFilterOption,
        ...allEventTypes.sort((a, b) => a.name.localeCompare(b.name)),
      ];
      const sortedParks = [
        prependedFilterOption,
        ...allParks.sort((a, b) => a.name.localeCompare(b.name)),
      ];

      dispatch({
        type: 'UPDATE_EVENT_DATA',
        payload: {
          allEventTypes: sortedEventTypes, allParks: sortedParks,
        },
      });
    })();
  }, [client]);

  const debouncedSearchTerm = useDebounce(filters.text, 600);

  useMemo(() => {
    (async () => {
      dispatch({ type: 'FETCHING' });

      const { data: { allEvents: { edges, pageInfo, totalCount } } } = await client.query({
        query: GET_ALL_EVENTS,
        variables: {
          cursorPagination: {
            ...cursorPaginationObject,
            first: numberOfRecordsPerPage,
          },
          criteria: {
            /*
              these properties are set to evaluate to null if 'prependedFilterOption'
              is selected in the requesite filter dropdown to avoid requesting
              an id of 0
            */
            eventTypeId: filters.eventTypeId || null,
            parkId: filters.parkId || null,
          },
          ...(debouncedSearchTerm && {
            filters: [{
              column: 'title',
              operator: 'LIKE',
              value: `%${debouncedSearchTerm}%`,
            }],
          }),
          showDeleted: filters.displayUnpublished,
        },
      });

      const events = edges.map(({ node }) => node);
      const fetchedItems = [];

      events.forEach((e) => {
        fetchedItems.push({
          ...e,
          parkName: e.park ? e.park.name : null,
          typeName: e.type ? e.type.name : null,
        });
      });

      dispatch({ type: 'UPDATE_PAGINATED_ITEMS', payload: { totalCount, pageInfo, items: copyAndSort(fetchedItems, 'createdAt', 'datetime', true) } });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    cursorPaginationObject,
    debouncedSearchTerm,
    filters.eventTypeId,
    filters.parkId,
    filters.displayUnpublished,
    numberOfRecordsPerPage,
    pageCount,
    quickEditMutated,
  ]);

  const handlePublishUnpublish = async (item) => {
    dispatch({ type: 'UPDATE_ERRORS', payload: [] });

    const isPublishingEvent = item.deletedAt;

    const response = await client.mutate({
      mutation: isPublishingEvent ? UNDELETE_EVENT : DELETE_EVENT,
      variables: {
        id: item.id,
      },
    });

    if (!response || response.errors) {
      const [payload] = handleErrorResponse({ response, message: `Error ${isPublishingEvent ? 'publishing' : 'unpublishing'} event` });
      dispatch({ type: 'UPDATE_ERRORS', payload });
      return;
    }

    if (response && response.data) {
      // remove event from items
      const index = state.items.findIndex(event => event.id === item.id);
      const newItems = Array.from(state.items);
      // Overwrite deletedAt value from one of the two paths depending on delete/undelete
      const deletedAt = isPublishingEvent
        ? response.data.undeleteEvent.deletedAt
        : response.data.deleteEvent.deletedAt;
      newItems[index].deletedAt = deletedAt;

      dispatch({ type: 'UPDATE_ITEMS', payload: newItems });
    }
  };

  const returnMenuItems = item => [
    {
      key: 'quickEditItem',
      name: 'Quick Edit',
      onClick: (e) => {
        e.preventDefault();
        dispatch({ type: 'SELECT_QUICK_EDIT', payload: item.id });
      },
    },
    {
      key: 'advancedEditItem',
      onRender: () => (
        <MenuLinkButton to={`${path}/${item.id}`}>
          Advanced Edit
        </MenuLinkButton>
      ),
    },
    {
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'publishUnpublishItem',
      name: item.deletedAt ? 'Publish' : 'Unpublish',
      onClick: () => {
        handlePublishUnpublish(item);
      },
    },
  ];

  const updatePaginationCriteria = cursorPaginationUpdateFunctions(
    {
      dispatch,
      startCursor: state.pageInfo.startCursor,
      endCursor: state.pageInfo.endCursor,
      numberOfRecordsPerPage,
      pageCount,
      type: 'UPDATE_PAGINATION_CRITERIA',
    },
  );

  return (
    <Stack
      styles={{ root: { height: '100%' } }}
      verticalAlign="space-between"
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <CommandBar
          items={commandBarItems}
          farItems={commandBarFarItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
        />

        {displayQuickEdit && (
          <QuickEdit
            eventId={parseInt(selectedEventId, 10)}
            handleOnDismiss={() => dispatch({ type: 'DISMISS_QUICK_EDIT' })}
            setQuickEditMutated={() => dispatch({ type: 'SET_QUICK_EDIT_MUTATED' })}
          />
        )}

        {showFilter && (
          <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 24 }}>
            <TextField
              label="Filter by"
              placeholder="Search"
              name="text"
              onChange={e => dispatch({ type: 'UPDATE_FILTER_VALUE', field: 'text', payload: e.target.value })}
              styles={{ root: { width: 180 } }}
            />

            <Dropdown
              label="Park"
              placeholder="Select a Park"
              selectedKey={state.filters.parkId}
              options={state.allParks.map(park => ({
                key: park.id,
                text: park.name,
              }))}
              name="parkId"
              onChange={(e, item) => dispatch({
                type: 'UPDATE_FILTER_VALUE',
                field: 'parkId',
                payload: item.key,
              })}
              styles={{ root: { width: 180 } }}
            />

            <Dropdown
              label="Event Type"
              placeholder="Select an Event Type"
              selectedKey={state.filters.eventTypeId}
              options={state.allEventTypes.map(eventType => ({
                key: eventType.id,
                text: eventType.name,
              }))}
              name="eventTypeId"
              onChange={(e, item) => dispatch({
                type: 'UPDATE_FILTER_VALUE',
                field: 'eventTypeId',
                payload: item.key,
              })}
              styles={{ root: { width: 180 } }}
            />

            <Toggle
              label="Include Unpublished"
              defaultChecked={filters.displayUnpublished}
              onText="Yes"
              offText="No"
              name="displayUnpublished"
              onChange={(e, checked) => dispatch({ type: 'UPDATE_FILTER_VALUE', field: 'displayUnpublished', payload: checked })}
            />
          </Stack>
        )}

        {errors.length > 0 && (
          <MessageList
            messages={errors}
            messageFunction={() => dispatch({ type: 'UPDATE_ERRORS', payload: [] })}
            messageType="error"
          />
        )}

        <Text variant="small">{`Displaying ${items.length} of ${state.totalCount}`}</Text>

        <ShimmeredOverviewList
          columns={columns}
          items={items}
          loading={loading}
          returnMenuItems={returnMenuItems}
          selectionMode={SelectionMode.none}
          setState={(newColumns, newItems) => dispatch(({ type: 'UPDATE_COLUMNS', payload: { columns: newColumns, items: newItems } }))}
          styles={{ root: { minHeight: 600 } }}
        />
      </Stack>

      <Stack>
        <PaginationButtonRow
          incrementFunction={updatePaginationCriteria.increment}
          decrementFunction={updatePaginationCriteria.decrement}
          loading={loading}
          pageCount={pageCount}
          selectPageFunction={updatePaginationCriteria.select}
          numOfPages={Math.ceil(state.totalCount / numberOfRecordsPerPage)}
        />

        <PaginationResultsButtonRow
          numberOfRecordsPerPage={numberOfRecordsPerPage}
          totalCount={state.totalCount}
          updateFunction={updatePaginationCriteria.updateNumberOfRecords}
        />
      </Stack>
    </Stack>
  );
};

Events.propTypes = propTypes;

export default withApollo(withRouter(Events));
