import gql from 'graphql-tag';

export const GET_ALL_PARK_NOTICES = gql`
  query allParkNotices($showDeleted: Boolean) {
    allParkNotices(showDeleted: $showDeleted) {
      id
      title
      publishAt
      expireAt
      parks {
        id
        name
      }
      deletedAt
    }
  }
`;

export const DELETE_PARK_NOTICE = gql`
  mutation deleteParkNotice($id: Int!) {
    deleteParkNotice(id: $id) {
      id
    }
  }
`;

export const UNDELETE_PARK_NOTICE = gql`
  mutation undeleteParkNotice($id: Int!) {
    undeleteParkNotice(id: $id) {
      id
    }
  }
`;
