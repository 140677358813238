/* eslint-disable import/no-unresolved */
import { MessageList, SCEditor } from 'components';
import {
  convertHTMLDatetimeString,
  handleErrorResponse
} from 'helpers';
import {
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField
} from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import {
  CREATE_PARK_MESSAGE, GET_PARK_MESSAGE,
  GET_PARK_MESSAGE_DATA, UPDATE_PARK_MESSAGE
} from './graphql';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const UpdateParkMessage = ({ client, ...otherProps }) => {
  const {
    match: {
      params: { messageTypeId, id: parkMessageId },
    },
  } = otherProps;

  const [isCreatingNewMessage, setIsCreatingNewMessage] = (
    useState(Number.isNaN(parseInt(parkMessageId, 10)))
  );

  const [state, setState] = useState({
    parkMessage: {
      id: null,
      message: '',
      parkId: null,
      parkMessageTypeId: parseInt(messageTypeId, 10),
      contactId: null,
      publishAt: '',
      expiresAt: '',
    },
    allParkContacts: [],
    selectedParkContacts: [],
    allParks: [],
    allParkMessageTypes: [],
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const msg = useRef();

  useEffect(() => {
    (async () => {
      let parkMessage = {};

      if (!isCreatingNewMessage) {
        const getParkMessage = await client.query({
          query: GET_PARK_MESSAGE,
          variables: {
            id: parkMessageId === 'create' ? state.parkMessage.id : parseInt(parkMessageId, 10),
            showDeleted: true,
          },
        });

        // eslint-disable-next-line prefer-destructuring
        parkMessage = getParkMessage.data.parkMessage;
      }

      const { data: { allParks, allParkMessageTypes, allParkContacts } } = await client.query({
        query: GET_PARK_MESSAGE_DATA,
      });

      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));
      const sortedParkContacts = allParkContacts.map(({
        firstname, surname, jobRole, ...rest
      }) => ({
        name: `${surname || ''}, ${firstname || ''} ${jobRole ? `(${jobRole.jobTitle})` : ''}`,
        ...rest,
      })).sort((a, b) => a.name.localeCompare(b.name));

      const parkMessageForState = !isCreatingNewMessage
        ? parkMessage
        : state.parkMessage;

      setState({
        ...state,
        allParks: sortedParks,
        allParkMessageTypes,
        allParkContacts: sortedParkContacts,
        parkMessage: parkMessageForState,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewMessage]);

  useEffect(() => {
    const { parkMessage: { parkId } } = state;
    let contactId = null;

    const selectedParkContacts = state.allParkContacts.filter((contact, index, array) => {
      const parkIds = array[index].parks.map(park => parseInt(park.id, 10));
      return parkIds.includes(parkId);
    });

    if (parseInt(messageTypeId, 10) === 1) {
      const manager = selectedParkContacts.find(contact => contact.jobRoleId === 1);
      contactId = (manager ? manager.id : null);
    }

    setState(prevState => ({
      ...prevState,
      ...(contactId && { parkMessage: { ...prevState.parkMessage, contactId } }),
      selectedParkContacts,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.parkMessage.parkId]);

  const handleInputChange = ({ target: { name, value } }) => {
    setState((prevState) => {
      const newParkMessage = Object.assign({}, prevState.parkMessage);
      newParkMessage[name] = value;
      return {
        ...prevState,
        parkMessage: newParkMessage,
      };
    });
  };

  const validateData = () => {
    const errorsFound = [];

    const {
      parkMessage: {
        parkId,
        parkMessageTypeId,
        contactId,
      },
    } = state;

    if (!parkId) {
      errorsFound.push({
        title: 'Invalid Park',
        message: 'Please ensure you have selected a Park',
      });
    }

    if (!parkMessageTypeId) {
      errorsFound.push({
        title: 'Invalid Park Message Type',
        message: 'Please ensure you have selected a Park Message Type',
      });
    }

    if (!contactId) {
      errorsFound.push({
        title: 'Invalid Contact',
        message: 'Please ensure you have selected a Contact',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleSave = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSuccessMessages([]);

    if (validateData()) {
      const {
        parkMessage: {
          __typename,
          id,
          parkId,
          contactId,
          expiresAt,
          publishAt,
          ...input
        },
      } = state;

      const formatDateString = string => string.substring(0, 16).concat(':00');

      const response = await client.mutate({
        mutation: isCreatingNewMessage ? CREATE_PARK_MESSAGE : UPDATE_PARK_MESSAGE,
        variables: {
          ...(!isCreatingNewMessage && { id: state.parkMessage.id }),
          input: {
            ...input,
            parkId: parseInt(parkId, 10),
            contactId: parseInt(contactId, 10),
            expiresAt: formatDateString(expiresAt),
            publishAt: formatDateString(publishAt),
          },
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: `Error ${isCreatingNewMessage ? 'creating' : 'updating'} message` }));
        return;
      }

      if (response && response.data) {
        const responseMapped = isCreatingNewMessage
          ? response.data.createParkMessage
          : response.data.updateParkMessage;

        setState({
          ...state,
          parkMessage: {
            ...state.parkMessage,
            id: parseInt(responseMapped.id, 10),
          },
        });

        setSuccessMessages([{ message: `Park message ${isCreatingNewMessage ? 'created' : 'updated'}` }]);
        setIsCreatingNewMessage(false);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="Park Message ID"
              name="id"
              value={state.parkMessage.id}
              prefix="#"
              type="number"
              readOnly
            />

            <TextField
              label="Park Message Type"
              name="parkMessageTypeId"
              value={state.allParkMessageTypes.find(({ id }) => (
                parseInt(id, 10) === parseInt(state.parkMessage.parkMessageTypeId, 10))).name}
              readOnly
              required
            />

            <Dropdown
              label="Park"
              selectedKey={parseInt(state.parkMessage.parkId, 10)}
              options={state.allParks.map(({ id, name }) => ({
                key: parseInt(id, 10),
                text: name,
              }))}
              onChange={(e, item) => handleInputChange({
                target: { name: 'parkId', value: item.key },
              })}
              required
            />

            <Dropdown
              label="Park Contact"
              selectedKey={parseInt(state.parkMessage.contactId, 10)}
              options={state.selectedParkContacts.map(({ id, name }) => ({
                key: parseInt(id, 10),
                text: name,
              }))}
              onChange={(e, item) => handleInputChange({
                target: { name: 'contactId', value: item.key },
              })}
              required
            />

            <SCEditor
              elemRef={msg}
              handleInputChange={handleInputChange}
              label="Message"
              objRef="message"
              required
              value={state.parkMessage.message}
            />

            <TextField
              label="Publish At"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              name="publishAt"
              value={convertHTMLDatetimeString({
                string: state.parkMessage.publishAt, toHTML: true,
              })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="Expires At"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              name="expiresAt"
              value={convertHTMLDatetimeString({
                string: state.parkMessage.expiresAt, toHTML: true,
              })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateParkMessage.propTypes = propTypes;

export default withApollo(UpdateParkMessage);
