import gql from 'graphql-tag';

export const GET_EVENT = gql`
  query event($id: Int!, $showDeleted: Boolean) {
    event(id: $id, showDeleted: $showDeleted) {
      id
      title
      slug
      location
      description
      startDate
      endDate
      pinned
      cancelled
      parkId
      eventTypeId
      mediaId
      media {
        id
        description
        path
    }
      eventCategoryId
    }
  }
`;

export const GET_EVENT_DATA = gql`
  {
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
    allEventTypes {
      id
      name
    }
    allEventCategories {
      id
      name
    }
  }
`;

export const CREATE_CS_EVENT = gql`
mutation CreateCSEvent($input: CreateCSEventInput!) {
  createCSEvent(input: $input) {
    id
    slug
  }
}`;

export const UPDATE_CS_EVENT = gql`
mutation UpdateCSEvent($id: Int!, $input: UpdateCSEventInput!) {
  updateCSEvent(id:$id, input: $input) {
    id
  }
}`;

export const CREATE_EVENT_TYPE = gql`
  mutation createEventType($input: CreateEventTypeInput!) {
    createEventType(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_EVENT_CATEGORY = gql`
  mutation createEventCategory($input: CreateEventCategoryInput!) {
    createEventCategory(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_CS_EVENT_CATEGORY = gql`
mutation CreateCSEventCategory($input: CreateCSEventCategoryInput!) {
  createCSEventCategory(input: $input) {
    id
    name
  }
}`;
