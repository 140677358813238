import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { GET_ALL_HOLIDAY_HOMES } from './graphql';

const StockOnlineReport = ({ client }) => {
  const [state, setState] = useState({
    items: [],
    loading: true,
  });

  useEffect(() => {
    if (state.items.length === 0) {
      const fetchData = async () => {
        const res = await client.query({
          query: GET_ALL_HOLIDAY_HOMES,
          variables: {
            showDeleted: false,
          },
        });

        const { allHolidayHomes } = res.data;

        setState(prevState => ({
          ...prevState,
          items: allHolidayHomes,
          loading: false,
        }));
      };

      fetchData();
    }
  }, [client, state.items]);

  if (state.loading) {
    return 'Loading...';
  }

  return (
    <div>
      <h3>Report: All stock units published online</h3>
      <small>{`Total count: ${state.items.length}`}</small>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Caravan Serial No.</th>
            <th>Park Code</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {state.items.map(({
            id,
            caravanSerialNumber,
            price,
            park: { code },
          }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{caravanSerialNumber}</td>
              <td>{code}</td>
              <td>{price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

StockOnlineReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(StockOnlineReport);
