import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from 'apollo-boost';

const uri = 'https://svkkzeo2hbdbjlfzfr7w5p7yde.appsync-api.eu-west-2.amazonaws.com/graphql';

// ApolloClient setup with Middleware
const httpLink = new HttpLink({ uri });
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'Content-Type': 'application/graphql',
      'x-api-key': 'da2-3fispjahsbdenj6q5dxi2vym2a',
    },
  });
  return forward(operation);
});

// eslint-disable-next-line import/prefer-default-export
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { // <Query />
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
