import gql from 'graphql-tag';

export const GET_ALL_PARK_CONTACTS = gql`
  query allParkContacts($criteria: ParkContactCriteria, $pagination: PaginationCriteria,  $showDeleted: Boolean) {
    allParkContacts(criteria: $criteria, pagination: $pagination, showDeleted: $showDeleted) {
      id
      firstname
      surname
      email
      phoneNo
      mobilePhoneNo
      media {
        path
      }
      jobRole {
        jobTitle
      }
      parks {
        id
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const DELETE_PARK_CONTACT = gql`
  mutation deleteParkContact($id: Int!) {
    deleteParkContact(id: $id){
      id
      deletedAt
    }
  }
`;

export const UNDELETE_PARK_CONTACT = gql`
  mutation undeleteParkContact($id: Int!) {
    undeleteParkContact(id: $id){
      id
    }
  }
`;
