/* eslint-disable import/no-unresolved */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import { FooterWrapper, SCEditor } from 'components';
import {
  GET_FAQ,
  UPDATE_FAQ,
} from './graphql';

const QuickEdit = ({
  client,
  faqId,
  handleOnDismiss,
  hasQuickEditSuccessfullyMutated,
  setHasQuickEditSuccessfullyMutated,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const ans = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const getFaq = await client.query({
        query: GET_FAQ,
        variables: {
          id: faqId,
          showDeleted: true,
        },
      });

      const { data: { faq } } = getFaq;

      setState({
        faq,
      });

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.faq[name] = value;
    setState(newState);
  };

  const validateData = () => {
    const errorsFound = [];

    const { faq: { question, answer } } = state;

    if (!question) {
      errorsFound.push({
        title: 'Invalid Question',
        description: 'Please ensure you have entered a question',
      });
    }

    if (!answer) {
      errorsFound.push({
        title: 'Invalid Answer',
        description: 'Please ensure you have entered an answer',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleSave = async () => {
    setErrors([]);
    if (validateData()) {
      const {
        faq: {
          __typename, id, faqCategoryId, ...input
        },
      } = state;

      const response = await client.mutate({
        mutation: UPDATE_FAQ,
        variables: {
          id: faqId,
          input: {
            ...input,
            categoryId: faqCategoryId,
          },
        },
      });

      if (!response) {
        setErrors([{ index: 0, message: 'Save unsuccessful' }]);
        return;
      }

      if (response && response.errors) {
        setErrors(response.errors.map(({ message }, index) => ({
          index,
          message,
        })));
        return;
      }

      if (response && response.data) {
        setHasQuickEditSuccessfullyMutated(!hasQuickEditSuccessfullyMutated);
        handleOnDismiss();
      }
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {/* display errors */}
          {errors.length !== 0 && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={() => setErrors([])}
            dismissButtonAriaLabel="Close"
          >
            <Stack tokens={{ childrenGap: 8 }}>
              {errors
                .flatMap(obj => Object.keys(obj))
                .includes('title' && 'description')
                ? (
                  <React.Fragment>
                    {/* client errors */}
                    <Text variant="medium">
                      {`Input ${errors.length === 1 ? 'error' : 'errors'}:`}
                    </Text>

                    {errors.map(({ title, description }) => (
                      <Text variant="medium" key={title}>
                        {`- ${description}`}
                      </Text>
                    ))}
                  </React.Fragment>
                )
                : (
                  <React.Fragment>
                    {/* server errors */}
                    <Text variant="medium">
                      {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                    </Text>

                    {errors.map(({ message, index }) => (
                      <Text variant="medium" key={index}>
                        {`- ${message}`}
                      </Text>
                    ))}
                  </React.Fragment>
                )}
            </Stack>
          </MessageBar>
          )}

          <TextField
            label="FAQ ID"
            value={state.faq.id}
            prefix="#"
            type="number"
            readOnly
          />

          <TextField
            label={`Question: (${
              state.faq.question.length
            }/255 characters)`}
            name="question"
            value={state.faq.question}
            onChange={e => handleInputChange(e)}
            ariaLabel="Question"
            maxLength={255}
            multiline
            required
          />

          <SCEditor
            elemRef={ans}
            handleInputChange={handleInputChange}
            label="Answer"
            objRef="answer"
            required
            value={state.faq.answer}
          />

        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = {
  client: PropTypes.object.isRequired,
  faqId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  hasQuickEditSuccessfullyMutated: PropTypes.bool.isRequired,
  setHasQuickEditSuccessfullyMutated: PropTypes.func.isRequired,
};

export default withApollo(QuickEdit);
