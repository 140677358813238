/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  IconButton,
  Text,
  Stack,
} from 'office-ui-fabric-react';

const GroupHeader = ({
  id: propId,
  name,
  url,
  level,
  isCollapsed,
  toggleCollapse,
  setParentState,
  toggleModalOpen,
  children,
}) => {
  const id = parseInt(propId, 10);

  const [preselected, setIsPreselected] = useState(false);

  const handleSelection = () => {
    setParentState(prevState => ({
      ...prevState,
      page: {
        ...prevState.page,
        parentId: id,
      },
    }));
    toggleModalOpen(false);
  };

  return (
    <Stack
      onMouseEnter={() => setIsPreselected(true)}
      onMouseLeave={() => setIsPreselected(false)}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={{ root: { backgroundColor: preselected ? '#eee' : 'inherit', height: 50 } }}
    >
      <Stack>
        <Stack
          horizontal
          styles={{ root: { marginLeft: (level + 1) * 25 } }}
        >
          <div style={{ height: '100%', width: 50 }}>
            {children.length > 0 && (
              <IconButton
                iconProps={{ iconName: isCollapsed ? 'ChevronRightSmall' : 'ChevronDownSmall' }}
                onClick={() => toggleCollapse()}
              />
            )}
          </div>
          <Text styles={{ root: { margin: '0 10px', width: 50 } }}>{id}</Text>
          <Text styles={{ root: { margin: '0 10px', width: 250 } }}>{name}</Text>
          <Text styles={{ root: { margin: '0 10px' } }}>{url}</Text>
          {children.length > 0 && <Text>{`(${children.length})`}</Text>}
        </Stack>
      </Stack>

      {preselected && (
        <DefaultButton
          text="Select"
          styles={{ root: { marginRight: '25px' } }}
          onClick={() => handleSelection()}
        />
      )}
    </Stack>
  );
};

GroupHeader.defaultProps = {
  children: [],
};

GroupHeader.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  setParentState: PropTypes.func.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GroupHeader;
