/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { MessageList, Toggle } from 'components';
import { convertHTMLDatetimeString, handleErrorResponse } from 'helpers';
import _ from 'lodash';
import createSlug from './createSlug';
import onRenderOption from './onRenderOption';
import {
  CREATE_CS_EVENT,
  CREATE_EVENT_TYPE,
  CREATE_CS_EVENT_CATEGORY,
  GET_EVENT,
  GET_EVENT_DATA,
  UPDATE_CS_EVENT,

} from './graphql';

/*
  NOTE:
    having media picker within a dialog (event category) and being unable to update
    categories and event types may not be ideal long term solutions but have been implemented
    to get this section of the CMS finished on time.

    likewise some of the code with event categories & events is duplicated to some
    degree and could possibly be refactored.

    might make sense in the future to refactor this page to use useReducer instead of useState
    due to the number of interrelated state changes.
*/

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

const UpdateEvent = ({ client, ...otherProps }) => {
  const {
    match: {
      params: { id: eventId },
    },
  } = otherProps;

  const [isCreatingNewEvent, setIsCreatingNewEvent] = useState(Number.isNaN(parseInt(eventId, 10)));

  const [state, setState] = useState({
    event: {
      id: null,
      slug: '',
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      pinned: false,
      cancelled: false,
      parkId: null,
      eventTypeId: null,
      mediaId: null,
      eventCategoryId: null,
    },
    allParks: [],
    allEventTypes: [],
    allEventCategories: [],
    eventType: {
      name: '',
      iconName: '',
    },
    eventCategory: {
      name: '',
      mediaId: null,
    },
  });
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [dialogDisplayStatus, setDialogDisplayStatus] = useState({
    eventCategoryId: false,
    eventTypeId: false,
  });

  const [mediaFlag, setMediaFlag] = useState(true);

  useEffect(() => {
    if (state && state.event && state.event.media && state.event.media.path && mediaFlag) {
      const eventDefinition = Object.assign({}, state);
      eventDefinition.event.mediaId = eventDefinition.event.media.path;
      setState({ ...eventDefinition });
      setMediaFlag(false);
    }
  }, [state, setState, mediaFlag]);

  useEffect(() => {
    (async () => {
      let event = {};

      if (!isCreatingNewEvent) {
        const getEvent = await client.query({
          query: GET_EVENT,
          variables: {
            id: eventId === 'create' ? parseInt(state.event.id, 10) : parseInt(eventId, 10),
            showDeleted: true,
          },
        });
        // eslint-disable-next-line prefer-destructuring
        event = _.get(getEvent, 'data.event', '');
      }

      const { data: { allParks, allEventTypes, allEventCategories } } = await client.query({
        query: GET_EVENT_DATA,
      });

      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      const newItem = { id: 0, name: 'Add New', data: { icon: 'Add' } };

      const sortedEventTypes = allEventTypes.sort((a, b) => a.name.localeCompare(b.name));
      sortedEventTypes.unshift(newItem);

      const sortedEventCategories = allEventCategories.sort((a, b) => a.name.localeCompare(b.name));
      sortedEventCategories.unshift(newItem);

      const eventForState = !isCreatingNewEvent
        ? event
        : state.event;

      setState({
        ...state,
        event: eventForState,
        allParks: sortedParks,
        allEventTypes: sortedEventTypes,
        allEventCategories: sortedEventCategories,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewEvent]);

  const handleDropdownChange = ({ target: { name, value } }) => {
    if (value === 0) {
      setDialogDisplayStatus(prevState => ({ ...prevState, [name]: !prevState[name] }));
    }

    setState(prevState => ({
      ...prevState,
      event: { ...prevState.event, [name]: value === 0 ? null : value },
    }));
  };

  const handleInputChange = ({ target: { name, value, object = 'event' } }) => {
    const newState = Object.assign({}, state);
    state[object][name] = value;
    setState(newState);
  };

  const handleEventTypeCreation = async () => {
    if (state.eventType.name.length && state.eventType.iconName.length) {
      const { eventType: input } = state;

      const response = await client.mutate({
        mutation: CREATE_EVENT_TYPE,
        variables: {
          input,
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Event type saved unsuccessfully' }));
        return;
      }

      if (response && response.data && response.data.createEventType) {
        // push new result into state.allEventTypes
        // set the selected event type as the new event type
        const { data: { createEventType: { id, name } } } = response;
        const newArrayOfEventTypes = [...state.allEventTypes, { id, name }];

        const sortedArrayOfEventTypes = newArrayOfEventTypes
          .slice(0, 1)
          .concat(
            newArrayOfEventTypes
              .slice(1, newArrayOfEventTypes.length)
              .sort((a, b) => a.name.localeCompare(b.name)),
          );

        setState({
          ...state,
          allEventTypes: sortedArrayOfEventTypes,
          event: {
            ...state.event,
            eventTypeId: id,
          },
          eventType: {
            name: '',
            iconName: '',
          },
        });
      }
      setDialogDisplayStatus(prevState => ({
        ...prevState,
        eventTypeId: false,
      }));
    }
  };

  const handleEventCategoryCreation = async () => {
    if (state.eventCategory.name.length && state.eventCategory.mediaId) {
      console.log('state.eventCategory.name', state.eventCategory.name);
      const { eventCategory: input } = state;

      const response = await client.mutate({
        mutation: CREATE_CS_EVENT_CATEGORY,
        variables: {
          input,
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Event Category saved unsuccessfully' }));
        return;
      }

      if (response && response.data && response.data.createCSEventCategory) {
        // push new result into state.allEventCategories
        // set the selected event category as the new event category
        const { data: { createCSEventCategory: { id, name } } } = response;
        const newArrayOfEventCategories = [...state.allEventCategories, { id, name }];

        const sortedArrayOfEventCategories = newArrayOfEventCategories
          .slice(0, 1)
          .concat(
            newArrayOfEventCategories
              .slice(1, newArrayOfEventCategories.length)
              .sort((a, b) => a.name.localeCompare(b.name)),
          );

        setState({
          ...state,
          allEventCategories: sortedArrayOfEventCategories,
          event: {
            ...state.event,
            eventCategoryId: id,
          },
          eventCategory: {
            name: '',
            mediaId: null,
          },
        });
      }
      setDialogDisplayStatus(prevState => ({
        ...prevState,
        eventCategoryId: false,
      }));
    }
  };

  const validateData = () => {
    const errorsFound = [];

    const {
      event: {
        parkId,
        eventTypeId,
        eventCategoryId,
        mediaId,
      },
    } = state;

    if (!parkId) {
      errorsFound.push({
        title: 'Invalid Park',
        message: 'Please ensure you have selected a Park',
      });
    }

    if (!eventCategoryId) {
      errorsFound.push({
        title: 'Invalid Event Category',
        message: 'Please ensure you have selected an Event Category',
      });
    }

    if (!eventTypeId) {
      errorsFound.push({
        title: 'Invalid Event Type',
        message: 'Please ensure you have selected an Event Type',
      });
    }


    if (!mediaId) {
      errorsFound.push({
        title: 'Invalid Media ID',
        message: 'Please ensure you have selected a Media ID',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleSave = async () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSuccessMessages([]);

    if (validateData()) {
      const {
        event: {
          __typename, id, cancelled, startDate, endDate, ...input
        },
      } = state;

      const slug = (isCreatingNewEvent ? createSlug(state.event.title) : state.event.slug);

      const formatDateString = string => string.substring(0, 16).concat(':00');
      const isCancelled = cancelled ? 1 : 0;
      const response = await client.mutate({
        mutation: isCreatingNewEvent ? CREATE_CS_EVENT : UPDATE_CS_EVENT,
        variables: {
          ...(!isCreatingNewEvent && { id: parseInt(state.event.id, 10) }),
          input: {
            ..._.omit(input, 'media'),
            cancelled: isCreatingNewEvent ? cancelled : isCancelled,
            slug,
            startDate: formatDateString(startDate),
            endDate: formatDateString(endDate),
          },
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Save unsuccessful' }));
        return;
      }

      if (response && response.data) {
        const responseMapped = isCreatingNewEvent
          ? response.data.createCSEvent
          : response.data.updateCSEvent;

        setState({
          ...state,
          event: {
            ...state.event,
            id: parseInt(responseMapped.id, 10),
            ...(isCreatingNewEvent && { slug: responseMapped.slug }),
          },
        });

        setSuccessMessages([{
          title: 'Event',
          message: `Event has been ${isCreatingNewEvent ? 'created' : 'updated'}`,
        }]);

        setIsCreatingNewEvent(false);
      }
    }
  };

  if (loading) {
    return <Spinner label="Loading, please wait..." />;
  }

  return (
    <Pivot styles={{ icon: { paddingRight: '6px' } }}>
      <PivotItem headerText="Details" itemIcon="Edit">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <Stack tokens={{ childrenGap: 18 }}>

            {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}
            {successMessages.length > 0 && <MessageList messages={successMessages} messageFunction={setSuccessMessages} messageType="success" />}

            <TextField
              label="Event ID"
              name="id"
              value={parseInt(state.event.id, 10)}
              prefix="#"
              type="number"
              readOnly
            />

            <TextField
              label="Slug"
              name="slug"
              value={state.event.slug}
              readOnly
            />

            <TextField
              label={`Title: (${
                state.event.title.length
              }/50 characters)`}
              name="title"
              placeholder="Enter an event title.."
              value={state.event.title}
              onChange={e => handleInputChange(e)}
              ariaLabel="Title"
              maxLength={50}
              required
            />

            <TextField
              label={`Description: (${
                state.event.description.length
              }/4000 characters)`}
              name="description"
              placeholder="Enter an event description.."
              value={state.event.description}
              onChange={e => handleInputChange(e)}
              multiline
              rows={8}
              maxLength={4000}
              required
            />

            <Dropdown
              label="Park"
              placeholder="Select a Park"
              selectedKey={
                state.event.parkId
                  ? state.event.parkId.toString()
                  : undefined
              }
              options={state.allParks.map(park => ({
                key: park.id,
                text: park.name,
              }))}
              name="parkId"
              onChange={(e, item) => handleInputChange({
                target: { name: 'parkId', value: item.key },
              })}
              required
            />

            <TextField
              label="Location"
              name="location"
              placeholder="e.g. Clubhouse"
              value={state.event.location}
              onChange={e => handleInputChange(e)}
              required
            />

            <Dropdown
              label="Event Category"
              name="eventCategoryId"
              placeholder="Select an option"
              onChange={(e, { key }) => handleDropdownChange({
                target: { name: 'eventCategoryId', value: key },
              })}
              selectedKey={state.event.eventCategoryId}
              options={state.allEventCategories.map(({ id: key, name: text, data }) => ({
                key,
                text,
                data,
              }))}
              onRenderOption={option => onRenderOption(option)}
              required
              notifyOnReselect={false}
            />

            <Dialog
              hidden={!dialogDisplayStatus.eventCategoryId}
              onDismiss={() => setDialogDisplayStatus(prevState => (
                { ...prevState, eventCategoryId: false }
              ))}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

                <TextField
                  label="Event Category Name"
                  name="name"
                  value={state.eventCategory.name}
                  onChange={e => handleInputChange({ target: { name: e.target.name, value: e.target.value, object: 'eventCategory' } })}
                  required
                />
                <TextField
                  name="mediaIdEventCategory"
                  placeholder="Enter media url"
                  value={state.eventCategory.mediaId}
                  onChange={e => setState(prevState => ({
                    ...prevState,
                    eventCategory: {
                      ...prevState.eventCategory,
                      mediaId: e.target.value,
                    },
                  }))}
                  label="Media Url"
                  required
                />
                <DialogFooter>
                  <PrimaryButton onClick={() => handleEventCategoryCreation()} text="Save" />
                  <DefaultButton onClick={() => setDialogDisplayStatus(prevState => ({ ...prevState, eventCategoryId: false }))} text="Cancel" />
                </DialogFooter>
              </Stack>
            </Dialog>
            <Dropdown
              label="Event Type"
              name="eventTypeId"
              placeholder="Select an option"
              onChange={(e, { key }) => handleDropdownChange({
                target: { name: 'eventTypeId', value: key },
              })}
              selectedKey={state.event.eventTypeId}
              options={state.allEventTypes.map(({ id: key, name: text, data }) => ({
                key,
                text,
                data,
              }))}
              onRenderOption={option => onRenderOption(option)}
              required
              notifyOnReselect={false}
            />

            <Dialog
              hidden={!dialogDisplayStatus.eventTypeId}
              onDismiss={() => setDialogDisplayStatus(prevState => (
                { ...prevState, eventTypeId: false }
              ))}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

                <TextField
                  label="Event Type Name"
                  name="name"
                  value={state.eventType.name}
                  onChange={e => handleInputChange({ target: { name: e.target.name, value: e.target.value, object: 'eventType' } })}
                  required
                />
                <TextField
                  label="Icon Name"
                  name="iconName"
                  value={state.eventType.iconName}
                  onChange={e => handleInputChange({ target: { name: e.target.name, value: e.target.value, object: 'eventType' } })}
                  required
                />
                <DialogFooter>
                  <PrimaryButton onClick={() => handleEventTypeCreation()} text="Save" />
                  <DefaultButton onClick={() => setDialogDisplayStatus(prevState => ({ ...prevState, eventTypeId: false }))} text="Cancel" />
                </DialogFooter>
              </Stack>
            </Dialog>
            <TextField
              name="mediaId"
              placeholder="Enter media url"
              value={state.event.mediaId}
              onChange={e => handleInputChange(e)}
              label="Media Url"
              required
            />
            {/*
              Using HTML5 inputs for date-times until a date-time picker is added to the
              office fabric spec. As of 26/11/19 only date picker is available.
            */}
            <TextField
              label="Start Date"
              name="startDate"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: state.event.startDate, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <TextField
              label="End Date"
              name="endDate"
              type="datetime-local"
              min="2010-01-01T00:00"
              max="9999-12-31T00:00"
              value={convertHTMLDatetimeString({ string: state.event.endDate, toHTML: true })}
              onChange={e => handleInputChange({
                target: {
                  name: e.target.name,
                  value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
                },
              })}
              required
            />

            <Toggle
              checked={state.event.pinned}
              label="Pinned"
              onText="Event pinned"
              offText="Event unpinned"
              name="pinned"
              onChange={handleInputChange}
            />

            <Toggle
              checked={state.event.cancelled}
              label="Cancelled"
              onText="Event cancelled"
              offText="Event not cancelled"
              name="cancelled"
              onChange={handleInputChange}
            />
            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>
        </form>
      </PivotItem>
    </Pivot>
  );
};

UpdateEvent.propTypes = propTypes;

export default withApollo(UpdateEvent);
