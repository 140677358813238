import gql from 'graphql-tag';

export const GET_SPECIAL_OFFER = gql`
  query specialOffer($id: ID!) {
    specialOffer(id: $id) {
      id
      title
      shortDescription
      meta
    }
  }
`;

export const UPDATE_SPECIAL_OFFER = gql`
  mutation updateSpecialOffer($id: Int!, $input: UpdateSpecialOfferInput!) {
    updateSpecialOffer(id: $id, input: $input) {
      id
    }
  }
`;
