import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_PAGES_DATA = gql`
  {
    allPages {
      id
      name
      url
      parentId
    }
  }
`;
