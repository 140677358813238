/* eslint-disable import/no-unresolved */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { handleErrorResponse } from 'helpers';
import { FooterWrapper, MessageList } from 'components';
import {
  GET_PARK_CONTACT,
  UPDATE_PARK_CONTACT,
} from './graphql';

const propTypes = {
  client: PropTypes.object.isRequired,
  contactId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  setHasQuickEditMutated: PropTypes.func.isRequired,
};

const QuickEdit = ({
  client,
  contactId,
  handleOnDismiss,
  setHasQuickEditMutated,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    (async () => {
      const getParkContact = await client.query({
        query: GET_PARK_CONTACT,
        variables: {
          id: contactId,
        },
      });

      const { data: { parkContact } } = getParkContact;

      setState({
        parkContact,
      });

      setIsLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.parkContact[name] = value;
    setState(newState);
  };

  const validateData = () => {
    const errorsFound = [];

    const { parkContact } = state;

    if (!parkContact.firstname) {
      errorsFound.push({
        title: 'Invalid First Name',
        message: 'Please ensure you have entered a first name',
      });
    }

    if (!parkContact.surname) {
      errorsFound.push({
        title: 'Invalid Last Name',
        message: 'Please ensure you have entered a last name',
      });
    }

    if (!parkContact.email) {
      errorsFound.push({
        title: 'Invalid Email',
        message: 'Please ensure you have entered an email',
      });
    }

    setErrors(errorsFound);

    return errorsFound.length === 0;
  };

  const handleSave = async () => {
    setErrors([]);
    if (validateData()) {
      const {
        parkContact: {
          __typename,
          id,
          ...input
        },
      } = state;

      const response = await client.mutate({
        mutation: UPDATE_PARK_CONTACT,
        variables: {
          id: contactId,
          input,
        },
      });

      if (!response || response.errors) {
        setErrors(...handleErrorResponse({ response, message: 'Park Contact saved unsuccessfully' }));
        return;
      }
      if (response && response.data) {
        setHasQuickEditMutated(prevState => (!prevState));
        handleOnDismiss();
      }
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>
          {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

          <TextField
            label="Contact ID"
            value={state.parkContact.id}
            prefix="#"
            type="number"
            disabled
            readOnly
          />

          <TextField
            label="Salutation"
            name="salutation"
            placeholder="E.g. Mr/Mrs"
            value={state.parkContact.salutation}
            onChange={e => handleInputChange(e)}
            ariaLabel="Salutation"
          />

          <TextField
            label="First Name"
            name="firstname"
            value={state.parkContact.firstname}
            onChange={e => handleInputChange(e)}
            ariaLabel="First Name"
            required
          />

          <TextField
            label="Last Name"
            name="surname"
            value={state.parkContact.surname}
            onChange={e => handleInputChange(e)}
            ariaLabel="Last Name"
            required
          />

          <TextField
            label="Email"
            name="email"
            value={state.parkContact.email}
            onChange={e => handleInputChange(e)}
            ariaLabel="Email"
            required
          />

          <TextField
            label="Phone Number"
            name="phoneNo"
            value={state.parkContact.phoneNo}
            onChange={e => handleInputChange(e)}
            ariaLabel="Phone Number"
          />
        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = propTypes;

export default withApollo(QuickEdit);
