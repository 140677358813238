/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ActionButton,
  CommandBar,
  ContextualMenuItemType,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
} from 'office-ui-fabric-react';
import {
  copyAndSort,
} from 'helpers';
import {
  GET_ALL_LIVE_CHAT_ITEMS,
} from './graphql';
import QuickEdit from './components/quick-edit';
import { apolloClient as client } from '../client';

const initialState = {
  columns: [
    {
      key: 'id',
      name: 'ID',
      fieldName: 'id',
      minWidth: 70,
      maxWidth: 300,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'url',
      name: 'URL',
      fieldName: 'url',
      minWidth: 70,
      maxWidth: 400,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'message',
      name: 'Message',
      fieldName: 'message',
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'active',
      name: 'Active',
      fieldName: 'active',
      onRender: d => (d.active ? 'Active' : 'Inactive'),
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      data: 'timestamp',
    },
    {
      key: 'actions',
      name: '',
    },
  ],
  items: [],
  filters: {
    text: '',
    displayInactive: false,
  },
};

const LiveChat = () => {
  const [state, setState] = useState(initialState);
  const [displayQuickEdit, setDisplayQuickEdit] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState([]);
  const [quickEditMutated, setHasQuickEditMutated] = useState(false);

  const commandBarItems = [
    {
      key: 'new',
      name: 'New',
      iconProps: {
        iconName: 'Add',
      },
      commandBarButtonAs: link => (
        <Link to="/live-chat/create" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ActionButton
            className={link.className}
            role={link.role}
            iconProps={link.iconProps}
            allowDisabledFocus={link.allowDisabledFocus}
          >
            {link.text}
          </ActionButton>
        </Link>
      ),
    },
    {
      key: 'toggleInactive',
      name: state.filters.displayInactive ? 'Hide Inactive' : 'Show Inactive',
      iconProps: {
        iconName: state.filters.displayInactive ? 'ClearFilter' : 'Filter',
      },
      onClick: () => {
        setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayInactive: !state.filters.displayInactive,
          },
        }));
      },
    },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => setShowFilter(!showFilter),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const res = await client.query({
        query: GET_ALL_LIVE_CHAT_ITEMS,
      });

      if (res.data && res.data.listLiveChatGreetings && res.data.listLiveChatGreetings.items) {
        const {
          data: {
            listLiveChatGreetings: {
              items,
            },
          },
        } = res;

        setState({
          ...state,
          items: copyAndSort(items, 'id', 'string', true),
        });
      }

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickEditMutated]);

  const handleQuickEdit = (id) => {
    setSelectedComponentId(id);
    setDisplayQuickEdit(true);
  };

  const onColumnClick = (e, column) => {
    const { columns, items } = state;
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        newCol.isSorted = false;
        // eslint-disable-next-line no-param-reassign
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items, currColumn.fieldName, currColumn.data, currColumn.isSortedDescending,
    );
    setState(prevState => ({
      ...prevState,
      columns: newColumns,
      items: newItems,
    }));
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'quickEditItem',
                  name: 'Quick Edit',
                  onClick: (e) => {
                    e.preventDefault();
                    handleQuickEdit(item.id);
                  },
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const filteredItems = () => {
    let allItems = [...state.items];

    if (!state.filters.displayInactive) {
      allItems = allItems.filter(item => item.active);
    }

    if (state.filters.text !== '') {
      allItems = allItems.filter((item) => {
        const {
          __typename,
          ...filterableData
        } = item;

        filterableData.active = (filterableData.active ? 'active' : 'inactive');

        return Object.values(filterableData).map(val => val.toString().toLowerCase())
          .filter(val => val.includes(state.filters.text.toLowerCase())).length;
      });
    }

    return allItems;
  };

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      {displayQuickEdit && (
        <QuickEdit
          componentId={selectedComponentId}
          setHasQuickEditMutated={setHasQuickEditMutated}
          quickEditMutated={quickEditMutated}
          handleOnDismiss={() => {
            setDisplayQuickEdit(false);
            setSelectedComponentId(null);
          }}
        />
      )}
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <React.Fragment>
          {showFilter && (
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <TextField
                label="Filter by"
                placeholder="Search"
                name="text"
                onChange={e => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    text: e.target.value,
                  },
                }))}
              />
              <Toggle
                defaultChecked={false}
                label="Display Inactive"
                onText="Yes"
                offText="No"
                name="displayInactive"
                onChange={(e, checked) => setState(prevState => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    displayInactive: checked,
                  },
                }))}
              />
            </Stack>
          )}

          {/* display errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
              isMultiline
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>
                {errors.map(({ message }) => (
                  <Text variant="medium" key={message}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}
          <Text variant="small">{`Displaying ${filteredItems().length} of ${state.items.length}`}</Text>
          <DetailsList
            items={filteredItems()}
            columns={state.columns.map(c => ({ ...c, onColumnClick }))}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default LiveChat;
