import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import {
  Persona,
  PersonaInitialsColor,
  PersonaSize,
} from 'office-ui-fabric-react/lib/Persona';
import { ActionButton } from 'office-ui-fabric-react';
import AuthService from '../../services/auth/AuthService';
import UserContext from '../../context/userContext';
import {
 AppbarWrapper, AppbarContainer, LogoContainer, Logo 
} from './style';

const Appbar = ({ client, userProfile, history }) => {
  const { authenticated, setUser } = useContext(UserContext);

  return (
    <AppbarWrapper>
      <AppbarContainer>
        <Link to="/overview">
          <LogoContainer>
            <Logo
              src="//parkholidays.s3.amazonaws.com/static_assets/svg_logo/white_redflag.svg"
              alt=""
            />
          </LogoContainer>
        </Link>
        <ActionButton
          menuIconProps={{ styles: { root: { color: '#fff' } } }}
          menuProps={{
            shouldFocusOnMount: true,
            items: [
              {
                key: 'logoutItem',
                text: 'Logout',
                onClick: () => {
                  // logout of AuthService (removing token)
                  AuthService.logout();
                  // Reset the apolloClient cache
                  client.cache.reset();
                  // Unset the UserProvider
                  setUser({
                    authenticated: false,
                    user: {},
                  });
                  // Redirect user back to login page
                  history.push('/login');
                },
              },
            ],
          }}
        >
          {authenticated && (
            <Persona
              initialsColor={PersonaInitialsColor.pink}
              text={userProfile.sub.displayName}
              size={PersonaSize.size32}
              styles={{
                primaryText: {
                  color: '#fff',
                  textTransform: 'capitalize',
                },
              }}
            />
          )}
        </ActionButton>
      </AppbarContainer>
    </AppbarWrapper>
  );
};

Appbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userProfile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(withApollo(Appbar));
