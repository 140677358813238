import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { Stack, TextField, PrimaryButton } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import AuthService from '../../services/auth/AuthService';
import UserContext from '../../context/userContext';
import LDAP_LOGIN from './graphql';
import {
  LoginWrapper,
  LoginContainer,
} from './style';

const Login = ({ client }) => {
  const [state, setState] = useState({
    email: process.env.NODE_ENV !== 'production' ? 'dev.test@parkholidays.com' : '',
    password: process.env.NODE_ENV !== 'production' ? '!HappyCamper5' : '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);
  const {
    setUser,
  } = useContext(UserContext);

  if (redirect) {
    return <Redirect to="/overview" />;
  }

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    client.mutate({
      mutation: LDAP_LOGIN,
      variables: {
        input: {
          ...state,
        },
      },
    })
      .then(({ data }) => {
        const { token } = data.ldapLogin;
        AuthService.setToken(token);
        setUser({
          authenticated: true,
          user: AuthService.getProfile(),
        });
        setRedirect(true);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
            {error}
          </MessageBar>
        )}
        <form onSubmit={e => handleLogin(e)}>
          <Stack tokens={{ childrenGap: 24 }}>
            <TextField label="Email" type="email" name="email" value={state.email} onChange={e => handleChange(e)} required />
            <TextField label="Password" type="password" name="password" value={state.password} onChange={e => handleChange(e)} required />
            <PrimaryButton type="submit" text={!loading ? 'Login' : 'Logging in...'} iconProps={loading && { iconName: 'AuthenticatorApp' }} disabled={loading} />
          </Stack>
        </form>
      </LoginContainer>
    </LoginWrapper>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(Login);
