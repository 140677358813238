import React from 'react';
import PropTypes from 'prop-types';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react';
import { copyAndSort } from 'helpers';

const defaultProps = {
  filteredItems: [],
  items: [],
};

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  setState: PropTypes.func.isRequired,
  filteredItems: PropTypes.arrayOf(PropTypes.object),
  renderItemColumn: PropTypes.func.isRequired,
};

const OverviewList = ({
  columns,
  items,
  setState,
  filteredItems,
  renderItemColumn,
}) => {
  const onColumnClick = (e, column) => {
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(
      currCol => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        newCol.isSorted = false;
        // eslint-disable-next-line no-param-reassign
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items,
      currColumn.fieldName,
      currColumn.data,
      currColumn.isSortedDescending,
    );
    setState(prevState => ({
      ...prevState,
      columns: newColumns,
      items: newItems,
    }));
  };

  return (
    <DetailsList
      items={filteredItems}
      columns={columns.map(c => ({ ...c, onColumnClick }))}
      setKey="set"
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      onRenderItemColumn={renderItemColumn}
      ariaLabelForSelectionColumn="Toggle selection"
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
    />
  );
};

OverviewList.defaultProps = defaultProps;
OverviewList.propTypes = propTypes;

export default OverviewList;
