import gql from 'graphql-tag';

export const GET_SPECIAL_OFFER = gql`
  query specialOffer($id: ID!, $showDeleted: Boolean!) {
    specialOffer(id: $id, showDeleted: $showDeleted) {
      id
      tenantId
      title
      shortDescription
      meta
      pageId
      mediaId
      media {
        id
        path
      }
      startDate
      endDate
      publishAt
      expiresAt
      parks {
        id
      }
    }
  }
`;

export const GET_SPECIAL_OFFER_DATA = gql`
  {
    allTenants {
      id
      name
    }
    allParks (showDeleted: true, filters: [{column: "active", operator: ">=", value: "0"}]) {
      id
      name
    }
    allPages {
      id
      name
      parentId
      url
    }
  }
`;

export const CREATE_SPECIAL_OFFER = gql`
  mutation createSpecialOffer($input: CreateSpecialOfferInput!) {
    createSpecialOffer(input: $input) {
      id
    }
  }
`;

export const UPDATE_SPECIAL_OFFER = gql`
  mutation updateCSSpecialOffer($id: Int!, $input: UpdateCSSpecialOfferInput!) {
    updateCSSpecialOffer(id: $id, input: $input) {
      id
      parks {
        id
      }
    }
  }
`;

export const ADD_SPECIAL_OFFER_TO_PARK = gql`
  mutation addSpecialOfferToPark($input: [SpecialOfferParkAssociationInput]!) {
    addSpecialOfferToPark(input: $input) {
      specialOfferId
    }
  }
`;

export const REMOVE_SPECIAL_OFFER_FROM_PARK = gql`
  mutation removeSpecialOfferFromPark($input: [SpecialOfferParkAssociationInput]!) {
    removeSpecialOfferFromPark(input: $input) {
      specialOfferId
    }
  }
`;
