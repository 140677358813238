import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  // ActionButton,
  DefaultButton,
  CommandBar,
  Spinner,
} from 'office-ui-fabric-react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import {
  MockContextualMenuLink,
  MockContextualMenuLinkItemText,
} from '../../../components/mock-contextual-menu-link';
import { PUBLIC_PATHS } from '../../../config';
import { GET_MEDIA } from './graphql';

const Media = ({ client }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    items: [],
    page: 1,
  });

  /* (int) how many items to return from GraphQL */
  const LIMIT = 20;

  const commandBarItems = [
    // NOTE ref:001
    // ------------------------------
    // this code below is for creating a new media item
    // but marketing have confirmed they do not wish parks
    // to be able to do this, only head office users, so
    // this has been commented out for now until we have
    // head office setup on this system, and permissions
    // surrounding the granularity of:
    // ROLE_PHAST_MEDIA_MODIFY in ActiveDirectory.
    // {
    //   key: 'new',
    //   name: 'New',
    //   iconProps: {
    //     iconName: 'Add',
    //   },
    //   commandBarButtonAs: link => (
    //     <Link to="/stock/create" style={{ textDecoration: 'none', color: 'inherit' }}>
    //       <ActionButton
    //         className={link.className}
    //         role={link.role}
    //         iconProps={link.iconProps}
    //         allowDisabledFocus={link.allowDisabledFocus}
    //       >
    //         {link.text}
    //       </ActionButton>
    //     </Link>
    //   ),
    // },
  ];

  const commandBarFarItems = [
    {
      key: 'filter',
      name: 'Filter',
      ariaLabel: 'Filter',
      iconProps: {
        iconName: 'Filter',
      },
      onClick: () => console.log('Filter'),
    },
  ];

  const columns = [
    {
      key: 'id', name: '#', fieldName: 'id', minWidth: 20, maxWidth: 40, isResizable: true,
    },
    {
      key: 'media',
      iconName: 'Picture',
      isIconOnly: true,
      onRender: item => (
        <div style={{ width: '300px', height: '169px' }}>
          <Image
            src={`${PUBLIC_PATHS.assets}/300x169/assets/${item.path}`}
            alt={item.alternateText}
            imageFit={ImageFit.cover}
            maximizeFrame
          />
        </div>
      ),
    },
    {
      key: 'alternateText', name: 'Alternate Text', fieldName: 'alternateText',
    },
    {
      key: 'description', name: 'Description', fieldName: 'description',
    },
    {
      key: 'keywords', name: 'Keywords', fieldName: 'keywords',
    },
    {
      key: 'createdAt', name: 'Created', fieldName: 'createdAt',
    },
    {
      key: 'actions', name: '',
    },
  ];

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                // NOTE
                // ----------------
                // see ref:001 note above for same issue here
                // {
                //   key: 'quickEditItem',
                //   name: 'Quick Edit',
                //   onClick: (e) => {
                //     e.preventDefault();
                //     // handleQuickEdit(item.id);
                //   },
                // },
                // {
                //   key: 'advancedEditItem',
                //   onRender: () => (
                //     <MockContextualMenuLink>
                //       <Link
                //          to={`/stock/${item.id}`}
                //          className="ms-ContextualMenu-link"
                //          style={{ textDecoration: 'none', color: 'inherit' }}
                //        >
                //         <div className="ms-ContextualMenu-linkContent">
                //           <MockContextualMenuLinkItemText
                //              className="ms-ContextualMenu-itemText"
                //            >
                //              Advanced Edit
                //            </MockContextualMenuLinkItemText>
                //         </div>
                //       </Link>
                //     </MockContextualMenuLink>
                //   ),
                // },
                // {
                //   key: 'divider_1',
                //   itemType: ContextualMenuItemType.Divider,
                // },
                {
                  key: 'imageEditItem',
                  onRender: () => (
                    <MockContextualMenuLink>
                      <Link
                        to={`/assets/media/edit/${item.id}`}
                        className="ms-ContextualMenu-link"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <div className="ms-ContextualMenu-linkContent">
                          <MockContextualMenuLinkItemText className="ms-ContextualMenu-itemText">Image Edit</MockContextualMenuLinkItemText>
                        </div>
                      </Link>
                    </MockContextualMenuLink>
                  ),
                },
                {
                  key: 'viewItem',
                  text: 'View',
                  href: `${PUBLIC_PATHS.assets}/assets/${item.path}`,
                  target: '_blank',
                },
              ],
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  const onLoadNextPage = useCallback(async () => {
    const res = await client.query({
      query: GET_MEDIA,
      variables: {
        pagination: {
          offset: (state.page * LIMIT) - LIMIT,
          limit: LIMIT,
        },
      },
    });
    const { allMedia } = res.data;

    setState({
      items: state.items.concat(allMedia),
      page: state.page + 1,
    });

    setIsLoading(false);
  }, [client, state.items, state.page]);

  const onDelayedLoadNextPage = () => {
    if (!isLoading) {
      setIsLoading(true);
      onLoadNextPage();
    }
  };

  useEffect(() => {
    // only run on first page
    if (state.page === 1) {
      const fetchData = async () => {
        setIsLoading(true);
        await onLoadNextPage();
      };

      fetchData();
    }
  }, [onLoadNextPage, state.page]);

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        styles={{ root: { paddingLeft: '0', paddingRight: '0' } }}
      />
      <div style={{ position: 'relative', height: '80vh' }}>
        <ScrollablePane>
          <DetailsList
            setKey="items"
            items={[...state.items, null]}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            onRenderMissingItem={() => onDelayedLoadNextPage()}
          />
          {isLoading && (
            <Spinner label="Loading..." />
          )}
        </ScrollablePane>
      </div>
    </div>
  );
};

Media.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
};

export default withApollo(Media);
