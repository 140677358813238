/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { FooterWrapper, MessageList, Toggle } from 'components';
import { convertHTMLDatetimeString, handleErrorResponse } from 'helpers';
import { GET_EVENT, GET_EVENT_DATA, UPDATE_EVENT } from './graphql';

const propTypes = {
  client: PropTypes.object.isRequired,
  eventId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
  setQuickEditMutated: PropTypes.func.isRequired,
};

const QuickEdit = ({
  client,
  eventId,
  handleOnDismiss,
  setQuickEditMutated,
}) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: { event } } = await client.query({
        query: GET_EVENT,
        variables: {
          id: eventId,
          showDeleted: true,
        },
      });

      const { data: { allParks } } = await client.query({
        query: GET_EVENT_DATA,
      });

      const sortedParks = allParks.sort((a, b) => a.name.localeCompare(b.name));

      setState({
        event,
        allParks: sortedParks,
      });

      setIsLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.event[name] = value;
    setState(newState);
  };

  const handleSave = async () => {
    const {
      event: {
        __typename, id, parkId, ...input
      },
    } = state;

    const formatDateString = string => string.substring(0, 16).concat(':00');

    const response = await client.mutate({
      mutation: UPDATE_EVENT,
      variables: {
        id: eventId,
        input: {
          ...input,
          startDate: formatDateString(input.startDate),
          endDate: formatDateString(input.endDate),
        },
      },
    });

    if (!response || response.errors) {
      setErrors(...handleErrorResponse({ response, message: 'Error saving Event changes' }));
      return;
    }

    if (response && response.data) {
      handleOnDismiss();
      setQuickEditMutated();
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {errors.length > 0 && <MessageList messages={errors} messageFunction={setErrors} messageType="error" />}

          <TextField
            label="Event ID"
            value={state.event.id}
            prefix="#"
            type="number"
            readOnly
          />

          <TextField
            label="Slug"
            value={state.event.slug}
            readOnly
          />

          <TextField
            label={`Title: (${
              state.event.title.length
            }/50 characters)`}
            name="title"
            value={state.event.title}
            onChange={e => handleInputChange(e)}
            ariaLabel="Title"
            maxLength={50}
            required
          />

          <TextField
            label="Location"
            name="location"
            placeholder="e.g. Clubhouse"
            value={state.event.location}
            onChange={e => handleInputChange(e)}
            required
          />

          {/*
            Using HTML5 inputs for date-times until a date-time picker is added to the
            office fabric spec. As of 26/11/19 only date picker is available.
          */}
          <TextField
            label="Start Date"
            name="startDate"
            type="datetime-local"
            min="2010-01-01T00:00"
            max="9999-12-31T00:00"
            value={convertHTMLDatetimeString({ string: state.event.startDate, toHTML: true })}
            onChange={e => handleInputChange({
              target: {
                name: e.target.name,
                value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
              },
            })}
            required
          />

          <TextField
            label="End Date"
            name="endDate"
            type="datetime-local"
            min="2010-01-01T00:00"
            max="9999-12-31T00:00"
            value={convertHTMLDatetimeString({ string: state.event.endDate, toHTML: true })}
            onChange={e => handleInputChange({
              target: {
                name: e.target.name,
                value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
              },
            })}
            required
          />

          <Toggle
            checked={state.event.pinned}
            label="Pinned"
            onText="Event pinned"
            offText="Event unpinned"
            name="pinned"
            onChange={handleInputChange}
          />

          <Toggle
            checked={state.event.cancelled}
            label="Cancelled"
            onText="Event cancelled"
            offText="Event not cancelled"
            name="cancelled"
            onChange={handleInputChange}
          />
        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = propTypes;

export default withApollo(QuickEdit);
