import { styled } from 'styletron-react';

// eslint-disable-next-line import/prefer-default-export
export const FooterWrapper = styled('div', {
  boxSizing: 'border-box',
  paddingTop: '8px',
  paddingRight: '24px',
  paddingBottom: '8px',
  paddingLeft: '24px',
});
