import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_PAGE_TEMPLATES = gql`
  {
    allPageTemplates {
      id
      tenantId
      name
      path
      initialProps
      layoutWireframeMediaId
      createdAt
      updatedAt
      media {
        id
        path
      }
    }
  }
`;
