import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import {
  GET_PARK_NOTICE,
  UPDATE_PARK_NOTICE,
} from './graphql';
import { FooterWrapper } from '../../../../../../components/footer-wrapper';
import { convertHTMLDatetimeString } from '../../../../../../helpers';

const QuickEdit = ({ client, noticeId, handleOnDismiss }) => {
  const [state, setState] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const getParkNotice = await client.query({
        query: GET_PARK_NOTICE,
        variables: {
          id: noticeId,
          showDeleted: true,
        },
      });

      const { data: { parkNotice } } = getParkNotice;

      setState({ parkNotice });

      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    const newState = Object.assign({}, state);
    state.parkNotice[name] = value;
    setState(newState);
  };

  const handleSave = async () => {
    const {
      parkNotice: {
        __typename, id, ...input
      },
    } = state;

    const formatDateString = string => string.substring(0, 16).concat(':00');

    const response = await client.mutate({
      mutation: UPDATE_PARK_NOTICE,
      variables: {
        id: noticeId,
        input: {
          ...input,
          publishAt: formatDateString(input.publishAt),
          expireAt: formatDateString(input.expireAt),
        },
      },
    });

    if (!response) {
      setErrors([{ index: 0, message: 'Save unsuccessful' }]);
      return;
    }

    if (response && response.errors) {
      setErrors(response.errors.map(({ message }, index) => ({
        index,
        message,
      })));
      return;
    }

    if (response && response.data) {
      handleOnDismiss();
    }
  };

  return (
    <Panel
      isOpen
      isLightDismiss
      isFooterAtBottom
      onDismiss={() => handleOnDismiss()}
      onRenderFooter={() => (
        <FooterWrapper>
          <PrimaryButton onClick={() => handleSave()}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={handleOnDismiss}>Cancel</DefaultButton>
        </FooterWrapper>
      )}
      type={PanelType.medium}
      headerText="Quick Edit"
    >
      {loading ? (
        <Spinner label="Loading, please wait..." />
      ) : (
        <Stack tokens={{ childrenGap: 18 }}>

          {/* display server errors */}
          {errors.length !== 0 && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline
              onDismiss={() => setErrors([])}
              dismissButtonAriaLabel="Close"
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="medium">
                  {`Server ${errors.length === 1 ? 'error' : 'errors'}:`}
                </Text>
                {errors.map(({ message, index }) => (
                  <Text variant="medium" key={index}>
                    {`- ${message}`}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}

          <TextField
            label="Notice ID"
            value={state.parkNotice.id}
            prefix="#"
            type="number"
            readOnly
          />

          <TextField
            label={`Title: (${
              state.parkNotice.title.length
            }/50 characters)`}
            name="title"
            value={state.parkNotice.title}
            onChange={e => handleInputChange(e)}
            ariaLabel="Title"
            maxLength={50}
            required
          />

          {/*
            Using HTML5 inputs for date-times until a date-time picker is added to the
            office fabric spec. As of 26/11/19 only date picker is available.
          */}
          <TextField
            label="Publish Date"
            name="publishAt"
            type="datetime-local"
            min="2010-01-01T00:00"
            max="9999-12-31T00:00"
            value={convertHTMLDatetimeString({ string: state.parkNotice.publishAt, toHTML: true })}
            onChange={e => handleInputChange({
              target: {
                name: e.target.name,
                value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
              },
            })}
            required
          />

          <TextField
            label="Expiry Date"
            name="expireAt"
            type="datetime-local"
            min="2010-01-01T00:00"
            max="9999-12-31T00:00"
            value={convertHTMLDatetimeString({ string: state.parkNotice.expireAt, toHTML: true })}
            onChange={e => handleInputChange({
              target: {
                name: e.target.name,
                value: convertHTMLDatetimeString({ string: e.target.value, toHTML: false }),
              },
            })}
            required
          />

        </Stack>
      )}
    </Panel>
  );
};

QuickEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  noticeId: PropTypes.number.isRequired,
  handleOnDismiss: PropTypes.func.isRequired,
};

export default withApollo(QuickEdit);
