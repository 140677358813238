import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_PARK_MESSAGE_TYPES = gql`
  {
    allParkMessageTypes {
      id
      name
      messages {
        id
      }
    }
  }
`;
