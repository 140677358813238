/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  ShimmeredDetailsList,
  SelectionMode,
} from 'office-ui-fabric-react';
import { copyAndSort } from 'helpers';

const defaultProps = {
  items: [],
  returnMenuItems: () => false,
};

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  returnMenuItems: PropTypes.func,
  setState: PropTypes.func.isRequired,
};

const ShimmeredOverviewList = ({
  columns,
  items,
  loading,
  returnMenuItems,
  setState,
}) => {
  const onColumnClick = (e, column) => {
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(
      currCol => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        newCol.isSorted = false;
        // eslint-disable-next-line no-param-reassign
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items,
      currColumn.fieldName,
      currColumn.data,
      currColumn.isSortedDescending,
    );
    setState(newColumns, newItems);
  };

  const onRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <DefaultButton
            text="Menu"
            menuProps={{
              shouldFocusOnMount: true,
              items: returnMenuItems(item),
            }}
          />
        );
      default:
        return fieldContent;
    }
  };

  return (
    <ShimmeredDetailsList
      items={items}
      columns={columns.map(c => ({ ...c, onColumnClick }))}
      onRenderItemColumn={onRenderItemColumn}
      enableShimmer={loading}
      selectionMode={SelectionMode.none}
    />
  );
};

ShimmeredOverviewList.defaultProps = defaultProps;
ShimmeredOverviewList.propTypes = propTypes;

export default ShimmeredOverviewList;
