import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_MEDIA = gql`
  query allMedia($pagination: PaginationCriteria) {
    allMedia(pagination: $pagination) {
      id
      path
      disk
      alternateText
      createdAt
      type {
        id
      }
    }
  }
`;
