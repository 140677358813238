import React from 'react';
import { Stack, Text } from 'office-ui-fabric-react';
// import StockAnalysis from './components/stock-analysis';

const Overview = () => (
  <Stack tokens={{ childrenGap: 8 }}>
    {/* <StockAnalysis parks={['BP']} /> */}
    <Text variant="large">Coming soon on your Dashboard...</Text>
    <Text> - Real-time Stock Report</Text>
    <Text> - Stock Traffic Analysis</Text>
  </Stack>
);

export default Overview;
